/**
 * get textual describing of the payment status
 *
 * @param paymentStatusCode code for payment code 0 to 4
 * @returns payment status as a string
 */
export const getPaymentStatus = (
	paymentStatusCode: number,
): 'Bezahlt' | 'Angenommen' | 'Abholbereit' | 'Abgeholt' | 'Abgelehnt' | 'Storniert'| 'Autom. Abgeholt' |'Unbekannt' => {
	switch (paymentStatusCode) {
		case 0:
			return 'Bezahlt';
		case 1:
			return 'Angenommen';
		case 2:
			return 'Abholbereit';
		case 3:
			return 'Abgeholt';
		case 4:
			return 'Abgelehnt';
		case 5:
			return 'Storniert';
		case 6: 
			return 'Autom. Abgeholt';
		default:
			return 'Unbekannt';
	}
};

/**
 * get textual describing of the payment method
 *
 * @param paymentMethodCode code for payment method 0 to 4
 * @returns payment method as string
 */
export const getPaymentMethod = (
	paymentMethodCode: number,
): 'Prepaid' | 'Charge' | 'Concardis' | 'Paypal' | 'POS' |'Nets Easy' | 'Treue Punkte' | 'Treue Punkte - Kasse'| 'Unbekannt' => {
	switch (paymentMethodCode) {
		case 0:
			return 'Prepaid';
		case 1:
			return 'Charge';
		case 2:
			return 'Concardis';
		case 3:
			return 'Paypal';
		case 4:
			return 'POS';
		case 5:
			return 'Nets Easy';
		case 6:
			return 'Treue Punkte';
		case 7:
			return  'Treue Punkte - Kasse';
		default:
			return 'Unbekannt';
	}
};
