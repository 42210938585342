import { AxiosResponse } from 'axios';
import { axiosInstance } from './axiosConfig';

export enum PaymentSettingEnum {
	IsSandboxPaypal=0,
	AllowPayCartWithPaypal=1,
	AllowPayPrepaidWithPaypal=2,
	NetsEasySandbox=3,
	AllowPayCartWithNetsEasy=4,
	AllowPayPrepaidWithNetsEasy=5,
	AllowPayCartWithPoints=6,
	PayWithCustomerCard=7,
	RechargeCustomerCard=8,
}
export interface PaymentSettingGetResponse {
	value: boolean | null;
	msg: string;
}
export interface PaymentSettingPatchResponse {
	success: boolean;
	msg: string;
}


/**
 * patches a payment provider setting
 *
 * @param paymentSetting which setting to patch
 * @param flavor AppFlavor
 * @param checked value of setting
 */
export const doApiRequestPatch = async (
	paymentSetting: PaymentSettingEnum,
	flavor: string,
	checked: boolean,
): Promise<PaymentSettingPatchResponse> => {
	try {
		const res: AxiosResponse<any>= await axiosInstance.patch(
			`v1/Config/paypal-setting/${paymentSetting}/${checked}`,
			{},{
				headers: { AppFlavour: flavor }
			},
		);
		if (res.status !== 200) {
			return {
				success: false,
				msg: 'Einstellung könnte nicht gespeichert werden.',
			};
		}
		return {
			success: true,
			msg: '',
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				success: false,
				msg: error.response.data.title,
			};
		}
		return { success: false, msg: 'no valid server response' };
	}
};

/**
 * gets a payment provider setting value
 *
 * @param paymentSetting which setting to get
 * @param flavor AppFlavor
 */
export const doApiRequestGET = async (
	paymentSetting: PaymentSettingEnum,
	flavor: string,
): Promise<PaymentSettingGetResponse> => {
	try {
		const res: AxiosResponse<boolean | null> = await axiosInstance(
			`v1/Config/paypal-setting?PaypalSetting=${paymentSetting}`,
			{
				headers: { AppFlavour: flavor },
			},
		);
		if (res.data == null) {
			return {
				value: null,
				msg: 'No value for setting',
			};
		}
		return {
			value: res.data,
			msg: '',
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				value: null,
				msg: error.response.data.title,
			};
		}
		return {
			value: null,
			msg: 'no valid server response',
		};
	}
};
