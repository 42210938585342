import { Button, IconButton, Popper } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowData } from '@material-ui/data-grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import dateFormat from 'dateformat';
import { parse } from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import * as api from '../../api/userAccount';
import * as userManagementApi from '../../api/userManagement';
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import UsersPopups from '../../components/UsersPopups/UsersPopups';
import { LoginContext } from '../../contexts/LoginContext';
import { Actions } from '../../types/actions';
import { Flavor } from '../../types/flavor';
import { LoginContextType } from '../../types/loginContext';
import { User } from '../../types/user';
import { hasAccess } from '../../util/authorization';
import './UserTable.css';

const UserTable = () => {
	const history = useHistory();
	const location = useLocation();
	const { role, selectedFlavor, allowedFlavors }: LoginContextType = useContext(LoginContext);
	const { displaySnackbar } = useContext(SnackBarContext);
	const {
		data: allUser,
		error,
		isLoading,
		refetch,
	} = useQuery('getUser', () => userManagementApi.getAllUsers(selectedFlavor.flavor, "UserTable.tsx"), {
		refetchInterval: 10000,
	});

	const passwordMutation = useMutation(async (user: User) => {
		const res: number = await api.resetPassword(user.email, user.flavor);
		showSnackbar('Email wurde versendet.', 'Fehler beim senden der Email!', res);
	});
	const confirmationEmailMutation = useMutation(async (userId: string) => {
		const res: number = await api.resendConfirmEmail(userId);
		showSnackbar('Email wurde versendet.', 'Fehler beim senden der Email!', res);
	});
	const manuelEmailConfirmationMutation = useMutation(async (user: User) => {
		const res: number = await api.manuallyConfirmEmail(user.id,user.flavor );
		showSnackbar('Account wurde bestätigt', 'Fehler bei der Bestätigung', res);
	});
	const blockCustomerCardMutation = useMutation(async (userId: string) => {
		const res: number = await api.blockCustomerCard(userId);
		showSnackbar('Kundenkarte gesperrt', 'Kundenkarte konnte nicht gesperrt werden!', res);
	});
	const unBlockCustomerCardMutation = useMutation(async (userId: string) => {
		const res: number = await api.unblockCustomerCard(userId);
		showSnackbar('Kundenkarte freigegeben', 'Kundenkarte konnte nicht freigegeben werden!', res);
	});

	const actions: Actions = {
		refetchAll: () => null,
		passwordMutation,
		confirmationEmailMutation,
		manuelEmailConfirmationMutation,
		blockCustomerCardMutation,
		unBlockCustomerCardMutation,
	};

	const [openChargeModal, setOpenChargeModal] = useState<boolean>(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorElTooltip, setAnchorElTooltip] = useState<null | HTMLElement>(null);
	const [filteredUsers, setFilteredUsers] = useState<User[]>();
	const [selectedUser, setSelectedUser] = useState<User | null>();
	const [pageSize, setPageSize] = useState<number>(25);

	const headers = [
		{ label: 'Kundennummer', key: 'id' },
		{ label: 'Email', key: 'email' },
		{ label: 'Vorname', key: 'vorname' },
		{ label: 'Nachname', key: 'name' },
		{ label: 'Kartennummer', key: 'customerCard' },
		{ label: 'Geburtstag', key: 'birthdate' },
		{ label: 'Loginversuche', key: 'accessFailedCount' },
		{ label: 'Flavor', key: 'flavor' },
		{ label: 'Bestätigt', key: 'emailConfirmed' },
		{ label: 'Rolle', key: 'role' },
		{ label: 'Anrede', key: 'salutation' },
		{ label: 'Telefon', key: 'phonenumber' },
	];

	useEffect(() => {
		const urlPageSize = parse(location.search).pagesize;
		if (urlPageSize) setPageSize(Number.parseInt(urlPageSize.toString()));
	}, []);

	useEffect(() => {
		if (allUser != null) {
			if (selectedFlavor.flavor !== 'all') {
				setFilteredUsers(allUser.filter((user) => user.flavor === selectedFlavor.flavor));
			} else {
				setFilteredUsers(allUser);
			}
		}
	}, [selectedFlavor, allUser]);

	// hides the "kopiert" popup after a delay
	useEffect(() => {
		if (anchorElTooltip == null) return;
		setTimeout(() => {
			setAnchorElTooltip(null);
		}, 1200);
	}, [anchorElTooltip]);

	const showSnackbar = (msgSuccess: string, msgError: string, type: number) => {
		displaySnackbar(type === 1 ? msgSuccess : msgError, type === 1 ? 'success' : 'error');
		refetch();
	};

	/** records the "more" button click */
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	/** copies the kundenkartennummer and triggers "kopiert" popup */
	const handleCopyKundenkarte = (event: React.MouseEvent<HTMLDivElement>) => {
		if (selectedUser) navigator.clipboard.writeText(selectedUser.customerCard);
		setAnchorElTooltip(event.currentTarget);
	};

	/** copies the user id and triggers "kopiert" popup */
	const handleCopyId = (event: React.MouseEvent<HTMLDivElement>) => {
		if (selectedUser) navigator.clipboard.writeText(selectedUser.id);
		setAnchorElTooltip(event.currentTarget);
	};

	const columns: GridColDef[] = [
		{ field: 'email', headerName: 'E-Mail', flex: 1 },
		{ field: 'vorname', headerName: 'Vorname', width: 190 },
		{ field: 'name', headerName: 'Nachname', width: 190 },
		{
			field: 'birthdate',
			headerName: 'Geburtsdatum',
			type: 'dateTime',
			width: 170,
			renderCell: (param) => {
				if (param.row.birthdate===null){
					return "Keine Angabe";
				}else {
					return dateFormat(Date.parse(param.row.birthdate), 'dd.mm.yyyy');
				}
			},
		},
		{
			field: 'customerCard',
			headerName: 'Kundenkarte',
			flex: 1,
			width: 200,
			renderCell: (param) => {
				return (
					<div
						style={{ width: '100%', overflow: 'hidden' }}
						onDoubleClick={handleCopyKundenkarte}
						onMouseDown={(event) => event.preventDefault()}
					>
						{param.row.customerCard}
					</div>
				);
			},
		},
		{
			field: 'emailConfirmed',
			headerName: 'Bestätigt',
			width: 130,
			renderCell: (param) => {
				return param.row.emailConfirmed ? 'bestätigt' : 'offen';
			},
		},
		{
			field: '',
			headerName: 'Mehr',
			width: 85,
			sortable: false,
			align: 'center',
			renderCell: () => {
				return (
					<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
				);
			},
		},
	];

	if (selectedFlavor.flavor === 'all')
		columns.splice(0, 0, {
			field: 'flavor',
			headerName: 'Flavor',
			flex: 0.5,
			renderCell: (param) => {
				return allowedFlavors.find((item: Flavor) => param.row.flavor === item.flavor)?.name;
			},
		});

	if (hasAccess(role, 'userIdColumn'))
		columns.splice(0, 0, {
			field: 'id',
			headerName: 'ID',
			flex: 1,
			renderCell: (param) => {
				return (
					<div
						style={{ width: '100%', overflow: 'hidden' }}
						onDoubleClick={handleCopyId}
						onMouseDown={(event) => event.preventDefault()}
					>
						{param.row.id}
					</div>
				);
			},
		});

	if (error) {
		console.error(error);
	}

	if (isLoading || filteredUsers == null || filteredUsers.length < 1) {
		return <Loadingspinner />;
	}

	return (
		<>
			<div className="userTable">
				{hasAccess(role, 'exportUsers') ? (
					<Button className="userTable__button">
						<CSVLink data={filteredUsers} headers={headers} className="userTable__csvLink">
							Exportieren
						</CSVLink>
					</Button>
				) : null}

				<div
					className={hasAccess(role, 'exportUsers') ? 'userTable__table' : 'userTable__bigTable'}
				>
					<DataGrid
						rows={filteredUsers}
						columns={columns}
						pageSize={pageSize}
						onPageSizeChange={(newPagesize) => {
							setPageSize(newPagesize);
							history.push({
								pathname: location.pathname,
								search: `pagesize=${newPagesize}`,
							});
						}}
						onRowClick={(element: GridRowData) => setSelectedUser(element.row as User)}
					/>
				</div>
			</div>

			{/* Popupmenue on user options */}
			{selectedUser != null && (
				<UsersPopups
					user={selectedUser as User}
					anchorElement={anchorEl}
					setAnchorElement={setAnchorEl}
					openChargeDialog={openChargeModal}
					setOpenChargeDialog={setOpenChargeModal}
					openSecurityDialog={openDeleteDialog}
					setOpenSecurityDialog={setOpenDeleteDialog}
					actions={actions}
				/>
			)}

			{/* popup after copying customerCard or userId */}
			{anchorElTooltip != null && (
				<Popper anchorEl={anchorElTooltip} open={anchorElTooltip != null} placement="right">
					<div className="userTable__popper">kopiert</div>
				</Popper>
			)}
		</>
	);
};

export default UserTable;
