import { Dialog, DialogContentText, IconButton, Popper } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import dateformat from 'dateformat';
import parse from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import * as api from '../../api/orders';
import { ShoppingHistory as ShoppingHistoryType } from '../../types/order';
import './ShoppingHistoryTable.css';
import orderBy from 'lodash/orderBy';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContextType } from '../../types/loginContext';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

interface Props {
	history: ShoppingHistoryType[];
}

function ShoppingHistoryTable({ history }: Props) {
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);
	const [open, setOpen] = useState<boolean>(false);
	const [shoppingReceipt, setShoppingReceipt] = useState<string>('');
	const [selectedPurchaseId, setSelectedPurchaseId] = useState<any>();
	const [buttonClicked, setButtonClicked] = useState<boolean>(false);
	const [anchorElTooltip, setAnchorElTooltip] = useState<null | HTMLElement>(null);

	// fetches and shows the "bon" if button clicked and purchchase id was selected
	useEffect(() => {
		const fetchShoppingReceipt = async () => {
			const receipt: string | null = await api.getShoppingReceipt(
				selectedPurchaseId,
				selectedFlavor.flavor,
			);
			if (receipt == null) return;
			setShoppingReceipt(receipt);
			setOpen(true);
			setButtonClicked(false);
			setSelectedPurchaseId(null);
		};
		if (selectedPurchaseId && buttonClicked) {
			fetchShoppingReceipt();
		}
	}, [selectedPurchaseId, buttonClicked]);

	// hides the "kopiert" popup after a delay
	useEffect(() => {
		if (anchorElTooltip == null) return;
		setTimeout(() => {
			setAnchorElTooltip(null);
		}, 1200);
	}, [anchorElTooltip]);

	/** selects the purchase id of the clicked row */
	const selectRow = (params: any) => {
		setSelectedPurchaseId(params.row.id);
	};

	/** records the button click */
	const handleButtonClick = () => {
		setButtonClicked(true);
	};

	/** copies the purchase id and triggers "kopiert" popup */
	const handleCopyPurchaseId = (event: React.MouseEvent<HTMLDivElement>) => {
		if (selectedPurchaseId) navigator.clipboard.writeText(selectedPurchaseId);
		setAnchorElTooltip(event.currentTarget);
	};

	/** close the "bon" popup */
	const closeBon = () => {
		setOpen(false);
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			flex: 2,
			renderCell: (param) => {
				return (
					<div
						style={{ width: '100%', overflow: 'hidden' }}
						onDoubleClick={handleCopyPurchaseId}
						onMouseDown={(event) => event.preventDefault()}
					>
						{param.row.id}
					</div>
				);
			},
		},
		{
			field: 'purchaseDate',
			headerName: 'Datum',
			flex: 1,
			renderCell: (params: GridCellParams) => {
				if (params.row.purchaseDate === null) {
					return "Keine Daten";
				} else {
					return dateformat(params.row.purchaseDate, 'dd.mm.yyyy - HH:MM');
				}
			},
		},
		{
			field: 'totalAmount',
			headerName: 'Betrag',
			flex: 1,
			renderCell: (params: GridCellParams) => {
	
				return `${(Math.round(params.row.totalAmount * 100) / 100).toFixed(2)} ${params.row.paidWithLoyaltyPoints ? 'TP':'€'}` ;
			},
		},
		{
			field: '',
			headerName: 'Bon',
			width: 80,
			sortable: false,
			align: 'center',
			renderCell: (params: GridCellParams) => {
				if (params.row.purchaseDate === null) {
					return "";
				} else {
					return (
						<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleButtonClick}>
							<ReceiptOutlinedIcon />
						</IconButton>
					);
				}
			},
		},
	];

	return (
		<>
			<div className="shoppingHistoryTable">
				<DataGrid
					rows={orderBy(history, ['purchaseDate'], ['desc'])}
					columns={columns}
					pageSize={10}
					disableSelectionOnClick
					onCellDoubleClick={(element) => {
						if (element.value != null) {
							navigator.clipboard.writeText(element.value.toString());
						}
					}}
					onRowClick={selectRow}
				/>
				<Dialog onClose={closeBon} aria-labelledby="orderhistory-document" open={open}>
					<DialogContentText style={{ margin: 30 }}>
						{/* maybe not the best way and on changes on the shoppingReceipt receiving from the api this need to bee changed */}
						{parse(shoppingReceipt.replace('body {  margin: 5% 5% !important; }', ''))}
					</DialogContentText>
				</Dialog>
			</div>

			{/* popup after copying customerCard or userId */}
			{anchorElTooltip != null && (
				<Popper anchorEl={anchorElTooltip} open={anchorElTooltip != null} placement="right">
					<div className="userTable__popper">kopiert</div>
				</Popper>
			)}
		</>
	);
}

export default ShoppingHistoryTable;
