import React, { useContext, useState } from 'react';
import { UserManagementActions } from '../../types/actions';
import { User } from '../../types/user';
import { SnackBarContext } from '../CustomAlertSnackBar/CustomAlertSnackBarContext';
import UserSecurityDialog from './UserDialog';
import UsersQuickMenu from './UsersQuickMenu';

interface Props {
	user: User;
	anchorElement: null | HTMLElement;
	setAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
	openSecurityDialog: boolean;
	setOpenSecurityDialog: React.Dispatch<React.SetStateAction<boolean>>;
	actions: UserManagementActions;
}

const UsersPopups = ({
	user,
	anchorElement,
	setAnchorElement,
	openSecurityDialog,
	setOpenSecurityDialog,
	actions,
}: Props) => {
	const { displaySnackbar } = useContext(SnackBarContext);

	const [dialogHeading, setDialogHeading] = useState<string>('');
	const [dialogMessage, setDialogMessage] = useState<string>('');
	const [dialogButtonText, setDialogButtonText] = useState<string>('');
	const [dialogOnAccept, setDialogOnAccept] = useState<Function>(() => null);
	const [roleSelection, setRoleSelection] = useState<boolean>(false);
	const [newUserRole, setNewUserRole] = useState<
		'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | 'Customer'
	>('Customer');

	return (
		<>
			<UsersQuickMenu
				user={user}
				anchorElement={anchorElement}
				setDialogHeading={setDialogHeading}
				setDialogMessage={setDialogMessage}
				setDialogButtonText={setDialogButtonText}
				setDialogOnAccept={setDialogOnAccept}
				actions={actions}
				setAnchorElement={setAnchorElement}
				setOpenSecurityDialog={setOpenSecurityDialog}
				setRoleSelection={setRoleSelection}
				newUserRole={newUserRole}
			/>
			<UserSecurityDialog
				user={user}
				heading={dialogHeading}
				message={dialogMessage}
				acceptButtonText={dialogButtonText}
				onAccept={() => dialogOnAccept()}
				openDialog={openSecurityDialog}
				setOpenDialog={setOpenSecurityDialog}
				roleSelection={roleSelection}
				setRoleSelection={setRoleSelection}
				newUserRole={newUserRole}
				setNewUserRole={setNewUserRole}
			/>
		</>
	);
};

export default UsersPopups;
