import { Container, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import * as api from "../../api/userAccount";
import * as userAccountApi from "../../api/userAccount";
import * as userManagementApi from "../../api/userManagement";
import ChargingHistoryTable from "../../components/ChargingHistoryTable/ChargingHistoryTable";
import { SnackBarContext } from "../../components/CustomAlertSnackBar/CustomAlertSnackBarContext";
import Loadingspinner from "../../components/LoadingSpinner/Loadingspinner";
import OrderHistoryTable from "../../components/OrderHistoryTable/OrderHistoryTable";
import ShoppingHistoryTable from "../../components/ShoppingHistoryTable/ShoppingHistoryTable";
import UserActionBar from "../../components/UserActionBar/UserActionBar";
import UserDataCard from "../../components/UserDataCard/UserDataCard";
import { LoginContext } from "../../contexts/LoginContext";
import { LoginContextType } from "../../types/loginContext";
import { User } from "../../types/user";
import { hasAccess } from "../../util/authorization";
import "./UserDashboard.css";

interface Props {
  params: any;
}

function EditUser({ params }: Props) {
  const history = useHistory();
  const { role, selectedFlavor }: LoginContextType = useContext(LoginContext);
  const { displaySnackbar } = useContext(SnackBarContext);

  const { data: users, refetch: refetchUser } = useQuery("allUser", () =>
    userManagementApi.getAllUsers(selectedFlavor.flavor, "UserDashboard.tsx")
  );
  const { data: shoppingHistory, refetch: refetchShoppingHistory } = useQuery(
    ["shoppingHistory", params.id, params.flavor],
    () => userAccountApi.getShoppingHistory(params.id, params.flavor)
  );
  const { data: customerCard, refetch: refetchCustomerCard } = useQuery(
    ["userCard", params.id, params.flavor],
    () => userAccountApi.getUserCard(params.id, params.flavor)
  );
  const { data: orderHistory, refetch: refetchOrderHistory } = useQuery(
    ["orderHistory", params.id, params.flavor],
    () => userAccountApi.getOrderHistory(params.id, params.flavor)
  );

  const passwordMutation = useMutation(async (user: User) => {
    const res: number = await api.resetPassword(user.email, user.flavor);
    showSnackbar(
      "Email wurde versendet.",
      "Fehler beim senden der Email!",
      res
    );
    refetchUser();
  });
  const confirmationEmailMutation = useMutation(async (userId: string) => {
    const res: number = await api.resendConfirmEmail(userId);
    showSnackbar(
      "Email wurde versendet.",
      "Fehler beim senden der Email!",
      res
    );
    refetchUser();
    refetchCustomerCard();
  });
  const manuelEmailConfirmationMutation = useMutation(
    async (user: User ) => {
      const res: number = await api.manuallyConfirmEmail(user.id, user.flavor);
      showSnackbar(
        "Account wurde bestätigt",
        "Fehler bei der Bestätigung",
        res
      );
      refetchUser();
      refetchCustomerCard();
    }
  );
  const blockCustomerCardMutation = useMutation(async (userId: string) => {
    const res: number = await api.blockCustomerCard(userId);
    showSnackbar(
      "Kundenkarte gesperrt",
      "Kundenkarte konnte nicht gesperrt werden!",
      res
    );
    refetchCustomerCard();
    refetchUser();
  });
  const unBlockCustomerCardMutation = useMutation(async (userId: string) => {
    const res: number = await api.unblockCustomerCard(userId);
    showSnackbar(
      "Kundenkarte freigegeben",
      "Kundenkarte konnte nicht freigegeben werden!",
      res
    );
    refetchCustomerCard();
    refetchUser();
  });

  const refetchAll = () => {
    refetchUser();
    refetchCustomerCard();
    refetchShoppingHistory();
    refetchOrderHistory();
  };

  const showSnackbar = (msgSuccess: string, msgError: string, type: number) => {
    displaySnackbar(
      type === 1 ? msgSuccess : msgError,
      type === 1 ? "success" : "error"
    );
  };

  const actions = {
    refetchAll,
    passwordMutation,
    confirmationEmailMutation,
    manuelEmailConfirmationMutation,
    blockCustomerCardMutation,
    unBlockCustomerCardMutation,
  };

  const [user, setUser] = useState<User>();

  useEffect(() => {
    const test = async () => {
      if (users != null) {
        setUser(users.filter((user: User) => user.id === params.id)[0]);
      }
    };
    test();
  }, [users]);

  useEffect(() => {
    if (!user) return;
    if (selectedFlavor.flavor !== user?.flavor)
      return history.push(`/${selectedFlavor.flavor}/users`);
  }, [selectedFlavor, user]);

  if (user == null) {
    return <Loadingspinner />;
  }

  return (
    <Container maxWidth="xl">
      <UserActionBar user={user} actions={actions} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <div className="editUser__title">Pers&ouml;nliche Informationen</div>
          <UserDataCard user={user} customerCard={customerCard ?? undefined} />
        </Grid>
        {user.role !== "Admin" && user.emailConfirmed ? (
          <>
            {hasAccess(role, "orderHistory") &&
              (orderHistory != null ? (
                <Grid item xs={12} md={6} lg={8}>
                  <div className="editUser__title">Bestellhistorie</div>
                  <OrderHistoryTable
                    history={orderHistory}
                    flavor={user.flavor}
                  />
                </Grid>
              ) : (
                <Loadingspinner />
              ))}
            {hasAccess(role, "shoppingHistory") &&
              (shoppingHistory ? (
                <Grid item xs={12} md={6}>
                  <div className="editUser__title">Kaufhistorie</div>
                  <ShoppingHistoryTable history={shoppingHistory} />
                </Grid>
              ) : (
                <Loadingspinner />
              ))}
            {hasAccess(role, "chargingHistory") &&
              (customerCard != null ? (
                <Grid item xs={12} md={6}>
                  <div className="editUser__title">Aufladehistorie</div>
                  <ChargingHistoryTable {...customerCard} />
                </Grid>
              ) : Date.parse(user.lockoutEnd) < Date.now() ? (
                <Loadingspinner />
              ) : null)}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Container>
  );
}

export default EditUser;
