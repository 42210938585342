import { AxiosResponse } from 'axios'; 
import {G2GTableOrderSetting, StoreTableOrderInfo, PatchBranchTableOrderInfo, TableOrderAdminInfo} from '../types/tableordersmodels'
import { axiosInstance } from './axiosConfig';

/**
 * get all BranchTableOrderInfo of a flavor
 *
 * @param flavor AppFlavor to search BranchTableOrderInfo of
 * @returns
 */
export const getBranchTables = async (flavor: string): Promise<StoreTableOrderInfo[]> => {
    
	if (flavor === 'all') return [] as StoreTableOrderInfo[];
	try {
		const res: AxiosResponse = await axiosInstance.get('v1/admin/Tischbestellung/branch-table', {
			headers: { AppFlavour: flavor },
		});
		if (res.data != null) return res.data;
	} catch (error) {
		console.error(error);
	}
    return [] as StoreTableOrderInfo[];
};

/**
 * get all TableOrderAdminInfo of a flavor
 *
 * @param flavor AppFlavor to search TableOrderAdminInfo of
 * @returns
 */
export const getTables = async (flavor: string): Promise<TableOrderAdminInfo[]> => {
    
	if (flavor === 'all') return [] as TableOrderAdminInfo[];
	try {
		const res: AxiosResponse = await axiosInstance.get('v1/admin/Tischbestellung/tables', {
			headers: { AppFlavour: flavor },
		});
		if (res.data != null) return res.data;
	} catch (error) {
		console.error(error);
	}
    return [] as TableOrderAdminInfo[];
};


/**
* get all G2GTableOrderSetting of a flavor
*
* @param flavor AppFlavor to search G2GTableOrderSetting of
* @returns
*/
export const getSetting = async (flavor: string, setting:G2GTableOrderSetting): Promise<any> => {
   
   if (flavor === 'all') return  {} as Record<G2GTableOrderSetting, number>;
   try {
	   const res: AxiosResponse = await axiosInstance.get(`v1/Config/g2gTableSettingsJSON/${G2GTableOrderSetting[setting]}`, {
		   headers: { AppFlavour: flavor },
	   });
	   if (res.data != null) return res.data;
   } catch (error) {
	   console.error(error);
   }
   return {} as Record<G2GTableOrderSetting, number>;
};

/**
 * patch G2GTableOrderSetting of a flavor
 *
 * @param flavor AppFlavor to patch G2GTableOrderSetting of
 * @returns
 */
export const patchSetting = async (flavor: string, setting :G2GTableOrderSetting, settingValue:any ): Promise<boolean> => {
    
	if (flavor === 'all') return false;
	try {
		const res: AxiosResponse = await axiosInstance.patch(`v1/Config/g2gTableSettingsJSON/${G2GTableOrderSetting[setting]}?value=${settingValue}`,
			 {}
			 ,
			 {
				 headers: { AppFlavour: flavor },
	 
	 
			 });
		if (res.status === 200) return true;
	} catch (error) {
		console.error(error);
	}
    return false;
};

/**
 * patch BranchTableOrderInfo of a flavor
 *
 * @param flavor AppFlavor to patch BranchTableOrderInfo of
 * @returns
 */
export const patchBranchTable = async (flavor: string , btables :StoreTableOrderInfo[] ): Promise<1|-1> => {
    
 
	try {
		let tempobject: any = { 
			branchTablePreordability: {} as any,
		  };
		  
		  btables.forEach((btable) => {
			tempobject.branchTablePreordability[btable.storeID] = btable.tableOrderAllowed;
		  }); 
		const res: AxiosResponse = await axiosInstance.patch(`v1/admin/Tischbestellung/branch-table`, 
        { 
			branchTablePreordability :tempobject.branchTablePreordability
		},
        {
			headers: { AppFlavour: flavor },


		});
		if (res.data != null) return res.data;
	} catch (error) {
		console.error(error);
		return -1;
	} 
	return 1;
};