import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import dateformat from 'dateformat';
import React, { useContext, useEffect, useState } from 'react';
import * as api from '../../api/orders';
import { LoginContext } from '../../contexts/LoginContext';
import { OrderDetails, OrderHistory } from '../../types/order';
import { getTwoDecimalPlaces } from '../../util/price';
import OrderHistoryDialog from './OrderHistoryDialog/OrderHistoryDialog';
import './OrderHistoryTable.css';
import orderBy from 'lodash/orderBy';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { IconButton, Popper } from '@material-ui/core';

interface Props {
	history: OrderHistory[];
	flavor: string;
}

function OrderHistoryTable({ history, flavor }: Props) {
	const { selectedFlavor } = useContext(LoginContext);
	const [open, setOpen] = useState<boolean>(false);
	const [orderDetails, setOrderDetails] = useState<OrderDetails>();
	const [selectedOrderId, setSelectedOrderId] = useState<any>();
	const [buttonClicked, setButtonClicked] = useState<boolean>(false);
	const [anchorElTooltip, setAnchorElTooltip] = useState<null | HTMLElement>(null);

	// fetches and shows the "bon" if button clicked and purchchase id was selected
	useEffect(() => {
		const fetchOrderDetails = async () => {
			const data: OrderDetails | null = await api.getOrderDetails(
				selectedOrderId,
				selectedFlavor.flavor,
			);
			if (data != null) setOrderDetails(data);
			setOpen(true);
			setButtonClicked(false);
			setSelectedOrderId(null);
		};
		if (selectedOrderId && buttonClicked) {
			fetchOrderDetails();
		}
	}, [selectedOrderId, buttonClicked]);

	// hides the "kopiert" popup after a delay
	useEffect(() => {
		if (anchorElTooltip == null) return;
		setTimeout(() => {
			setAnchorElTooltip(null);
		}, 1200);
	}, [anchorElTooltip]);

	/** selects the purchase id of the clicked row */
	const selectRow = (params: any) => {
		setSelectedOrderId(params.row.id);
	};

	/** records the button click */
	const handleButtonClick = () => {
		setButtonClicked(true);
	};

	/** copies the purchase id and triggers "kopiert" popup */
	const handleCopyOrderId = (event: React.MouseEvent<HTMLDivElement>) => {
		if (selectedOrderId) navigator.clipboard.writeText(selectedOrderId);
		setAnchorElTooltip(event.currentTarget);
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			flex: 2,
			renderCell: (param) => {
				return (
					<div
						style={{ width: '100%', overflow: 'hidden' }}
						onDoubleClick={handleCopyOrderId}
						onMouseDown={(event) => event.preventDefault()}
					>
						{param.row.id}
					</div>
				);
			},
		},
		{
			field: 'state',
			headerName: 'Status',
			flex: 1,
		},
		{
			field: 'date',
			headerName: 'Datum',
			flex: 1,
			renderCell: (params: GridCellParams) => {
				if (params.row.date === null) {
					return "Keine Daten";
				} else {
					return dateformat(params.row.date, 'dd.mm.yyyy - HH:MM');
				}
			},
		},
		{
			field: 'totalAmount',
			headerName: 'Betrag',
			flex: 1,
			renderCell: (params: GridCellParams) => {
				return `${(Math.round(params.row.totalAmount * 100) / 100).toFixed(2)} ${params.row.paidWithLoyaltyPoints ? 'TP':'€'}`;
			},
		},
		{
			field: '',
			headerName: 'Übersicht',
			width: 110,
			sortable: false,
			align: 'center',
			renderCell: () => {
				return (
					<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleButtonClick}>
						<DescriptionOutlinedIcon />
					</IconButton>
				);
			},
		},
	];

	return (
		<>
			<div className="orderHistoryTable">
				<DataGrid
					rows={orderBy(history, ['date'], ['desc'])}
					columns={columns}
					pageSize={10}
					disableSelectionOnClick
					onCellClick={(element) => {
						if (element.value != null) {
							navigator.clipboard.writeText(element.value.toString());
						}
					}}
					onRowClick={selectRow}
				/>
				{orderDetails != null && (
					<OrderHistoryDialog
						orderDetails={orderDetails}
						flavor={flavor}
						open={open}
						setOpen={setOpen}
					/>
				)}
			</div>
			{/* popup after copying customerCard or userId */}
			{anchorElTooltip != null && (
				<Popper anchorEl={anchorElTooltip} open={anchorElTooltip != null} placement="right">
					<div className="userTable__popper">kopiert</div>
				</Popper>
			)}
		</>
	);
}

export default OrderHistoryTable;
