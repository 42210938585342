import { AxiosResponse } from 'axios';
import { saveAccessToken, getAccessToken } from "../service/Token";
import { axiosInstance, axiosRefreshInstance } from './axiosConfig';
import jwt_decode, { JwtPayload } from 'jwt-decode';

/**
 * logs user in, by getting access and refresh token
 *
 * @param email email of account to login with
 * @param password password of account to login with
 * @returns
 */
export const login = async (email: string, password: string, flavor: string): Promise<AxiosResponse<any> | null> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.post(
			`v1/Auth/login`,
			{
				email: email,
				password: password,
			},
			{
				headers: {
					AppFlavour: flavor,
				},
				validateStatus: function (status) {
					return status <= 300; // Resolve only if the status code is less than 300
				}
			}
		);
		return res;
	} catch (error) {
		console.error(error);
	}
	return null;
};

/**
 * gets new access token with refresh token
 *
 * @returns access token
 */
export const refreshAccessToken = async () => {
	let acctok = getAccessToken();
	let tokenflavor = "Dev";
	let decoded: any;
	if (acctok !== undefined) {
		decoded = jwt_decode<JwtPayload>(acctok.token);
		tokenflavor = decoded['flavor'];
		try {
			const res: AxiosResponse<any> = await axiosRefreshInstance.post(
				`v1/Auth/refresh`,
				{
					accessToken: acctok.token,
					refreshToken: acctok.refreshToken
				},
				{
					headers: {
						AppFlavour: tokenflavor,
					},
				},
			);
			if (res != null) return saveAccessToken(res.data);
		} catch (error) {
			console.error(error);
			saveAccessToken(undefined);
		}
	} else {
		saveAccessToken(undefined);
	}

};
