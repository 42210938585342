import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { GenderCounter } from '../../types/statistics';
import './AgeDoughnutChart.css';

interface Props {
	data: GenderCounter;
}

const AgeDoughnutChart = ({ data }: Props) => {
	const localData = {
		labels: ['männlich', 'weiblich', 'divers', 'familie', 'firma', 'sonstige'],
		datasets: [
			{
				label: '# of Votes',
				data: [data.male, data.female, data.divers, data.family, data.company, data.other],
				backgroundColor: [
					'rgba(54, 162, 235, 1)',
					'rgba(255, 99, 132, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(70, 100, 220, 1)',
					'rgba(130, 252, 94, 1)',
					'rgba(0, 185, 62, 1)',
				],
			},
		],
	};

	return (
		<div className="ageDoughnutChart">
			<Doughnut data={localData} options={{ maintainAspectRatio: false }} />
			<div style={{ position: 'absolute', zIndex: -1, fontSize: '4rem' }}>
				{data.male + data.female + data.divers + data.family + data.company + data.other}
			</div>
		</div>
	);
};

export default AgeDoughnutChart;
