import { Menu, MenuItem } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContext';
import { handleUserDeletion } from '../../service/user';
import { UserManagementActions } from '../../types/actions';
import { LoginContextType } from '../../types/loginContext';
import { User } from '../../types/user';
import { hasAccess } from '../../util/authorization';
import { SnackBarContext } from '../CustomAlertSnackBar/CustomAlertSnackBarContext';

interface Props {
	user: User;
	anchorElement: null | HTMLElement;
	setDialogHeading: React.Dispatch<React.SetStateAction<string>>;
	setDialogMessage: React.Dispatch<React.SetStateAction<string>>;
	setDialogButtonText: React.Dispatch<React.SetStateAction<string>>;
	setDialogOnAccept: React.Dispatch<React.SetStateAction<Function>>;
	setAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
	setOpenSecurityDialog: React.Dispatch<React.SetStateAction<boolean>>;
	setRoleSelection: React.Dispatch<React.SetStateAction<boolean>>;
	newUserRole: 'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | 'Customer';
	actions: UserManagementActions;
}

const UsersQuickMenu = ({
	user,
	anchorElement,
	setDialogHeading,
	setDialogMessage,
	setDialogButtonText,
	setDialogOnAccept,
	setAnchorElement,
	setOpenSecurityDialog,
	setRoleSelection,
	newUserRole,
	actions,
}: Props) => {
	const { displaySnackbar } = useContext(SnackBarContext);
	const history = useHistory();
	const { role, selectedFlavor }: LoginContextType = useContext(LoginContext);

	const handleClose = () => {
		setAnchorElement(null);
	};

	return (
		<Menu anchorEl={anchorElement} keepMounted open={Boolean(anchorElement)} onClose={handleClose}>
			{selectedFlavor.flavor === 'all' ? (
				<div></div>
			) : (
				<div>
					{hasAccess(role, 'resetPassword') && (
						<MenuItem
							onClick={() => {
								setDialogHeading('Rolle des Nutzers ändern?');
								setDialogMessage('Welche Rolle soll der Nutzer erhalten?');
								setDialogButtonText('Rolle speichern');
								setRoleSelection(true);
								setDialogOnAccept(() => () => {
									actions.changeRole.mutate({
										userFlavor: user.flavor,
										userId: user.id,
										newRole: newUserRole,
									});
									handleClose();
								});
								setOpenSecurityDialog(true);
							}}
						>
							Rolle ändern
						</MenuItem>
					)}
				</div>
			)}
			<div></div>
		</Menu>
	);
};

export default UsersQuickMenu;
