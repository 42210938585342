import { Chip, Container, Grid } from '@material-ui/core';
import { initial } from 'lodash';
import { parse } from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { getCategories, getProductAvailability, getProducts } from '../../api/product';
import Category from '../../components/Category/Category';
import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import Product from '../../components/Product/Product';
import ProductPopup from '../../components/ProductPopup/ProductPopup';
import ProductSearchbar from '../../components/ProductSearchbar/ProductSearchbar';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContextType } from '../../types/loginContext';
import { Product as ProductType, ProductCategory } from '../../types/product';
import './ProductOverview.css';

const ProductOverview = () => {
	const history = useHistory();
	const location = useLocation();
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);
	const {
		data: categoryData,
		isLoading: categoriesLoading,
		refetch: refetchCategories,
	} = useQuery('getCategories', () => getCategories(selectedFlavor.flavor), {
		staleTime: 1000 * 60 * 60,
	});
	const {
		data: productData,
		isLoading: productsLoading,
		refetch: refetchProducts,
	} = useQuery('getproducts', () => getProducts(selectedFlavor.flavor), {
		staleTime: 1000 * 60 * 60,
	});

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [categories, setCategories] = useState<ProductCategory[] | null>();
	const [products, setProducts] = useState<ProductType[] | null>();
	const [showCategories, setShowCategories] = useState<boolean>(true);
	const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>();
	const [selectedProduct, setSelectedProduct] = useState<ProductType | null>();
	const [isProductPopupOpen, setIsProductPopupOpen] = useState<boolean>(false);
	const [initialLoading, setInitialLoading] = useState<boolean>(true);

	useEffect(() => {
		const urlCategorie = parse(location.search).category;
		const urlProduct = parse(location.search).product;
		if (urlCategorie && categories) {
			const foundCategory = categories.find(
				(category: ProductCategory) => category.id.toString() === urlCategorie.toString(),
			);
			setSelectedCategory(foundCategory);
		}
		if (urlProduct && productData) {
			const foundArticle = productData.find(
				(product: ProductType) => product.productID.toString() === urlProduct.toString(),
			);
			if (foundArticle && (!urlCategorie || urlCategorie === foundArticle.categoryID.toString())) {
				setSelectedProduct(foundArticle);
				setIsProductPopupOpen(true);
			}
			setSearchTerm(urlProduct.toString());
		}
		setInitialLoading(false);
	}, [location, categories, productData]);

	useEffect(() => {
		setCategories(null);
		setProducts(null);
		refetchCategories();
		refetchProducts();
	}, [selectedFlavor]);

	useEffect(() => {
		if (categoryData && categoryData.length >= 1) setCategories(categoryData);
		else setCategories([]);
		if (productData && productData.length >= 1) setProducts(productData);
		else setProducts([]);
	}, [categoryData, productData]);

	useEffect(() => {
		if (!productData || productData.length < 1) return setShowCategories(true);
		if (searchTerm === '' && !selectedCategory) return setProducts(productData);
		setShowCategories(false);
		if (selectedCategory && searchTerm !== '') {
			setProducts(
				filterProductsBySearchTerm(
					filterProductsByCategory(productData, selectedCategory),
					searchTerm,
				),
			);
		} else if (selectedCategory) {
			setProducts(filterProductsByCategory(productData, selectedCategory));
		} else if (searchTerm !== '') {
			setProducts(filterProductsBySearchTerm(productData, searchTerm));
		}
	}, [searchTerm, selectedCategory, productData]);

	useEffect(() => {
		if (initialLoading) return;
		history.push({
			pathname: location.pathname,
			search: `
			${selectedCategory || selectedProduct ? '' : ''}
			${selectedCategory ? 'category=' + selectedCategory.id : ''}
			${selectedCategory && selectedProduct ? '&' : ''}
			${selectedProduct ? 'product=' + selectedProduct.productID : ''}`,
		});
	}, [selectedProduct, selectedCategory]);

	if (selectedFlavor.flavor === 'all') {
		return (
			<div className="productAvailability__noFlavorSelected">
				Es muss ein Flavor ausgew&auml;hlt werden um die Produkte anzeigen zu k&ouml;nnen
			</div>
		);
	}

	if (products == null || categories == null) {
		return <Loadingspinner />;
	}

	if (categoriesLoading || productsLoading) {
		return <div>Laden...</div>;
	}

	return (
		<div>
			<div className="productAvailability__tagContainer">
				{/* Searchbar */}
				<ProductSearchbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
				{/* shows Product Tag if products are shown */}
				{showCategories ? (
					''
				) : (
					<Chip
						label="Produkte"
						onDelete={() => {
							setShowCategories(true);
							setSelectedCategory(null);
							setSearchTerm('');
						}}
						className="productAvailability__tag"
					/>
				)}
				{/* show tag if a category is selected */}
				{selectedCategory ? (
					<Chip
						label={selectedCategory.name}
						onDelete={() => setSelectedCategory(null)}
						className="productAvailability__tag"
					/>
				) : (
					''
				)}
				{/* show tag with searchTerm if there is an input */}
				{searchTerm === '' ? (
					''
				) : (
					<Chip
						label={searchTerm}
						onDelete={() => setSearchTerm('')}
						className="productAvailability__tag"
					/>
				)}
			</div>
			<Container maxWidth="xl" style={{ padding: '0px' }}>
				<Grid container spacing={3}>
					{showCategories ? (
						!categories || categories.length < 1 ? (
							<Grid item>Keine Kategorien verfügbar</Grid>
						) : (
							categories.map((category: ProductCategory, index: number) => {
								// Grid-Items for showing Categories
								return (
									<Grid item xs={6} sm={4} md={3} lg={2} key={category.id}>
										<Category
											category={category}
											onClick={() => {
												setSelectedCategory(categories[index]);
												setShowCategories(false);
											}}
										/>
									</Grid>
								);
							})
						)
					) : !products || products.length < 1 ? (
						<Grid item>Keine passenden Produkte gefunden</Grid>
					) : (
						products.map((product: ProductType) => {
							// Grid-Items for showing Products
							return (
								<Grid item xs={6} sm={4} md={3} lg={2} key={product.productID}>
									<div
										onClick={() => {
											setSelectedProduct(product);
											setIsProductPopupOpen(true);
										}}
									>
										<Product product={product} availabilityBitmask={1} />
									</div>
								</Grid>
							);
						})
					)}
				</Grid>
			</Container>
			<ProductPopup
				isOpen={isProductPopupOpen}
				onClose={() => {
					setIsProductPopupOpen(false);
					setSelectedProduct(undefined);
				}}
				productId={selectedProduct?.productID}
				flavor={selectedFlavor.flavor}
			/>
		</div>
	);
};

const filterProductsByCategory = (
	products: ProductType[],
	category: ProductCategory,
): ProductType[] => {
	return products.filter((product: ProductType) => product.categoryID === category.id);
};

const filterProductsBySearchTerm = (products: ProductType[], searchTerm: string): ProductType[] => {
	return products.filter(
		(product: ProductType) =>
			product.name.toLowerCase().includes(searchTerm) ||
			product.productID.toString().includes(searchTerm),
	);
};

export default ProductOverview;
