import jwt_decode, { JwtPayload } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContext';
import { CheckLocalToken, getAccessToken } from "../../service/Token";

interface Props {
	location?: any;
}

function AutoLogin({ location }: Props) {
	const history = useHistory();
	const { role, setRole, logout }: any = useContext(LoginContext);
	const [loginFailed, setLoginFailed] = useState<boolean>(false);

	useEffect(() => {
		const login = async () => {
			if (localStorage.getItem('userLoggedOut') === 'true') {
				setLoginFailed(true);
				return;
			}
			if (role === '' || getAccessToken() === undefined) {
				try {
					let cacheToken = await CheckLocalToken();
					if (cacheToken === 1) {
						try {
							let temptoken = getAccessToken();
							if (temptoken !== undefined) {
								const token: any = jwt_decode<JwtPayload>(temptoken.token);
								const tokenRole = token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
								setRole(tokenRole);
								history.push(`${location.pathname ? location.pathname + location.search : ''}`);
							}
						} catch (error) {
							console.error('invalid jwt');
							setLoginFailed(true);
						}
					} else {
						setLoginFailed(true);
					}
				} catch (error) {
					console.error('all sessions closed');
					setLoginFailed(true);
				}
			}
		};
		login();
	}, []);

	if (loginFailed) {
		logout();
		console.log('Autologin failed.');
		return <Redirect to="/login" />;
	}

	return <div>Versuche einzuloggen...</div>;
}

export default AutoLogin;
