import { Dialog, DialogContentText, DialogTitle } from '@material-ui/core';
import dateformat from 'dateformat';
import React, { useEffect, useState } from 'react';
import * as api from '../../../api/orders';
import { OrderDetails } from '../../../types/order';
import { ProductById } from '../../../types/product';
import { getTwoDecimalPlaces } from '../../../util/price';
import './OrderHistoryDialog.css';

interface Props {
	orderDetails: OrderDetails;
	flavor: string;
	open: boolean;
	setOpen: Function;
}

function OrderHistoryDialog({ orderDetails, flavor, open, setOpen }: Props) {
	const [productNames, setProductNames] = useState<(string | null)[]>([]);

	useEffect(() => {
		const fetch = async () => {
			const data: (string | null)[] = [];
			let i = 0;
			for (const product of orderDetails.products) {
				let temp: ProductById | string | null = await api.getProductById(product.productID, flavor);
				if (temp != null) temp = temp.title;
				data[i] = temp;
				i++;
			}
			setProductNames(data);
		};
		fetch();
	}, [orderDetails]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			className="orderHistoryDialog"
			fullWidth={true}
			maxWidth={'sm'}
			onClose={handleClose}
			aria-labelledby="orderhistory-document"
			open={open}
		>
			{orderDetails != null ? (
				<>
					{/* Brachdetails */}
					<DialogTitle className="orderHistoryDialog__dialogTitle">Filiale</DialogTitle>
					<DialogContentText className="orderHistoryDialog__branchInfo">
						<div>{orderDetails.info.filialName}</div>
						<div>{orderDetails.info.filialStreet + ' ' + orderDetails.info.filialStreetNumber}</div>
						<div>{orderDetails.info.filialPostcode + ' ' + orderDetails.info.filialCity}</div>
						&nbsp;
						<div>Tel.: {orderDetails.info.filialTelephone}</div>
						<div>Web: {orderDetails.info.filialWebsite}</div>
					</DialogContentText>

					{/* Date and Time */}
					<DialogTitle className="orderHistoryDialog__dialogTitle">Datum & Uhrzeit</DialogTitle>
					<DialogContentText>
						<div className="orderHistoryDialog__text">
							Bestelldatum: {dateformat(orderDetails.purchaseDate, 'dd.mm.yyyy - HH:MM')}&nbsp;Uhr
						</div>
						<div className="orderHistoryDialog__text">
							Abholdatum: {dateformat(orderDetails.collectTime, 'dd.mm.yyyy - HH:MM')}&nbsp;Uhr
						</div>
					</DialogContentText>

					{/* Orderdetails */}
					<DialogTitle className="orderHistoryDialog__dialogTitle">Einkauf</DialogTitle>
					<DialogContentText>
						<table className="orderHistoryDialog__table">
							<tr>
								<th className="orderHistoryDialog__column10">Anzahl</th>
								<th className="orderHistoryDialog__column70">Artikel</th>
								<th className="orderHistoryDialog__column10" style={{ textAlign: 'right' }}>
									Preis <br />
									EUR
								</th>
								<th className="orderHistoryDialog__column10" style={{ textAlign: 'right' }}>
									gesamt <br />
									EUR
								</th>
							</tr>
							{/* fill table with each product */}
							{orderDetails.products.map((product, index) => {
								return (
									<tr>
										<td>{product.amount}x</td>
										<td>
											{productNames[index] ?? '???'} <br />
											ArtNr.: {product.productID}
										</td>
										<td className="orderHistoryDialog__textAlignRight">
											{getTwoDecimalPlaces(product.unitPrice)}&euro;
										</td>
										<td className="orderHistoryDialog__textAlignRight">
											{getTwoDecimalPlaces(product.totalPrice)}&euro;
										</td>
									</tr>
								);
							})}
						</table>
						<hr />
						<div className="orderHistoryDialog__priceSummary">
							<p>Gesamtsumme</p>
							<p className="orderHistoryDialog__sum">
								{getTwoDecimalPlaces(orderDetails.paymentMethods[0].value)}&euro;
							</p>
						</div>
						<div className="orderHistoryDialog__priceSummary">
							<p>{orderDetails.paymentMethods[0].name}</p>
							<p>{getTwoDecimalPlaces(orderDetails.paymentMethods[0].value)}&euro;</p>
						</div>
					</DialogContentText>
					{orderDetails.comment != null ? (
						<>
							<DialogTitle className="orderHistoryDialog__dialogTitle">Kommentar</DialogTitle>
							{/* TODO hier ist das margin nach links nicht angezeigt, obwohl gleiche css klasse wie bei dateAndTime */}
							<DialogContentText className="orderHistoryDialog__text">
								{orderDetails.comment}
							</DialogContentText>
						</>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
		</Dialog>
	);
}

export default OrderHistoryDialog;
