import { AvailabilityProduct, Product } from '../types/product';
import { Availability } from '../types/productAvailability';
import { getUnavailabilityReasons } from './bitmask';

/**
 * get the name of a prod
 *
 * @param id productId to get the name of
 * @param products product array to find the name in
 * @returns
 */
export const getProductName = (id: string, products: Product[]): string => {
	let productName = '';
	if (products != null) {
		products.forEach((product) => {
			if (product.productID.toString() === id) {
				productName = product.name;
			}
		});
	}
	return productName;
};

/**
 *
 * @param products
 * @param availability
 */
export const getProductsWithAvailability = (
	products: Product[],
	availabilities: Availability[],
): AvailabilityProduct[] => {
	let result: AvailabilityProduct[] = [];
	if (!products) return result;
	products.forEach((product: Product) => {
		let pushed: boolean = false;
		availabilities.forEach((available: Availability) => {
			if (product.productID.toString() === available[0]) {
				pushed = true;
				result.push({
					...product,
					availability: getReasons(available[1]),
				});
			}
		});
	});
	return result;
};

const getReasons = (availability: number) => {
	const reasons = getUnavailabilityReasons(availability);
	const result = [];
	for (let reason of Object.entries(reasons)) {
		result.push(reason);
	}
	return result;
};
