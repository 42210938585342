import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import './ProductSearchbar.css';

interface Props {
	searchTerm: string;
	setSearchTerm: Function;
}

const ProductSearchbar = ({ searchTerm, setSearchTerm }: Props) => {
	return (
		<div className="productSearchbar">
			<FormControl variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Produktsuche</InputLabel>
				<OutlinedInput
					label="Produktsuche"
					value={searchTerm}
					onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
					endAdornment={
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					}
				/>
			</FormControl>
		</div>
	);
};

export default ProductSearchbar;
