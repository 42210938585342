import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getProductById } from '../../api/orders';
import { Nutrition, ProductById } from '../../types/product';
import './ProductPopup.css';
import { AiOutlineClose } from 'react-icons/ai';

interface Props {
	isOpen: boolean;
	onClose: Function;
	productId: number | undefined;
	flavor: string;
}

const ProductPopup = ({ isOpen, onClose, productId, flavor }: Props) => {
	const [product, setProduct] = useState<ProductById | null>(null);

	useEffect(() => {
		const loadProduct = async () => {
			if (productId == null) return;
			const res: ProductById | null = await getProductById(productId.toString(), flavor);
			if (res == null) return;
			setProduct(res);
		};
		loadProduct();
	}, [productId]);

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				onClose();
				setProduct(null);
			}}
		>
			{product == null ? (
				<></>
			) : (
				<>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<DialogTitle>Produktinformationen</DialogTitle>
						<DialogTitle>
							<AiOutlineClose
								style={{ cursor: 'pointer' }}
								onClick={() => {
									onClose();
									setProduct(null);
								}}
							/>
						</DialogTitle>
					</div>
					<img src={product.headerImage} alt="Produktbild" />
					<DialogContent>
						{product.description != null && product.description !== '' && (
							<>
								<h3>Beschreibung</h3>
								{product.description}
							</>
						)}
						{product.unstructuredInfo != null && product.unstructuredInfo !== '' && (
							<>
								<h3>Infos</h3>
								{product.unstructuredInfo}
								{product.allergens != null && product.allergens !== '' && (
									<>
										<h3>Allergene</h3>
										{product.allergens}
									</>
								)}
							</>
						)}
						{product.nutrition != null && (
							<>
								{product.ingredients != null && product.ingredients !== '' && (
									<>
										<h3>Zutaten</h3>
										{product.ingredients}
									</>
								)}

								{product.nutrition != null && product.nutrition.length > 0 && (
									<>
										<h3>Nährwerte</h3>
										<div style={{ width: '100%' }}>
											<div className="productPopup__tableheader">pro 100g</div>
											{product.nutrition.map((nutrition: Nutrition) => {
												return (
													<div
														className="productPopup__tablerow"
														style={{ display: 'flex', justifyContent: 'space-between' }}
													>
														<div>{nutrition.section}</div>
														<div>{nutrition.value}</div>
													</div>
												);
											})}
										</div>
									</>
								)}

								{product.allergens != null && product.allergens !== '' && (
									<>
										<h3>Allergene</h3>
										{product.allergens}
									</>
								)}
							</>
						)}
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};

export default ProductPopup;
