import { CircularProgress } from '@material-ui/core';
import React from 'react';
import './Loadingspinner.css';

function Loadingspinner() {
	return (
		<div className="loadingSpinner">
			<CircularProgress />
		</div>
	);
}

export default Loadingspinner;
