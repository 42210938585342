import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import dateformat from 'dateformat';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { CustomerCard } from '../../types/customerCard';
import './ChargingHistoryTable.css';

function ChargingHistoryTable(customerCard: CustomerCard) {
	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID' },
		{
			field: 'chargingDate',
			headerName: 'Datum',
			flex: 1,
			renderCell: (params: GridCellParams) => {
				if (params.row.chargingDate === null) {
					return "Keine Daten";
				} else {
					return dateformat(params.row.chargingDate, 'dd.mm.yyyy - HH:MM');
				}
			},
		},
		{
			field: 'price',
			headerName: 'Betrag',
			flex: 1,
			renderCell: (params: GridCellParams) => {
				return params.row.price.toFixed(2);
			},
		},
		{ field: 'priceUnit', headerName: 'Währung', flex: 1 },
		{ field: 'chargingPlatform', headerName: 'Plattform', flex: 1 },
	];

	return (
		<div className="chargingHistoryTable">
			<DataGrid
				rows={orderBy(customerCard.chargingHistory.changed, ['chargingDate'], ['desc'])}
				columns={columns}
				pageSize={10}
				disableSelectionOnClick
			/>
		</div>
	);
}

export default ChargingHistoryTable;
