import React, { ChangeEvent,useContext, useEffect, useState } from 'react';
import { Button,Checkbox, Container, Grid, TextField } from '@material-ui/core';
import TableOrderAdminInfoTable from '../../components/TableOrderAdminInfoTable/TableOrderAdminInfoTable';
import BranchTableOrderInfoTable from '../../components/BranchTableOrderInfoTable/BranchTableOrderInfoTable';
import { getBranchTables, getTables, patchBranchTable, getSetting, patchSetting } from '../../api/tableorders'; 
import {doApiRequestGET, doApiRequestPATCHJSON, G2GSetting} from '../../api/g2gSettings'
import { hasAccess } from '../../util/authorization';
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import {
	G2GTableOrderSetting,
	StoreTableOrderInfo, 
	TableOrderAdminInfo
} from '../../types/tableordersmodels';

import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContextType } from '../../types/loginContext';
import './TableOrders.css';

const TableOrders = () => {

	const { selectedFlavor, role }: LoginContextType = useContext(LoginContext);
	const [branchTables, setBranchTables] = useState<StoreTableOrderInfo[]>([]);
	const [tables, setTables] = useState<TableOrderAdminInfo[]>([]);
	const [branchTablesChanged, setBranchTablesChanged ] = useState<boolean>(false);
	const { displaySnackbar } = useContext(SnackBarContext);
	const [tableWebUrl, setTableWebUrl] = useState<string>("");
	
	const [deeplinkPopupIntervallMS, setDeeplinkPopupIntervallMS] = useState<number>(0);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [orderingAllowedOnlyPerApp, setOrderingAllowedOnlyPerApp] =useState<boolean>(true); 
 const handleChecked = (e: any) => {
	console.log(e.target.checked)
	setOrderingAllowedOnlyPerApp(e.target.checked);
};
	useEffect(() => {
		if(selectedFlavor.flavor ==='all') return;
		setIsLoading(true);
		doApiCalls();
setBranchTablesChanged(false);
		function doApiCalls() {
		
		 
		getBranchTables(selectedFlavor.flavor)
		.then((value) => {
			if (value !== null ){
				const bt =value.sort((a,b)=>a.storeNumber-b.storeNumber) 
				setBranchTables(bt) 
			} 
	}).catch((error) => console.error('Fehler beim holen die Filiale Tischbestellung Bestellbarkeit.', error));
		
	getTables(selectedFlavor.flavor)
	.then((value) => {
		if (value !== null ){
			
			const t =value.sort((a,b)=>a.storeNumber-b.storeNumber) 
			setTables(t) 
		} 
	
}).catch((error) => console.error('Fehler beim holen die Tischen Informationen.', error));
	
getSetting(selectedFlavor.flavor, G2GTableOrderSetting.OrderingAllowedOnlyPerApp)	
.then((value) => {
	if (value !== null ){ 
		setOrderingAllowedOnlyPerApp(value===true? true : (value===1 ? true:false)); 
	} }).catch((error) => console.error('Fehler beim holen der Einstellungen für Tischbestellung : Erlauben Vorbestellung nur in der App.', error));

doApiRequestGET(G2GSetting.TischbestellungFrontEndURL ,selectedFlavor.flavor)	
	.then((value) => {
		if (value !== null ){ 
			setTableWebUrl(value.value===null ? "":value.value.toString()); 
		} }).catch((error) => console.error('Fehler beim holen der Einstellungen für Tischbestellung : Tischbestellung Web Url.', error));
getSetting(selectedFlavor.flavor, G2GTableOrderSetting.DeeplinkPopupIntervallMS)	
		.then((value) => {
			if (value !== null ){ 
				setDeeplinkPopupIntervallMS(value as number); 
			} }).catch((error) => console.error('Fehler beim holen der Einstellungen für Tischbestellung : Deeplink-Popup-Intervall in Millisekunden', error));
		
setIsLoading(false);
	}},  [selectedFlavor]);

 const triggerSetBranchTablesChanged = ( ) => {
		if(!branchTablesChanged) setBranchTablesChanged(true);
	};

	const changeTableOrderAllowed = (branchID:number, tableOrderAllowed:boolean ) => {
	var btToChange=	branchTables.find(x=>x.storeID==branchID)  
	if(btToChange)
	btToChange.tableOrderAllowed=tableOrderAllowed
	};

	const patchBranchTablesPreordability = async()=>{
		if(!branchTablesChanged) return; 
		const res = await patchBranchTable(selectedFlavor.flavor, branchTables)
		if(res ==-1) {displaySnackbar('Die Änderungen an der Vorbestellungsmöglichkeit für die Filiale konnten nicht gespeichert werden.', 'error');return;}
			displaySnackbar('Die Änderungen an der Vorbestellungsmöglichkeit für die Filiale wurden erfolgreich gespeichert.', 'success');
			setBranchTablesChanged(false);
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);

	if (selectedFlavor.flavor === 'all') {
		return (
			<div className="branch__noFlavorSelected">
				Es muss ein Flavor ausgewählt werden um die Filialen anzeigen zu können
			</div>
		);
	}

	if (isLoading ) {
		return <Loadingspinner />;
	}
	else if(isSaving){
		return (
		<div>
						<Loadingspinner  />
			<div className='grid__title t_textfield'>
			Änderungen werden gespeichert...
			</div>

		</div>);
	}
else{
	return (
		<Container maxWidth="xl">
			<Grid container spacing={3}>
				{hasAccess(role, 'tableorders_sa') &&
			<Grid item xs={8}  >  
<div>
			{/* url */} 
			<TextField 
			    className='  t_textfield_url '  
				label="Tischbestellung Web Page Url"
				required
				value={tableWebUrl}
				onChange={(event: ChangeEvent<{ value: any }>) => setTableWebUrl(event.target.value)}
				margin='dense'			/>
			</div>
			<div>
			<TextField 
			    className='  t_textfield_url '  
				label="Deeplink-Popup-Intervall in Millisekunden"
				required
				value={deeplinkPopupIntervallMS}
				onChange={(event: ChangeEvent<{ value: any }>) => setDeeplinkPopupIntervallMS(event.target.value)}
				margin='dense'			/></div>
				<div>
					<label >
					<Checkbox  
					checked={orderingAllowedOnlyPerApp}  
					onChange={handleChecked} />
					{"Erlauben Vorbestellung nur in der App"}
					</label>
				</div>
				
				<div> 
			
			<Button
				onClick={async () => {
					setIsSaving(true);
					const res = await patchSetting(selectedFlavor.flavor,G2GTableOrderSetting.OrderingAllowedOnlyPerApp,  orderingAllowedOnlyPerApp);
					if (!res) {
						displaySnackbar(`Die Einstellung \„Vorbestellungen nur in der App zulassen\“ konnte nicht auf den Wert  ${orderingAllowedOnlyPerApp} gesetzt werden.`, 'error');
					} 
					const res2 = await doApiRequestPATCHJSON(G2GSetting.TischbestellungFrontEndURL, selectedFlavor.flavor, tableWebUrl);
					if (!res2) {
						displaySnackbar(`Die Einstellung \„Tischbestellung web page url\“ konnte nicht auf den Wert ${tableWebUrl} gesetzt werden.`, 'error');
					} 
					const res3 = await patchSetting(selectedFlavor.flavor,G2GTableOrderSetting.DeeplinkPopupIntervallMS,  deeplinkPopupIntervallMS);
					if (!res3) {
						displaySnackbar(`Die Einstellung \„Deeplink-Popup-Intervall in Millisekunden\“ konnte nicht auf den Wert ${deeplinkPopupIntervallMS} gesetzt werden.`, 'error');
					} 
				
					if(res&&res2&&res3) {
						displaySnackbar("Einstellung erfolgreich geändert.", 'success')
					} 
					setIsSaving(false);
				}}
				className="to__button"
				color="secondary"
				variant="text"
			>
				Einstellungen speichern
			</Button>
			</div>
		 
				</Grid>
				}
				<Grid item xs={8}  >
					<div className="grid__title">Bestellbarkeit der Filialen</div>
					<Grid className='branchtables_grid'>
					{branchTablesChanged && <Button onClick={patchBranchTablesPreordability} style={{margin:'1% 2%'}} color="secondary" variant="text"  >
							Änderungen speichern
						</Button> }
						<BranchTableOrderInfoTable
						data={branchTables} setChanged={triggerSetBranchTablesChanged} changeTableOrderAllowed={changeTableOrderAllowed}
					/>
					
					</Grid>
				</Grid>
				<Grid item xs={12}  >
					<div className="grid__title">Tisch Informationen</div>
					<TableOrderAdminInfoTable data={tables}   />
				</Grid>
			</Grid>
		</Container>
	);}
};

export default TableOrders;
