import { Menu, MenuItem } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContext';
import { handleUserDeletion } from '../../service/user';
import { Actions } from '../../types/actions';
import { LoginContextType } from '../../types/loginContext';
import { User } from '../../types/user';
import { hasAccess } from '../../util/authorization';
import { SnackBarContext } from '../CustomAlertSnackBar/CustomAlertSnackBarContext';

interface Props {
	user: User;
	anchorElement: null | HTMLElement;
	setDialogHeading: React.Dispatch<React.SetStateAction<string>>;
	setDialogMessage: React.Dispatch<React.SetStateAction<string>>;
	setDialogButtonText: React.Dispatch<React.SetStateAction<string>>;
	setDialogOnAccept: React.Dispatch<React.SetStateAction<Function>>;
	setAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
	setOpenSecurityDialog: React.Dispatch<React.SetStateAction<boolean>>;
	setOpenChargeDialog: React.Dispatch<React.SetStateAction<boolean>>;
	actions: Actions;
}

const UsersQuickMenu = ({
	user,
	anchorElement,
	setDialogHeading,
	setDialogMessage,
	setDialogButtonText,
	setDialogOnAccept,
	actions,
	setAnchorElement,
	setOpenChargeDialog,
	setOpenSecurityDialog,
}: Props) => {
	const { displaySnackbar } = useContext(SnackBarContext);
	const history = useHistory();
	const { role, selectedFlavor }: LoginContextType = useContext(LoginContext);

	const handleClose = () => {
		setAnchorElement(null);
	};

	return (
		<Menu anchorEl={anchorElement} keepMounted open={Boolean(anchorElement)} onClose={handleClose}>
			{selectedFlavor.flavor === 'all' ? (
				<></>
			) : (
				<>
					{hasAccess(role, 'userOverview') && (
						<MenuItem onClick={() => history.push(`/${selectedFlavor.flavor}/users/${user.id}`)}>
							&Uuml;bersicht
						</MenuItem>
					)}
					{hasAccess(role, 'resetPassword') && (
						<MenuItem
							onClick={() => {
								setDialogHeading('Email zum Passwort Zurücksetzen senden?');
								setDialogMessage(
									'Dem Nutzer wird eine Email zum zurücksetzen seines Passworts gesendet.',
								);
								setDialogButtonText('Email senden');
								setDialogOnAccept(() => () => {
									actions.passwordMutation.mutate(user);
									handleClose();
								});
								setOpenSecurityDialog(true);
							}}
						>
							Passwort zur&uuml;cksetzen
						</MenuItem>
					)}
				</>
			)}
			{user.emailConfirmed === false ? (
				<div>
					{hasAccess(role, 'resendconfirmationmail') && (
						<MenuItem
							onClick={() => {
								setDialogHeading('Email bestätigung erneut senden?');
								setDialogMessage(
									'Dem Nutzer wird dann eine Email zum bestätigen seines Kontos gesendet.',
								);
								setDialogButtonText('Email senden');
								setDialogOnAccept(() => () => {
									actions.confirmationEmailMutation.mutate(user.id);
									handleClose();
								});
								setOpenSecurityDialog(true);
							}}
						>
							Email Bestätigung erneut senden
						</MenuItem>
					)}
					{hasAccess(role, 'manuelEmailConfirmation') && (
						<MenuItem
							onClick={() => {
								setDialogHeading('Email manuell bestätigen?');
								setDialogMessage(
									'Der Nutzer ist dann bestätigt und muss sein Konot nicht selbständig bestätigen.',
								);
								setDialogButtonText('Konto bestätigen');
								setDialogOnAccept(() => () => {
									actions.manuelEmailConfirmationMutation.mutate(user);
									handleClose();
								});
								setOpenSecurityDialog(true);
							}}
						>
							Email manuell best&auml;tigen
						</MenuItem>
					)}
				</div>
			) : (
				<div>
					{Date.parse(user.lockoutEnd) < Date.now()
						? hasAccess(role, 'blockCustomerCard') && (
								<MenuItem
									onClick={() => {
										setDialogHeading('Kundenkarte sperren?');
										setDialogMessage(
											'Diese kann dann bis zu einer Entsperrung der Karte nicht genutzt werden.',
										);
										setDialogButtonText('Karte sperren');
										setDialogOnAccept(() => () => {
											actions.blockCustomerCardMutation.mutate(user.id);
											handleClose();
										});
										setOpenSecurityDialog(true);
									}}
								>
									Karte sperren
								</MenuItem>
						  )
						: hasAccess(role, 'unblockCustomerCard') && (
								<MenuItem
									onClick={() => {
										setDialogHeading('Kundenkarte freischalten?');
										setDialogMessage('Diese kann vom Nutzer dann wieder genutzt werden.');
										setDialogButtonText('Karte freischalten');
										setDialogOnAccept(() => () => {
											actions.unBlockCustomerCardMutation.mutate(user.id);
											handleClose();
										});
										setOpenSecurityDialog(true);
									}}
								>
									Karte freischalten
								</MenuItem>
						  )}
					{hasAccess(role, 'chargeCustomerCard') && (
						<MenuItem
							onClick={() => {
								setOpenChargeDialog(true);
								handleClose();
							}}
						>
							Kundenkarte aufladen
						</MenuItem>
					)}
				</div>
			)}
			{hasAccess(role, 'deleteUser') && (
				<MenuItem
					onClick={() => {
						setDialogHeading('Nutzer wirklich löschen?');
						setDialogMessage(
							'Dies Änderung ist endgültig und kann NICHT rückgängig gemacht werden!',
						);
						setDialogButtonText('Löschen');
						setDialogOnAccept(() => () => {
							handleUserDeletion(user, displaySnackbar);
							handleClose();
						});
						setOpenSecurityDialog(true);
					}}
				>
					Nutzer L&ouml;schen
				</MenuItem>
			)}
		</Menu>
	);
};

export default UsersQuickMenu;
