import { Button, Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as api from '../../api/auth';
import { getLoginFlavourChoice } from "../../api/flavorChoice";
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import { LoginContext } from '../../contexts/LoginContext';
import { saveAccessToken } from "../../service/Token";
import { Flavor, LoginOnlyFlavor } from "../../types/flavor";
import { LoginContextType } from '../../types/loginContext';
import './LoginScreen.css';

const LoginScreen = () => {
	const { displaySnackbar } = useContext(SnackBarContext);
	const { setRole, selectedFlavor, setLoginFlavors, setTempEmail, setTempPassword, setChosenFlavor }: LoginContextType = useContext(LoginContext);
	let history = useHistory();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [flavorChoice, setFlavorChoice] = useState('');
	const [flavorList, setFlavorList] = useState<LoginOnlyFlavor[]>([]);
	const [loginState, setLoginState] = useState<1 | 2 | 3>(1);

	const login = async (email: string, password: string, flavor: string) => {
		const jwt = await api.login(email, password, flavor);
		if (jwt == null) {
			displaySnackbar('Login fehlgeschlagen!', 'error');
			return console.error('Token undefined');
		}

		//User in mehreren Flavors, auf auswahl umleiten
		if (jwt.status === 300) {
			setTempEmail(email);
			setTempPassword(password);
			setLoginFlavors(jwt.data);
			history.push('/flavorchoice');
		} else {
			try {
				const token: any = jwt_decode<JwtPayload>(jwt.data.token);
				setRole(token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
				saveAccessToken(jwt.data);
				localStorage.removeItem('userLoggedOut');
				history.push(`/${selectedFlavor.flavor}/`);
			} catch (error) {
				console.error('invalid jwt');
				history.push('/login');
			}
		}
	};

	const mailButton = async () => {
		const flavors = await getLoginFlavourChoice(email);
		if (flavors === null) {
			//null = fehler von der API zurück
			displaySnackbar('Login fehlgeschlagen!', 'error');
		} else {
			if (flavors.length) {
				//length = kein leeres array, passt
				if (flavors.length === 1) {
					//length 1, also nur den einen als gewählt setzen und direkt zum Passwort
					setFlavorChoice(flavors[0].flavor);
					setLoginState(3);
				} else {
					//mehr length, auswahl
					const adminindex = flavors.map((o) => o.flavor).indexOf("Admin");
					let tempflavors = flavors;
					if (adminindex > -1) {
						//Admin vorhanden, auf oberste position
						tempflavors.unshift(flavors[adminindex]);
						tempflavors.splice(adminindex + 1, 1);
					}
					setFlavorList(tempflavors);
					setLoginState(2);
				}
			} else {
				//!length = leeres array, also entweder kein flavor für die mail hinterlegt, oder mail existiert im system nicht, oder oder oder
				displaySnackbar('Login fehlgeschlagen!', 'error');
			}
		}
	}

	const flavorButton = () => {
		setLoginState(3);
	}

	const setFlavor = (flavorToFind: string) => {
		const index = flavorList.map((f) => f.flavor).indexOf(flavorToFind);
		if (index > -1) {
			setChosenFlavor(flavorList[index]);
			setFlavorChoice(flavorToFind);
		} else {
			displaySnackbar('Fehler in der Verarbeitung des Flavors', 'error');
			console.error("A flavor was selected as loginchoice, but does not exist in the list of choices");
		}
	}

	const getContent = () => {
		if (loginState === 1) {
			return (
				<>
					{/* TODO margin bei Textfeldern verschwindet immer. Nur da, wenn Seite geladen und css import aus und wieder ein kommerntiert wird  */}
					<Card className="loginScreen" variant="elevation">
						<Typography variant="h5">Anmeldung</Typography>
						<div className="loginScreen__form">
							<TextField
								name="email"
								className="loginScreen__textBox"
								id="standard-required"
								label="E-Mail"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<div className="loginScreen__actionbar">
								<Button
									color="secondary"
									variant="text"
									onClick={() => mailButton()}
									disabled={email === ''}
								>
									Anmelden
								</Button>
							</div>
						</div>
					</Card>
				</>);
		} else if (loginState === 2) {
			return (
				<>
					<Card className="flavorChoiceScreen" variant="elevation">
						<Typography variant="h5">Anmeldung</Typography>
						<div className="flavorChoiceScreen__form">
							<FormControl>
								<FormLabel id="radio-buttons-group-label">Bitte Flavor wählen:</FormLabel>
								<RadioGroup
									aria-labelledby="radio-buttons-group-label"
									name="radio-buttons-group"
									onChange={(event) => { setFlavor(event.target.value) }}
								>
									{flavorList && flavorList !== null && flavorList.map(flavor => {
										return (
											<FormControlLabel key={flavor.flavor} value={flavor.flavor} control={<Radio />} label={flavor.kundenname} />
										);
									})}
								</RadioGroup>
							</FormControl>
							<div className="loginScreen__actionbar">
								<Button
									color="secondary"
									variant="text"
									onClick={() => flavorButton()}
									disabled={flavorChoice === ''}
								>
									Weiter
								</Button>
							</div>
						</div>
					</Card>

				</>);
		} else if (loginState === 3) {
			return (
				<>
					{/* TODO margin bei Textfeldern verschwindet immer. Nur da, wenn Seite geladen und css import aus und wieder ein kommerntiert wird  */}
					<Card className="loginScreen" variant="elevation">
						<Typography variant="h5">Anmeldung</Typography>
						<div className="loginScreen__form">
							<TextField
								name="password"
								className="loginScreen__textBox"
								id="standard-password-input"
								label="Passwort"
								value={password}
								type="password"
								onChange={(e) => setPassword(e.target.value)}
							/>
							<div className="loginScreen__actionbar">
								<div className="loginScreen__actionbar">
									<Button
										color="secondary"
										variant="text"
										onClick={() => login(email, password, flavorChoice)}
										disabled={email === '' || password === ''}
									>
										Anmelden
									</Button>
									<Link to="/forgotpassword">Passwort vergessen</Link>
								</div>
							</div>
						</div>
					</Card>
				</>);
		} else {
			return (<div>
				Fehler beim Login
			</div>);
		}
	}

	return (getContent());
};

export default LoginScreen;
