import dateFormat from 'dateformat';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import QRCode from 'qrcode.react';
import OrderDocumentItem from './OrderDocumentItem/OrderDocumentItem';
import './OrderDocument.css';
import { LoginContext } from '../../contexts/LoginContext';
import { POSSyncOrder } from '../../types/order';
import { getProducts } from '../../api/product';
import { getProductName } from '../../util/products';

interface Props {
	order: POSSyncOrder;
}

const OrderDocument = ({ order }: Props) => {
	const { selectedFlavor } = useContext(LoginContext);
	const { data: products } = useQuery('products', () => getProducts(selectedFlavor.flavor));

	if (order == null || products == null) {
		return <div></div>;
	}

	return (
		<div className="orderDocument">
			{/* <button onClick={() => window.print()}>PRINT</button> */}
			<div className="orderDocument__header">
				{/* TODO aus API lesen, wenn verfügbar */}
				<p>copago GmbH &amp; Co. KG </p>
				<p>Centroallee 277</p>
				<p>46047 Oberhausen</p>
				<p>Tel. 0208 77 250-0</p>
				<p>www.copago.de</p>
			</div>
			<div className="orderDocument__dashedLines"></div>
			<div className="orderDocument__dashedLines"></div>
			<div className="orderDocument__orderDetails">
				<table>
					<tbody>
						<tr>
							<td>Abholdatum &nbsp; </td>
							<td>{dateFormat(order.Bestellzeit, 'dd.mm.yyyy')}</td>
						</tr>
						<tr>
							<td>Abholzeit</td>
							<td>{dateFormat(order.Bestellzeit, 'HH:MM')}</td>
						</tr>
						<tr>
							<td>Name</td>
							<td>
								{order.UserNachname}, {order.UserVorname}
							</td>
						</tr>
						<tr>
							<td>Telefon</td>
							<td>{order.UserTelefon}</td>
						</tr>
						<tr>
							<td>Bemerkung</td>
							<td>{order.Bemerkung}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="orderDocument__pickupCode">
				<p>Abholcode: {order.Abholcode != null ? order.Abholcode : '-'}</p>
			</div>
			<div className="orderDocument__qrcode">
				{order.Abholcode != null ? <QRCode value={order.Abholcode} /> : <></>}
			</div>
			<div className="orderDocument__article">
				<table style={{ width: '100%' }}>
					<thead>
						<tr>
							<td align="left">
								Artikelname <br />
								Menge Einheit
							</td>
							<td align="right">
								<br />
								Einzel <br />
								&euro;
							</td>
							<td align="right">
								<br />
								Gesamt <br />
								&euro;
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colSpan={3}>
								<div className="orderDocument__dashedLines"></div>
							</td>
						</tr>

						{order.Positionen.map((article, index) => {
							return (
								<OrderDocumentItem
									article={article}
									key={article.Artikelnummer}
									articleName={getProductName(article.Artikelnummer, products)}
								/>
							);
						})}
						<tr>
							<td colSpan={3}>
								<div className="orderDocument__dashedLines" style={{ width: '100%' }}></div>
							</td>
						</tr>
						<tr>
							<td>Gesamtsumme:</td>
							<td colSpan={2} align="right">
								{order.GesamtBetrag.toFixed(2).toString().replace('.', ',')}
							</td>
						</tr>
						<tr>
							<td colSpan={3} align="right">
								<div className="orderDocument__dashedLines" style={{ width: '3rem' }}></div>
								<div className="orderDocument__dashedLines" style={{ width: '3rem' }}></div>
							</td>
						</tr>
						<tr>
							<td>Zur&uuml;ck:</td>
							<td colSpan={2} align="right">
								0.00
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="orderDocument__footer">
				<div className="orderDocument__dateTime">
					<div className="test">
						<p>Datum</p>
						<p>{dateFormat(order.Bezahlzeit, 'dd.mm.yyyy')}</p>
					</div>
					<div>
						<p>Uhrzeit</p>
						<p>{dateFormat(order.Bezahlzeit, 'HH:MM:ss')}</p>
					</div>
				</div>
				<div className="orderDocument__center">
					<p> Vielen Dank für Ihren Einkauf</p>
					{/* TODO aus der API auslesen, wenn verfügbar */}
					<p>Steuernummer: {'00120301'}</p>
				</div>
				<p></p>
			</div>
		</div>
	);
};

export default OrderDocument;
