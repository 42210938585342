import EditablePaymentProviderSetting from "./PaymentProviderSetting"
import { PaymentSettingEnum } from "../../../api/paymentProviderSettings"; 
export default function EditablePaymentProviderSettingList() 
{
	return (
		<div>
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.IsSandboxPaypal} name={"Soll Paypal in Sandbox Modus sein?"} />
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.AllowPayCartWithPaypal} name={"Können App Benutzer Warenkörbe mit Paypal bezahlen?"} />
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.AllowPayPrepaidWithPaypal} name={"Können App Benutzer Aufladungen mit Paypal machen?"} />
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.NetsEasySandbox} name={"Soll Nets Easy in Sandbox Modus sein?"} />
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.AllowPayCartWithNetsEasy} name={"Können App Benutzer Warenkörbe mit Nets Easy bezahlen?"} />
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.AllowPayPrepaidWithNetsEasy} name={"Können App Benutzer Aufladungen mit NetsEasy machen?s"} />
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.AllowPayCartWithPoints} name={"Können App Benutzer Warenkörbe mit Treuepunkte bezahlen?"} />
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.PayWithCustomerCard} name={"Können App Benutzer Warenkörbe mit die Kundenkarte bezahlen?"} />
            <EditablePaymentProviderSetting setting={PaymentSettingEnum.RechargeCustomerCard} name={"Soll die Aufladung Option beim bezahlen eine Warenkorb angezeigt werden?"} />
        </div>
    );
}
