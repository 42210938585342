import { Color } from '@material-ui/lab/Alert';
import { createContext, FunctionComponent, useState } from 'react';
import { CustomAlertSnackBar } from './CustomAlertSnackBar';

export const SnackBarContext = createContext({} as SnackBarContextType);

export type SnackBarContextType = {
	displaySnackbar: (message: string, severity: Color) => void;
};

type AlertPropTypes = {
	snackBarDuration: number;
	snackBarMessage: string;
	snackBarSeverity: Color;
};

export const SnackBarProvider: FunctionComponent = (props) => {
	const [alert, setAlert] = useState({} as AlertPropTypes);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const displaySnackbar = (message: string, severity: Color) => {
		var duration = 4000;
		if (severity !== 'success') {
			duration = 10000;
		}
		var displayAlert: AlertPropTypes = {
			snackBarDuration: duration,
			snackBarMessage: message,
			snackBarSeverity: severity,
		};
		setSnackbarOpen(true);

		setAlert(displayAlert);
	};
	const setOpen = (open: boolean) => {
		setSnackbarOpen(open);
	};

	const value = { displaySnackbar };

	return (
		<SnackBarContext.Provider value={value}>
			{props.children}
			<CustomAlertSnackBar
				duration={alert.snackBarDuration}
				message={alert.snackBarMessage}
				severity={alert.snackBarSeverity}
				open={snackbarOpen}
				setOpen={setOpen}
			/>
		</SnackBarContext.Provider>
	);
};
