import QRCode from 'qrcode.react';
import React from 'react';
import { DebitCard } from '../../types/customerCard';
import './CustomerCard.css';

interface Props {
	card: DebitCard | undefined;
}

function CustomerCard({ card }: Props) {
	return (
		<div className={`customerCard ${!card ? 'customerCard__blocked' : ''}`}>
			<div className={`customerCard__card ${!card ? 'customerCard__blocked' : ''}`}>
				{!card ? (
					<div className="customerCard__blocked">Kundenkarte gesperrt</div>
				) : (
					<>
						<div className="customerCard__qrcode">
							<QRCode value={card.id} includeMargin className="customerCard__qrcode" />
						</div>
						<div className="customerCard__right">
							<div>
								<div className="customerCard__name">{card.customerName}</div>
								<div>{card.id}</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default CustomerCard;
