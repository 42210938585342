import { Button, Card, CardContent, TextField } from '@material-ui/core';
import React, { ChangeEvent, useContext, useState } from 'react';
import * as service from '../../api/flavor';
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import { Flavor } from '../../types/flavor';
import './CreateFlavor.css';

function CreateFlavor() {
	const { displaySnackbar } = useContext(SnackBarContext);

	const [flavorInput, setFlavorInput] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [primaryColor, setPrimaryColor] = useState<string>('');
	const [secondaryColor, setSecondrayColor] = useState<string>('');
	const [inactiveTintColor, setInactiveTintColor] = useState<string>('');
	const [kundennummer, setKundennummer] = useState<string>('');
	const [emailDomain, setEmailDomain] = useState('');

	const resetForm = () => {
		setFlavorInput('');
		setName('');
		setPrimaryColor('');
		setSecondrayColor('');
		setInactiveTintColor('');
		setKundennummer('');
		setEmailDomain('');
	};

	const createFlavor = async (event: ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();

		const newFlavor: Flavor = {
			flavor: flavorInput,
			name: name,
			primaryColor: primaryColor,
			secondaryColor: secondaryColor,
			inactiveTintColor: inactiveTintColor,
			kundennummer: kundennummer,
			emailDomain: emailDomain,
		};

		try {
			const result: number = await service.createFlavor(newFlavor);
			if (result === 1) {
				resetForm();
				displaySnackbar('Flavor erfolgreich angelegt!', 'success');
				return;
			}
		} catch (error) {
			console.error(error);
		}
		displaySnackbar('Fehler beim erstellen des Flavors!', 'error');
	};

	return (
		<div className="createFlavor">
			<Card className="createFlavor__cardContainer" variant="elevation">
				<CardContent>
					<form className="createFlavor__form" autoComplete="off" onSubmit={createFlavor}>
						<TextField
							name="flavor"
							label="Flavor"
							required
							value={flavorInput}
							onChange={(event: ChangeEvent<{ value: any }>) => setFlavorInput(event.target.value)}
							margin={'dense'}
						/>

						{/* Name */}
						<TextField
							label="Name"
							required
							value={name}
							onChange={(event: ChangeEvent<{ value: any }>) => setName(event.target.value)}
							margin={'dense'}
						/>

						{/* primaryColor */}
						<TextField
							label="Primärfarbe"
							required
							value={primaryColor}
							onChange={(event: ChangeEvent<{ value: any }>) => setPrimaryColor(event.target.value)}
							margin={'dense'}
						/>

						{/* secondaryColor */}
						<TextField
							label="Sekundärfarbe"
							required
							value={secondaryColor}
							onChange={(event: ChangeEvent<{ value: any }>) =>
								setSecondrayColor(event.target.value)
							}
							margin={'dense'}
						/>

						{/* inactiveTintColor */}
						<TextField
							label="Deaktiviert Farbe"
							required
							value={inactiveTintColor}
							onChange={(event: ChangeEvent<{ value: any }>) =>
								setInactiveTintColor(event.target.value)
							}
							margin={'dense'}
						/>

						<TextField
							label="Kundennummer"
							required
							value={kundennummer}
							onChange={(event: ChangeEvent<{ value: any }>) => setKundennummer(event.target.value)}
							margin={'dense'}
						/>

						{/* emailDomain */}
						<TextField
							label="EmailDomain"
							required
							value={emailDomain}
							onChange={(event: ChangeEvent<{ value: any }>) => setEmailDomain(event.target.value)}
							margin={'dense'}
						/>

						{/* Submit Button */}
						<Button type="submit" className="createFlavor__button" color="secondary" variant="text">
							Erstellen
						</Button>
					</form>
				</CardContent>
			</Card>
		</div>
	);
}

export default CreateFlavor;
