import React, { useContext } from 'react';
import * as api from '../../../api/kundenanlage';
import { LoginContext } from '../../../contexts/LoginContext';
import { LoginContextType } from '../../../types/loginContext';
import InfoRow from './InfoRow';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
function SanityCheck() {
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);

	const { flavor } = selectedFlavor;

	return (
		<div>
			<InfoRow
				name="Connection String in Azure hinterlegt"
				onClick={() => api.checkSqlStringExists(flavor)}
			/>
			<InfoRow
				name="Kartennummer lang genug - Tabelle Karten"
				onClick={() => api.checkSqlKarten(flavor)}
			/>
			<InfoRow
				name="Kartennummer lang genug - Tabelle Kartenprepaidumsatz"
				onClick={() => api.checkSqlKartenprepaidumsatz(flavor)}
			/>
			<InfoRow
				name="Kartennummer lang genug - Tabelle Treuehartebuchung"
				onClick={() => api.checkSqlTreuekartebuchung(flavor)}
			/>
			<InfoRow
				name="Kartennummer lang genug - Tabelle Kartengruppe"
				onClick={() => api.checkSqlKartengruppe(flavor)}
			/>
			<InfoRow name="Skriptstand hoch genug" onClick={() => api.checkSkriptstand(flavor)} />
			<InfoRow name="Demo-User anlegen" onClick={() => api.setDemoUser(flavor, true)} />
			<InfoRow name="Office-Admin anlegen" onClick={() => api.setkOfficeAdmin(flavor, true)} />
			<InfoRow
				name="Office-Einstellungen anlegen"
				onClick={() => api.setOfficeApiSettings(flavor)}
			/>
			<InfoRow
				name="Default Werte anlegen"
				onClick={() => {
					if (window.confirm('Möchten Sie wirklich Default Werten anlegen für den Kunde?')) {
							return api.setDefaultApiSettings(flavor)
						}
						else return api.doNothing(); 
						 
				}}
			/>
			<InfoRow name="Kunde Firebase SDK in Vault"
			onClick={()=> api.checkHasFirebase(flavor)}
			/>
			<InfoRow name="Kunde hat Paypal Live Credentials in Vault"
			onClick={()=> api.checkHasPPLive(flavor)}
			/>

<InfoRow name="Kunde hat Paypal Sandbox Credentials in Vault"
			onClick={()=> api.checkHasPPSandbox(flavor)}
			/>

<InfoRow name="Kunde hat Nets Easy Live Credentials in Vault"
			onClick={()=> api.checkHasNELive(flavor)}
			/>

<InfoRow name="Kunde hat Nets Easy Sandbox Credentials in Vault"
			onClick={()=> api.checkHasNESandbox(flavor)}
			/>
		</div>
	);
}

export default SanityCheck;
