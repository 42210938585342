import { Button, TextField } from '@material-ui/core';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import * as api from '../../../api/flavor';
import { LoginContext } from '../../../contexts/LoginContext';
import { Flavor } from '../../../types/flavor';
import { LoginContextType } from '../../../types/loginContext';
import './EditFlavor.css';
import Loadingspinner from '../../LoadingSpinner/Loadingspinner';
import { SnackBarContext } from '../../CustomAlertSnackBar/CustomAlertSnackBarContext';

const EditFlavor = () => {
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);

	const mutation = useMutation((newFlavor: Flavor) => api.editFlavor(newFlavor));

	const { displaySnackbar } = useContext(SnackBarContext);
	const [flavor, setFlavour] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [primaryColor, setPrimaryColor] = useState<string>('');
	const [secondaryColor, setSecondrayColor] = useState<string>('');
	const [inactiveTintColor, setInactiveTintColor] = useState<string>('');
	const [kundennummer, setKundennummer] = useState<string>('');
	const [emailDomain, setEmailDomain] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	useEffect(() => {
		setFlavour(selectedFlavor.flavor);
		setName(selectedFlavor.name);
		setPrimaryColor(selectedFlavor.primaryColor);
		setSecondrayColor(selectedFlavor.secondaryColor);
		setInactiveTintColor(selectedFlavor.inactiveTintColor ?? '');
		setKundennummer(selectedFlavor.kundennummer);
		setEmailDomain(selectedFlavor.emailDomain);
	}, [selectedFlavor]);

	const editFlavor = async (event: ChangeEvent<HTMLFormElement>) => {
		setIsSaving(true);
		event.preventDefault();

		const newFlavor: Flavor = {
			flavor: flavor,
			name: name,
			primaryColor: primaryColor,
			secondaryColor: secondaryColor,
			inactiveTintColor: inactiveTintColor,
			kundennummer: kundennummer,
			emailDomain: emailDomain,
		};

	 mutation.mutate(newFlavor,  {
		onSuccess: () => {
		  // mutation was successful
		  
	 displaySnackbar('Flavour Änderungen erfolgreich gespeichert', 'success');
		},
		onError: () => {
		  // mutation was not successful
		  
	 displaySnackbar('Flavour Änderungen könnten nicht gespeichert werden', 'error');
		}});

	 setIsSaving(false);
	};

	if(isSaving){
<div>
	<label>
<Loadingspinner/>
		Flavour Änderungen werden gespeichert.
	</label>
</div>
	}
	return (
		<form className="kundenanlage__container" autoComplete="off" onSubmit={editFlavor}>
			<TextField name="flavor" label="Flavor" disabled value={flavor} margin={'dense'} />

			{/* Name */}
			<TextField
				label="Name"
				required
				value={name}
				onChange={(event: ChangeEvent<{ value: any }>) => setName(event.target.value)}
				margin={'dense'}
			/>

			{/* primaryColor */}
			<TextField
				label="Primärfarbe"
				value={primaryColor}
				onChange={(event: ChangeEvent<{ value: any }>) => setPrimaryColor(event.target.value)}
				margin={'dense'}
			/>

			{/* secondaryColor */}
			<TextField
				label="Sekundärfarbe"
				value={secondaryColor}
				onChange={(event: ChangeEvent<{ value: any }>) => setSecondrayColor(event.target.value)}
				margin={'dense'}
			/>

			{/* inactiveTintColor */}
			<TextField
				label="Deaktiviert Farbe"
				value={inactiveTintColor}
				onChange={(event: ChangeEvent<{ value: any }>) => setInactiveTintColor(event.target.value)}
				margin={'dense'}
			/>

			<TextField
				label="Kundennummer"
				required
				value={kundennummer}
				onChange={(event: ChangeEvent<{ value: any }>) => setKundennummer(event.target.value)}
				margin={'dense'}
			/>

			{/* emailDomain */}
			<TextField
				label="EmailDomain"
				value={emailDomain}
				onChange={(event: ChangeEvent<{ value: any }>) => setEmailDomain(event.target.value)}
				margin={'dense'}
			/>

			{/* Submit Button */}
			<Button type="submit" className="kundenanlage__button" color="secondary" variant="text">
				Änderungen speichern
			</Button>
		</form>
	);
};

export default EditFlavor;
