import React, { Fragment, useContext, useState } from 'react';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
	useLocation,
	useParams,
} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import { LoginContext } from './contexts/LoginContext';
import AutoLogin from './pages/Autologin/AutoLogin';
import Branches from './pages/Branches/Branches';
import BranchOrders from './pages/BranchOrders/BranchOrders';
import CreateFlavor from './pages/CreateFlavor/CreateFlavor';
import UserRegistration from './pages/CreateUser/UserRegistration';
import EncryptDecrypt from './pages/EncryptDecrypt/EncryptDecrypt';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Home from './pages/Home/Home';
import InvitationRegistration from './pages/InvitationRegistration/InvitationRegistration';
import Kundenanlage from './pages/Kundenanlage/Kundenanlage';
import LoginScreen from './pages/Login/LoginScreen';
import ProductAvailability from './pages/ProductAvailability/ProductAvailability';
import ProductOverview from './pages/ProductOverview/ProductOverview';
import UserEdit from './pages/User/UserDashboard';
import UserManagement from './pages/UserManagement/UserManagement';
import UserTable from './pages/Users/UserTable';
import ParamsManager from './ParamsManager';
import './Routes.css';
import { hasAccess } from './util/authorization';
import TableOrders from './pages/TableOrders/TableOrders';

const Routes = () => {
	const { role }: any = useContext(LoginContext);
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

	return (
		<Router>
			<div style={{ display: 'flex' }}>
				<div
					className="routes__sidebarContainer"
					style={{ width: `${sidebarOpen ? '14rem' : '3.5rem'}` }}
				>
					<Sidebar open={sidebarOpen} changeState={(isOpen: boolean) => setSidebarOpen(isOpen)} />
				</div>
				<div className="routes__container">
					<Navbar />
					<div className="routes__contentContainer">
						{!role ? (
							<Switch>
								<Route path="/login">
									<LoginScreen />
								</Route>
								<Route path="/confirmInvite/:encflavour/:invtoken">
									<ParamsManager>{(params: any) => <InvitationRegistration params={params} />}</ParamsManager>
								</Route>
								<Route path="/forgotpassword">
									<ForgotPassword />
								</Route>
								<Route path="/autologin">
									<AutoLogin />
								</Route>
								<Route path="/" children={<AutologinRedirector />} />
							</Switch>
						) : (
							<Fragment>
								<Switch>
									<Route path="/:flavor/users/:id">
										{!hasAccess(role, 'user') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <UserEdit params={params} />}</ParamsManager>
									</Route>
									<Route path="/:flavor/users">
										{!hasAccess(role, 'users') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <UserTable />}</ParamsManager>
									</Route>
									<Route path="/:flavor/usermanagement">
										{!hasAccess(role, 'users') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <UserManagement />}</ParamsManager>
									</Route>
									<Route path="/:flavor/createUser">
										{!hasAccess(role, 'createUser') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <UserRegistration />}</ParamsManager>
									</Route>
									<Route path="/:flavor/createFlavor">
										{!hasAccess(role, 'createFlavor') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <CreateFlavor />}</ParamsManager>
									</Route>
									{/*}
									<Route path="/:flavor/encryptdecrypt">
										{!hasAccess(role, 'encryptdecrypt') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <EncryptDecrypt />}</ParamsManager>
									</Route>
									{*/}
									<Route path="/:flavor/kundenanlage">
										{!hasAccess(role, 'kundenanlage') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <Kundenanlage />}</ParamsManager>
									</Route>
									<Route path="/:flavor/productoverview">
										{!hasAccess(role, 'productOverview') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <ProductOverview />}</ParamsManager>
									</Route>
									<Route path="/:flavor/branches/:id/orders">
										{!hasAccess(role, 'orders') && <Redirect to="/all/" />}
										<ParamsManager>
											{(params: any) => <BranchOrders params={params} />}
										</ParamsManager>
									</Route>
									<Route path="/:flavor/branches/:id/productavailability">
										{!hasAccess(role, 'productAvailability') && <Redirect to="/all/" />}
										<ParamsManager>
											{(params: any) => <ProductAvailability params={params} />}
										</ParamsManager>
									</Route>
									<Route path="/:flavor/branches">
										{!hasAccess(role, 'branches') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <Branches />}</ParamsManager>
									</Route>
									<Route path="/:flavor/tableorders">
									{!hasAccess(role, 'createFlavor') && <Redirect to="/all/" />}
										<ParamsManager>{(params: any) => <TableOrders />}</ParamsManager>
									</Route>
									<Route path="/:flavor/">
										<ParamsManager>{(params: any) => <Home />}</ParamsManager>
									</Route>
								</Switch>
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</Router>
	);
};

export default Routes;

/**
 * reads the path from url and passes it to the autologin for redirection
 *
 * @returns auto login for automatic login and redirection
 */
const AutologinRedirector = () => {
	const location = useLocation();

	return <AutoLogin location={location} />;
};
