import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Tab,
	Tabs,
	TextField,
} from '@material-ui/core';
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import dateformat from 'dateformat';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import * as api from '../../api/userManagement';
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import * as inviteApi from '../../api/userManagement';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContextType } from '../../types/loginContext';
import { RegistrationUser } from '../../types/user';
import { hasAccess } from '../../util/authorization';
import './UserRegistration.css';

const UserRegistration = () => {
	const { selectedFlavor, role }: LoginContextType = useContext(LoginContext);
	const { displaySnackbar } = useContext(SnackBarContext);

	const [salutation, setSalutation] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [birthday, setBirthday] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [companyName, setCompanyName] = useState<string>('');
	const [tabValue, setTabValue] = React.useState("0");
	const [inviteEmail, setInviteEmail] = useState<string>('');
	const [inviteRole, setInviteRole] = useState<
		'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | 'Customer'
	>('Customer');
	const [canInvite, setCanInvite] = useState<boolean>(false);

	useEffect(() => {
		//console.log("Loading UserReg. User with role " + role + " has access to create manually: " + hasAccess(role, 'createUserManually') + "; selected flavor: " + selectedFlavor.name);
	}, []);

	useEffect(() => {
		setCanInvite(inviteEmail !== '');
	}, [inviteEmail]);

	const resetForm = () => {
		setSalutation('');
		setFirstName('');
		setName('');
		setBirthday('');
		setPhoneNumber('');
		setEmail('');
		setPassword('');
		setCompanyName('');
		setInviteEmail('');
		setInviteRole('Customer');
	};

	const validityCheck = () => {
		const ageCheck = (dateOfBirth: string) => {
			var today = new Date();
			var birthDate = new Date(dateOfBirth);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return (age >= 16);
		};

		var re = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$');
		if (!re.test(password)) {
			displaySnackbar('Das Passwort muss mindestens acht Zeichen lang sein und sowohl Groß- und Kleinbuchstaben wie auch eine Zahl enthalten.', 'info');
			return -1;
		}
		if (birthday === '') {
			displaySnackbar('Bitte Geburtstag angeben.', 'info');
			return -1;
		}
		if (!ageCheck(birthday)) {
			displaySnackbar('Das Mindestalter beträgt 16 Jahre.', 'info');
			return -1;
		}
		return 1;
	}

	const createUser = async (event: ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();

		let entriesValid = validityCheck();
		if (entriesValid === 1) {
			const newUser: RegistrationUser = {
				role: "Customer",
				firstName: firstName,
				name: name,
				phoneNumber: phoneNumber,
				email: email,
				password: password,
				salutation: salutation,
				birthdate: new Date(Date.parse(birthday)).toISOString(),
				acceptedPrivacyStatement: true,
				flavor: selectedFlavor.flavor,
				companyName: companyName
			};

			try {
				const res: number = await api.createUser(newUser);
				if (res === -1) return displaySnackbar('Registrierung fehlgeschlagen!', 'error');
				resetForm();
				displaySnackbar('Registrierung erfolgreich!', 'success');
			} catch (error) {
				displaySnackbar('Registrierung fehlgeschlagen!', 'error');
			}
		}
	};

	const handleInvite = (event: ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (canInvite) {
			invite();
		}
	}

	const invite = async () => {
		if (inviteEmail === '')
			return displaySnackbar('Es müssen alle Felder ausgefüllt werden', 'error');
		let inviteFlavor = selectedFlavor.name;
		//Für Superadmin, MUSS der Flavor "Dev" sein
		if (inviteRole === "SuperAdmin") {
			inviteFlavor = "Dev";
		} else {
			//Ansonsten: Ist der Flavor "alle" ausgewählt (sollte nicht möglich sein, aber just in case), abbrech-condition setzen
			//Ansonsten: Erstellt wird für den gewählten Flavor
			inviteFlavor = (inviteFlavor === "Alle") ? "nope" : selectedFlavor.flavor;
		}
		//Abbrechen wenn Flavor alle gewählt ist
		if (inviteFlavor === "nope") {
			displaySnackbar('Fehler beim Feststellen des Flavors', 'error');
		} else {
			try {
				const res: 1 | -1 = await inviteApi.inviteUser(inviteEmail, inviteRole, inviteFlavor);
				if (res === -1) return displaySnackbar('Fehler beim versenden der Einladung', 'error');
				displaySnackbar('Einladung wurde erfolgreich verschickt.', 'success');
				resetForm();
			} catch (error) {
				console.error(error);
				displaySnackbar('Fehler beim versenden der Einladung', 'error');
			}
		}
	};

	if (selectedFlavor.flavor === 'all') {
		return (
			<div className="userRegistration__noFlavorSelected">
				Bitte das Flavor auswählen f&uuml;r welches der Nutzer erstellt werden soll.
			</div>
		);
	}

	return (
		<div className="userRegistration">
			{hasAccess(role, 'createUserManually') ? (
				<>
					<Paper className="userRegistration__card" elevation={3} style={{ height: '80%', width: '50%' }}>
						<TabContext value={tabValue}>
							<Tabs
								value={tabValue}
								onChange={(event: ChangeEvent<{}>, value: any) => {
									setTabValue(value)
								}
								}
								aria-label="newUserTabs">
								<Tab label="Customer Erstellen" id="createTab" aria-controls="createTab" value="0" />
								<Tab label="Als Rolle Einladen" id="inviteTab" aria-controls="inviteTab" value="1" />
							</Tabs>
							<TabPanel value="0" className="userRegistration__tabPanel">
								<form className="userRegistration__form" autoComplete="off" onSubmit={createUser}>

									{/* Salutation */}
									<FormControl className="userRegistration__textBox">
										<InputLabel id="createUser__inputlabel__salutation">Anrede</InputLabel>
										<Select
											labelId="createUser__inputlabel__salutation"
											required
											value={salutation}
											onChange={(event: ChangeEvent<{ value: any }>) =>
												setSalutation(event.target.value)
											}
										>
											<MenuItem value={'Herr'}>Herr</MenuItem>
											<MenuItem value={'Frau'}>Frau</MenuItem>
											<MenuItem value={'Divers'}>Divers</MenuItem>
											<MenuItem value={'Familie'}>Familie</MenuItem>
											<MenuItem value={'Firma'}>Firma</MenuItem>
										</Select>
									</FormControl>

									{/* FirstName */}
									<TextField
										label="Vorname"
										required
										value={firstName}
										onChange={(event: ChangeEvent<{ value: any }>) => setFirstName(event.target.value)}
										margin={'dense'}
									/>

									{/* Name */}
									<TextField
										label="Name"
										required
										value={name}
										onChange={(event: ChangeEvent<{ value: any }>) => setName(event.target.value)}
										margin={'dense'}
									/>

									{/* CompanyName */}
									<TextField
										label="Firmenname"
										value={companyName}
										onChange={(event: ChangeEvent<{ value: any }>) => setCompanyName(event.target.value)}
										margin={'dense'}
									/>

									{/* Birthday */}
									<TextField
										label="Geburtstag"
										type="date"
										required
										value={dateformat(Date.parse(birthday), 'yyyy-mm-dd')}
										onChange={(event: ChangeEvent<{ value: any }>) => setBirthday(event.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
										margin={'dense'}
									/>

									{/* PhoneNumber */}
									<TextField
										label="Telefon"
										required
										value={phoneNumber}
										onChange={(event: ChangeEvent<{ value: any }>) =>
											setPhoneNumber(event.target.value)
										}
										margin={'dense'}
									/>

									{/* Email */}
									<TextField
										label="E-Mail"
										required
										value={email}
										onChange={(event: ChangeEvent<{ value: any }>) => setEmail(event.target.value)}
										margin={'dense'}
									/>

									{/* Password */}
									<TextField
										label="Passwort"
										type="password"
										required
										value={password}
										onChange={(event: ChangeEvent<{ value: any }>) => setPassword(event.target.value)}
										margin={'dense'}
									/>

									{/* Submit Button */}
									<Button
										type="submit"
										className="userRegistration__button"
										color="secondary"
										variant="text"
									>
										Erstellen
									</Button>
								</form>
							</TabPanel>
							<TabPanel value="1" className="userRegistration__tabPanel">
								<form className="userRegistration__form" autoComplete="off" onSubmit={handleInvite}>
									<InputLabel id="createUser__inputlabel__salutation">Rolle</InputLabel>
									<Select
										labelId="createUser__inputlabel__salutation"
										required
										fullWidth
										value={inviteRole}
										onChange={(event: ChangeEvent<{ value: any }>) =>
											setInviteRole(event.target.value)
										}
									>
										<MenuItem value={'Customer'}>Customer</MenuItem>
										<MenuItem value={'FlavorUser'}>FlavorUser</MenuItem>
										{hasAccess(role, 'creatFlavorAdminUser') ? (
											<MenuItem value={'FlavorAdmin'}>FlavorAdmin</MenuItem>
										) : null}
										{hasAccess(role, 'creatSupportUser') ? (
											<MenuItem value={'Support'}>Support</MenuItem>
										) : null}
										{hasAccess(role, 'creatSuperAdminUser') ? (
											<MenuItem value={'SuperAdmin'}>SuperAdmin</MenuItem>
										) : null}
									</Select>
									<TextField
										autoFocus
										margin='dense'
										id='beispieltextfeld'
										label="Emailadresse"
										type="email"
										fullWidth
										variant="standard"
										value={inviteEmail}
										onChange={(event: ChangeEvent<{ value: any }>) => setInviteEmail(event.target.value)}
									/>
									{/* Submit Button */}
									<Button
										type="submit"
										className="userRegistration__button"
										color="secondary"
										variant="text"
										disabled={!canInvite}
									>
										Nutzer Einladen
									</Button>

								</form>
							</TabPanel>
						</TabContext>
					</Paper>
				</>
			) : null}
		</div>
	);
};

export default UserRegistration;
