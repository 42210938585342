import { Button, Card, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as api from '../../api/userAccount';
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import './ForgotPassword.css';

const ForgotPassword = () => {
	const { displaySnackbar } = useContext(SnackBarContext);
	const [email, setEmail] = useState<string>('');
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const history = useHistory();

	useEffect(() => {
		if (email === '') return setButtonDisabled(true);
		setButtonDisabled(false);
	}, [email]);

	const resetPassword = async () => {
		setButtonDisabled(true);
		if (email === '') return;
		try {
			const res: number = await api.resetPassword(email, 'Admin');
			if (res === 1) {
				displaySnackbar('Email zum Zurücksetzen der Email wurde versendet', 'success');
				return history.push('/login');
			}
			displaySnackbar('Zurücksetzen des Passworts fehlgeschlagen!', 'error');
		} catch (error) {
			console.error(error);
			displaySnackbar('Zurücksetzen des Passworts fehlgeschlagen!', 'error');
		}
	};

	return (
		<div>
			{/* // TODO margin bei Textfeldern verschwindet immer. Nur da, wenn Seite geladen und css import aus und wieder ein kommerntiert wird */}
			<Card className="forgotPassword" variant="elevation">
				<Typography variant="h5">Passwort zurücksetzen</Typography>
				<div className="forgotPassword__form">
					<TextField
						name="email"
						className="forgotPassword__textBox"
						id="standard-required"
						label="E-Mail"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className="forgotPassword__actionbar">
					<Button
						color="secondary"
						variant="text"
						onClick={resetPassword}
						disabled={buttonDisabled}
					>
						Passwort zurücksetzen
					</Button>
					<Link to="/login">Zur Anmeldung</Link>
				</div>
			</Card>
		</div>
	);
};

export default ForgotPassword;
