import {
	Button,
	Card,
	CardContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import dateformat from 'dateformat';
import React, { ChangeEvent, useContext, useState } from 'react';
import * as api from '../../api/userAccount';
import { EditableUser, User } from '../../types/user';
import { SnackBarContext } from '../CustomAlertSnackBar/CustomAlertSnackBarContext';
import './UserEditForm.css';

interface Props {
	user: User;
	setEditMode: Function;
}

function UserEditForm({ user, setEditMode }: Props) {
	const { displaySnackbar } = useContext(SnackBarContext);
	const [salutation, setSalutation] = useState<string>(user.salutation);
	const [firstName, setFirstName] = useState<string>(user.vorname);
	const [name, setName] = useState<string>(user.name);
	const [birthdate, setBirthdate] = useState<string>(user.birthdate);
	const [phoneNumber, setPhoneNumber] = useState<string>(user.phonenumber);
	const [companyName, setCompanyName] = useState<string>(user.phonenumber);

	const cancel = () => {
		displaySnackbar('Keine Änderungen gespeichert!', 'info');
		setEditMode(false);
	};

	const saveChanges = async () => {
		try {
			const changedUser: EditableUser = {
				name: name,
				firstName: firstName,
				phoneNumber: phoneNumber,
				birthdate: birthdate,
				salutation: salutation,
				companyName: companyName
			};
			const data: number = await api.editUser(user.id, changedUser, user.flavor);
			if (data === 1) {
				displaySnackbar('Änderungen wurden gespeichert.', 'success');
				setEditMode(false);
			} else {
				displaySnackbar('Änderungen konnte nichtgespeichert werden!', 'error');
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Card className="userEditForm__container" variant="elevation">
			<CardContent>
				<form className="userEditForm__form" autoComplete="off">
					<FormControl>
						<InputLabel id="createUser__inputlabel__salutation">Anrede</InputLabel>
						<Select
							labelId="createUser__inputlabel__salutation"
							required
							value={salutation}
							onChange={(event: ChangeEvent<{ value: any }>) => setSalutation(event.target.value)}
						>
							<MenuItem value={'Herr'}>Herr</MenuItem>
							<MenuItem value={'Frau'}>Frau</MenuItem>
								<MenuItem value={'Divers'}>Divers</MenuItem>
								<MenuItem value={'Familie'}>Familie</MenuItem>
								<MenuItem value={'Firma'}>Firma</MenuItem>
						</Select>
					</FormControl>

					<TextField
						label="Vorname"
						required
						value={firstName}
						onChange={(event: ChangeEvent<{ value: any }>) => setFirstName(event.target.value)}
						margin={'dense'}
					/>

					<TextField
						label="Name"
						required
						value={name}
						onChange={(event: ChangeEvent<{ value: any }>) => setName(event.target.value)}
						margin={'dense'}
					/>

					<TextField
						label="Firmenname"
						value={companyName}
						onChange={(event: ChangeEvent<{ value: any }>) => setCompanyName(event.target.value)}
						margin={'dense'}
					/>

					<TextField
						label="Telefon"
						required
						value={phoneNumber}
						onChange={(event: ChangeEvent<{ value: any }>) => setPhoneNumber(event.target.value)}
						margin={'dense'}
					/>

					<TextField
						label="Geburtstag"
						type="date"
						required
						value={dateformat(Date.parse(birthdate), 'yyyy-mm-dd')}
						onChange={(event: ChangeEvent<{ value: any }>) => setBirthdate(event.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						margin={'dense'}
					/>
					<div className="userEditForm__buttonContainer">
						<Button
							onClick={cancel}
							className="userEditForm__button"
							color="default"
							variant="text"
						>
							Abbrechen
						</Button>
						<Button
							onClick={saveChanges}
							className="userEditForm__button"
							color="primary"
							variant="text"
						>
							Speichern
						</Button>
					</div>
				</form>
			</CardContent>
		</Card>
	);
}

export default UserEditForm;
