import { Button , Checkbox} from '@material-ui/core';
import { useContext, useState, useEffect } from 'react';
import {
	PaymentSettingEnum,
	PaymentSettingGetResponse,
	doApiRequestGET,
	doApiRequestPatch,
	PaymentSettingPatchResponse,
} from '../../../api/paymentProviderSettings';
import { LoginContext } from '../../../contexts/LoginContext';
import { LoginContextType } from '../../../types/loginContext';
import { SnackBarContext } from '../../CustomAlertSnackBar/CustomAlertSnackBarContext';
import Loadingspinner from '../../LoadingSpinner/Loadingspinner';
interface Props {
	name: string;
	setting: PaymentSettingEnum;
}

const EditablePaymentProviderSetting = ({ name, setting }: Props) => {
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);

	async function getSetting(
		setting: PaymentSettingEnum,
		flavour: string,
	): Promise<PaymentSettingGetResponse> {
		return doApiRequestGET(setting, flavour);
	}

	async function setSetting(
		setting: PaymentSettingEnum,
		flavour: string,
		checked: boolean,
	): Promise<PaymentSettingPatchResponse> {
		return doApiRequestPatch(setting, flavour, checked);
	}
const [isLoading, setIsLoading] = useState<boolean>(false);
const [isSaving, setIsSaving] = useState<boolean>(false);
	const [checked, setChecked] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		getSetting(setting, selectedFlavor.flavor)
			.then((value) => {
				if (value.value === null) displaySnackbar(value.msg, 'error');
				setChecked(value.value ?? false);
			})
			.catch((error) => console.error('Error setting checked state:', error));
		setIsLoading(false);
	}, [setting, selectedFlavor.flavor]);

	const { displaySnackbar } = useContext(SnackBarContext);

	const handleChecked = (e: any) => {
		setChecked(e.target.checked);
	};
if(isSaving || isLoading){
	return (

		<div className="paymentProviderDiv">
			<label className='paymentProvider__label'> 
			{name}
		<Loadingspinner />
		
		</label>
		</div>
	)
}

	return (
		<div className="paymentProviderDiv">
			<label>
			<Checkbox  checked={checked} onChange={handleChecked} />
			{name}
			</label>
			{/* Submit Button */}
			<div>
			<Button
				onClick={async () => {
					setIsSaving(true);
					const res = await setSetting(setting, selectedFlavor.flavor, checked);
					if (!res.success) {
						displaySnackbar(res.msg, 'error');
					}else{
						displaySnackbar("Einstellung erfolgreich geändert.", 'success')
					}
					setIsSaving(false);
				}}
				className="paymentProvider__button"
				color="secondary"
				variant="text"
			>
				Einstellungen speichern
			</Button>
			</div>
		
		</div>
	);
};

export default EditablePaymentProviderSetting;
