import axios from 'axios';
import { refreshAccessToken } from '../api/auth';
import { getAccessToken } from "../service/Token";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL ,
	withCredentials: true,
});

export const axiosRefreshInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL ,
	withCredentials: true,
});

axiosInstance.interceptors.request.use(async (config) => {
	const baseURL = sessionStorage.getItem('alternativeAPIRoute');
	if (baseURL != null) {
		config.baseURL = baseURL;
	} else {
		config.baseURL = process.env.REACT_APP_API_URL;
	}
	const token = getAccessToken();
	if (token && token !== undefined) {
		let exp: number = 0;
		try {
			const tmp: number | undefined = token.expiration;
			if (tmp != null) exp = tmp;
		} catch (error) {
			console.error('invalid jwt');
		}
		if (exp * 1000 > Date.now()) {
			config.headers.Authorization = `Bearer ${token.token}`;
		} else {
			await refreshAccessToken();
			const newToken = getAccessToken();
			config.headers.Authorization = `Bearer ${newToken?.token}`;
		}
	}
	return config;
});