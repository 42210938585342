import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import './FullSync.css';
import { forceFullSync } from '../../../api/kundenanlage';
import { LoginContext } from '../../../contexts/LoginContext';
import { SnackBarContext } from '../../CustomAlertSnackBar/CustomAlertSnackBarContext';
import Loadingspinner from '../../LoadingSpinner/Loadingspinner';


const FullSync = () => {
	const { selectedFlavor } = useContext(LoginContext);
	const { displaySnackbar } = useContext(SnackBarContext);

	const [syncyng, setSyncyng] = useState(false);
	const fullSync = async () => {
		try {
			setSyncyng(true);
			const res: number = await forceFullSync(selectedFlavor.flavor);
			if (res === -1) return displaySnackbar('Fehler beim erzwingen des FullSync', 'error');
			displaySnackbar('FullSync erfolgreich durchgeführt', 'success');
			setSyncyng(false);
		} catch (error) {
			console.error(error);
		}
	};
	if(syncyng){
		return (
			<div className="fullSync">
				<label className="fullSync__label"> 
					<Loadingspinner/>
					FullSync wird asgeführt.
					</label>
		</div>
	);};

	return (
		<div className="fullSync">
			
			{/* Submit Button */}
		
			<Button
				className="fullSync__button"
				color="secondary"
				onClick={fullSync}
			>
				
				
				FullSync ausführen
			</Button>
			
		</div>
	);
};
 

export default FullSync;
