import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getProducts } from '../../../api/product';
import { LoginContext } from '../../../contexts/LoginContext';
import { POSSyncOrderPosition } from '../../../types/product';
import { getProductName } from '../../../util/products';

interface Props {
	article: POSSyncOrderPosition;
	articleName: any;
}

const OrderDocumentItem = ({ article, articleName }: Props) => {
	const { selectedFlavor } = useContext(LoginContext);
	const { data: products } = useQuery('products', () => getProducts(selectedFlavor.flavor));

	return (
		<>
			<tr>
				<td align="left" valign="bottom">
					{articleName} <br />
					{article?.Menge} St&uuml;ck &nbsp; &#10005;
				</td>
				<td align="right" valign="bottom">
					<br />
					{article.Einzelpreis.toFixed(2).toString().replace('.', ',')}
				</td>
				<td align="right" valign="bottom">
					<br />
					{article.Gesamtpreis.toFixed(2).toString().replace('.', ',')}
				</td>
			</tr>
			<tr>
				<td>
					{products &&
						article.Unterpositionen?.map((unterPosition: POSSyncOrderPosition) => {
							return (
								<div key={unterPosition.Artikelnummer}>
									<p style={{ fontSize: '14px', paddingLeft: '0.5rem', marginBottom: '' }}>
										- {getProductName(unterPosition.Artikelnummer, products)} <br />
									</p>
								</div>
							);
						})}
				</td>
			</tr>
		</>
	);
};

export default OrderDocumentItem;
