import { Tooltip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import iconBranch from '../../assets/icons/branch.svg';
import iconTableOrder from '../../assets/icons/tableorder.svg';
import iconCreateFlavor from '../../assets/icons/createFlavor.svg';
import iconCreateUser from '../../assets/icons/createUser.svg';
import iconEncrypt from '../../assets/icons/encryption.svg';
import iconHome from '../../assets/icons/home.svg';
import iconKundenanlage from '../../assets/icons/kundenanlage.svg';
import iconLogout from '../../assets/icons/logout.svg';
import iconProducts from '../../assets/icons/products.svg';
import iconUsers from '../../assets/icons/users.svg';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContextType } from '../../types/loginContext';
import { hasAccess } from '../../util/authorization';
import './Sidebar.css';

interface Props {
	open: boolean;
	changeState: Function;
}

const Sidebar = ({ open, changeState }: Props) => {
	const history = useHistory();
	const location = useLocation();
	const { logout, role, selectedFlavor }: LoginContextType = useContext(LoginContext);

	const [currentPage, setCurrentPage] = useState<string>('home');
	const [newPage, setNewPage] = useState<string>('home');
	const [isRendered, setIsRendered] = useState<boolean>(false);

	const ID = [
		'home',
		'users',
		'createuser',
		'productoverview',
		'branches',
		'tableorders',
		'createflavor',
		'kundenanlage',
		'encryptdecrypt',
		'usermanagement'
	];

	useEffect(() => {
		const page = location.pathname.split('/')[1];
		if (page === '' || page === 'autologin') return setNewPage('home');
		setNewPage(page);
	}, []);

	useEffect(() => {
		if (!ID.find((id) => id === newPage)) {
			document.getElementById(currentPage)?.classList.remove('sidebar__selected');
			document.getElementById(ID[0])?.classList.add('sidebar__selected');
			return setCurrentPage(ID[0]);
		}
		document.getElementById(currentPage)?.classList.remove('sidebar__selected');
		document.getElementById(newPage)?.classList.add('sidebar__selected');
		setCurrentPage(newPage);
	}, [newPage, isRendered]);

	useEffect(() => {
		const page = location.pathname.split('/')[2];
		if (page === '' || page === 'autologin') return setNewPage('home');
		setNewPage(page);
	}, [location]);

	/** delete role and access token and set local storage userLoggedOut auf true to prevent auto login */
	const logUserOut = async () => {
		logout();
		history.push('');
	};

	/** navigates to the given path and adds selected flavor */
	const navigate = (path: string) => {
		history.push(`/${selectedFlavor.flavor}/${path}`);
	};

	return (
		<div
			className="sidebar"
			style={{ width: `${open ? '14rem' : '3.5rem'}`, transition: '.5s ease' }}
		>
			<div className="sidebar__stateChangeButton">
				<div className={`linecontainer ${open ? 'open' : ''}`} onClick={() => changeState(!open)}>
					<div className="line" id="top" />
					<div className="line" id="middle" />
					<div className="line" id="bottom" />
				</div>
			</div>
			<div className="sidebar__container">
				{role !== '' ? (
					<>
						{/* Icon container */}
						<div className="sidebar__menuContainer">
							{/* Home */}
							{hasAccess(role, 'home') && (
								<Tooltip title="Home" placement="right">
									<div id={ID[0]} className="sidebar__menuItem" onClick={() => navigate('')}>
										{/* <FiHome className="sidebar__menuIcon" /> */}
										<img src={iconHome} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Home</div>
									</div>
								</Tooltip>
							)}

							{/* Users */}
							{hasAccess(role, 'users') && (
								<Tooltip title="Nutzerübersicht" placement="right">
									<div id={ID[1]} className="sidebar__menuItem" onClick={() => navigate('users')}>
										{/* <FiUsers className="sidebar__menuIcon" /> */}
										<img src={iconUsers} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Nutzerübersicht</div>
									</div>
								</Tooltip>
							)}

							{/* Userregistration */}
							{hasAccess(role, 'createUser') && (
								<Tooltip title="Nutzerregistrierung" placement="right">
									<div
										id={ID[2]}
										className="sidebar__menuItem"
										onClick={() => navigate('createuser')}
									>
										{/* <FiUserPlus className="sidebar__menuIcon" /> */}
										<img src={iconCreateUser} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Nutzerregistrierung</div>
									</div>
								</Tooltip>
							)}

							{/* Products */}
							{hasAccess(role, 'productOverview') && (
								<Tooltip title="Produkte" placement="right">
									<div
										id={ID[3]}
										className="sidebar__menuItem"
										onClick={() => navigate('productoverview')}
									>
										{/* <FiPackage className="sidebar__menuIcon" /> */}
										<img src={iconProducts} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Produkte</div>
									</div>
								</Tooltip>
							)}

							{/* Branches */}
							{hasAccess(role, 'branches') && (
								<Tooltip title="Filialen" placement="right">
									<div
										id={ID[4]}
										className="sidebar__menuItem"
										onClick={() => navigate('branches')}
									>
										{/* <BiStoreAlt className="sidebar__menuIcon" /> */}
										<img src={iconBranch} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Filialen</div>
									</div>
								</Tooltip>
							)}

								{/* TableOrders */}
							{hasAccess(role, 'tableorders') && (
								<Tooltip title="Tischbestellung" placement="right">
									<div
										id={ID[5]}
										className="sidebar__menuItem"
										onClick={() => navigate('tableorders')}
									>
										{/* <BiStoreAlt className="sidebar__menuIcon" /> */}
										<img src={iconTableOrder} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Tischbestellung</div>
									</div>
								</Tooltip>
							)}

							{!isRendered && setIsRendered(true)}

							{/* Create Flavor */}
							{hasAccess(role, 'createFlavor') && (
								<Tooltip title="Kundenerstellung" placement="right">
									<div
										id={ID[6]}
										className="sidebar__menuItem"
										onClick={() => navigate('createflavor')}
									>
										{/* <HiOutlinePencil className="sidebar__menuIcon" /> */}
										<img src={iconCreateFlavor} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Kundenerstellung</div>
									</div>
								</Tooltip>
							)}

							{/* Kundenanlage */}
							{hasAccess(role, 'kundenanlage') && (
								<Tooltip title="Kundeneinstellungen" placement="right">
									<div
										id={ID[7]}
										className="sidebar__menuItem"
										onClick={() => navigate('kundenanlage')}
									>
										{/* <FiCheckSquare className="sidebar__menuIcon" /> */}
										<img src={iconKundenanlage} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Kundeneinstellungen</div>
									</div>
								</Tooltip>
							)}

							{/* Verschlüsselung }
							{hasAccess(role, 'encryptdecrypt') && (
								<Tooltip title="Verschlüsselung" placement="right">
									<div
										id={ID[7]}
										className="sidebar__menuItem"
										onClick={() => navigate('encryptdecrypt')}
									>
										{// <FiLock className="sidebar__menuIcon" /> 
										}
										<img src={iconEncrypt} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Verschlüsselung</div>
									</div>
								</Tooltip>
							)}
							{ Verschlüsselung */}
							{hasAccess(role, 'usermanagement') && (
								<Tooltip title="Nutzerverwaltung" placement="right">
									<div
										id={ID[9]}
										className="sidebar__menuItem"
										onClick={() => navigate('usermanagement')}
									>
										{/* <FiLock className="sidebar__menuIcon" /> */}
										<img src={iconCreateUser} className="sidebar__menuIconImg" alt="icon" />
										<div className="sidebar__menuText">Nutzerverwaltung</div>
									</div>
								</Tooltip>
							)}
							
						</div>
						{/* Bottom Container */}
						<Tooltip title="Abmelden" placement="right">
							<div className="sidebar__menuContainer">
								{/* Logout Button */}
								<div className="sidebar__menuItem" onClick={logUserOut}>
									{/* <FiLogOut className="sidebar__menuIcon" /> */}
									<img src={iconLogout} className="sidebar__menuIconImg" alt="icon" />
									<div className="sidebar__menuText">Abmelden</div>
								</div>
							</div>
						</Tooltip>
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default Sidebar;
