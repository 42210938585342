import { Button } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { handleUserDeletion } from '../../service/user';
import { User } from '../../types/user';
import { SnackBarContext } from '../CustomAlertSnackBar/CustomAlertSnackBarContext';
import UserBalanceChargeDialog from '../UsersPopups/UserBalanceChargeDialog';
import UserSecurityDialog from '../UsersPopups/UserDeleteDialog';
import './UserActionBar.css';
import { Actions } from '../../types/actions';
import { hasAccess } from '../../util/authorization';
import { LoginContext } from '../../contexts/LoginContext';

interface Props {
	user: User;
	actions: Actions;
}

const UserActionBar = ({ user, actions }: Props) => {
	const { role } = useContext(LoginContext);
	const { displaySnackbar } = useContext(SnackBarContext);

	const [openSecurityDialog, setOpenSecurityDialog] = useState<boolean>(false);
	const [openChargeDialog, setOpenChargeDialog] = useState<boolean>(false);

	const [dialogHeading, setDialogHeading] = useState<string>('');
	const [dialogMessage, setDialogMessage] = useState<string>('');
	const [dialogButtonText, setDialogButtonText] = useState<string>('');
	const [dialogOnAccept, setDialogOnAccept] = useState<Function>(() => null);

	return (
		<div className="userActionBar">
			{hasAccess(role, 'userOverview') && (
				<Button onClick={() => actions.refetchAll()}>Aktualisieren</Button>
			)}
			{hasAccess(role, 'resetPassword') && (
				<Button
					onClick={() => {
						setDialogHeading('Email zum Passwort Zurücksetzen senden?');
						setDialogMessage(
							'Dem Nutzer wird eine Email zum zurücksetzen seines Passworts gesendet.',
						);
						setDialogButtonText('Email senden');
						// react useState calls the inline function immediately with previous stat as parameter so here we need to
						// pass a function that returns the function after the initial call.
						// https://reactjs.org/docs/hooks-reference.html#functional-updates
						setDialogOnAccept(() => () => actions.passwordMutation.mutate(user));
						setOpenSecurityDialog(true);
					}}
				>
					Passwort zur&uuml;cksetzen
				</Button>
			)}
			{!user.emailConfirmed ? (
				<>
					{hasAccess(role, 'resendconfirmationmail') && (
						<Button
							onClick={() => {
								setDialogHeading('Email bestätigung erneut senden?');
								setDialogMessage(
									'Dem Nutzer wird dann eine Email zum bestätigen seines Kontos gesendet.',
								);
								setDialogButtonText('Email senden');
								setDialogOnAccept(() => () => actions.confirmationEmailMutation.mutate(user.id));
								setOpenSecurityDialog(true);
							}}
						>
							Emailbest&auml;tigung erneut senden
						</Button>
					)}
					{hasAccess(role, 'manuelEmailConfirmation') && (
						<Button
							onClick={() => {
								setDialogHeading('Email manuell bestätigen?');
								setDialogMessage(
									'Der Nutzer ist dann bestätigt und muss sein Konot nicht selbständig bestätigen.',
								);
								setDialogButtonText('Konto bestätigen');
								setDialogOnAccept(() => () => actions.manuelEmailConfirmationMutation.mutate(user));
								setOpenSecurityDialog(true);
							}}
						>
							Email manuell bestätigen
						</Button>
					)}
				</>
			) : (
				<>
					{hasAccess(role, 'chargeCustomerCard') && (
						<Button onClick={() => setOpenChargeDialog(true)}>Kundenkarte aufladen</Button>
					)}
					{Date.parse(user.lockoutEnd) < Date.now() ? (
						<>
							{hasAccess(role, 'blockCustomerCard') && (
								<Button
									onClick={() => {
										setDialogHeading('Kundenkarte sperren?');
										setDialogMessage(
											'Diese kann dann bis zu einer Entsperrung der Karte nicht genutzt werden.',
										);
										setDialogButtonText('Karte sperren');
										setDialogOnAccept(
											() => () => actions.blockCustomerCardMutation.mutate(user.id),
										);
										setOpenSecurityDialog(true);
									}}
								>
									Karte sperren
								</Button>
							)}
						</>
					) : (
						<>
							{hasAccess(role, 'unblockCustomerCard') && (
								<Button
									onClick={() => {
										setDialogHeading('Kundenkarte freischalten?');
										setDialogMessage('Diese kann vom Nutzer dann wieder genutzt werden.');
										setDialogButtonText('Karte freischalten');
										setDialogOnAccept(
											() => () => actions.unBlockCustomerCardMutation.mutate(user.id),
										);
										setOpenSecurityDialog(true);
									}}
								>
									Karte freischalten
								</Button>
							)}
						</>
					)}
				</>
			)}
			{hasAccess(role, 'deleteUser') && (
				<Button
					onClick={() => {
						setDialogHeading('Nutzer wirklich löschen?');
						setDialogMessage(
							'Dies Änderung ist endgültig und kann NICHT rückgängig gemacht werden!',
						);
						setDialogButtonText('Löschen');
						setDialogOnAccept(() => () => handleUserDeletion(user, displaySnackbar));
						setOpenSecurityDialog(true);
					}}
				>
					Nutzer Löschen
				</Button>
			)}
			<UserSecurityDialog
				user={user}
				heading={dialogHeading}
				message={dialogMessage}
				acceptButtonText={dialogButtonText}
				onAccept={dialogOnAccept}
				openDialog={openSecurityDialog}
				setOpenDialog={setOpenSecurityDialog}
			/>
			<UserBalanceChargeDialog
				user={user}
				openDialog={openChargeDialog}
				setOpenDialog={setOpenChargeDialog}
			/>
		</div>
	);
};

export default UserActionBar;
