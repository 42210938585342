import React from 'react';
import { Product as ProductType } from '../../types/product';
import ImagePlaceholder from '../ImagePlaceholder/ImagePlaceholder';
import './Product.css';

interface Props {
	product: ProductType;
	availabilityBitmask: number;
}

const Product = ({ product, availabilityBitmask }: Props) => {
	// const {
	// 	BlockedByCriticalAmount,
	// 	BlockedByFixedG2gAvailability,
	// 	BlockedByPreorderTime,
	// 	BlockedByMissingPrice,
	// 	BlockedByCalendarAvailability,
	// 	BlockedByManualBranchBlock,
	// 	BlockedByCurrentBranchAvailability,
	// 	BlockedByFixedBranchAvailability,
	// } = getUnavailabilityReasons(availabilityBitmask);

	return (
		<div className="product">
			<div className="product__imgcontainer">
				<ImagePlaceholder>
					<img src={product.icon} alt="Produktbild" />
				</ImagePlaceholder>
			</div>
			<div className="product__namecontainer">
				<div>
					{product.name}, <br />#{product.productID}
					<br />
				</div>
			</div>
		</div>
	);
};

export default Product;
