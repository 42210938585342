import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoginContext } from './contexts/LoginContext';
import { Flavor } from './types/flavor';
import { LoginContextType } from './types/loginContext';

interface Props {
	children: any;
}

const ParamsManager = ({ children }: Props) => {
	const params: any = useParams();
	const { selectedFlavor, setSelectedFlavor, allowedFlavors }: LoginContextType =
		useContext(LoginContext);

	useEffect(() => {
		if (params.flavor != selectedFlavor.flavor) {
			const foundFlavor = allowedFlavors.find((item: Flavor) => item.flavor === params.flavor);
			if (foundFlavor) {
				setSelectedFlavor(foundFlavor);
			} else {
				setSelectedFlavor(allowedFlavors[0]);
			}
		}
	}, [params, allowedFlavors]);

	return <div style={{ height: '100%', boxSizing: 'border-box' }}>{children(params)}</div>;
};

export default ParamsManager;
