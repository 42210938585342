import { AxiosResponse } from 'axios';
import { Flavor, LoginFlavorDTO, LoginOnlyFlavor } from '../types/flavor';
import { axiosInstance } from './axiosConfig';

/**
 * gets all flavors the email is associated with
 *
 * @returns array of flavors on success, otherwise null
 */
export const getLoginFlavourChoice = async (email: string): Promise<null | LoginOnlyFlavor[]> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.get(`v1/Auth/getmultipleuserflavors?usermail=${email}`, {
			headers: { AppFlavour: 'Unknown_Flavor' },
		});
		return res.data.flavors;
	} catch (error) {
		console.error('Flavor Error: ', error);
	}
	return null;
};