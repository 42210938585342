import './FlavourSettings.css';
import {
	SetIOS_AppStore_SettingsAsync,
	SetAndroid_PlayStore_SettingsAsync,
	GetIOS_AppStore_SettingsAsync,
	GetAndroid_PlayStore_SettingsAsync,
	FlavorSettings_IOSAppstore_Request,
	FlavorSettings_AndroidPlaystore_Request
} from '../../../api/flavourSettings';
import { Button, Checkbox, TextField } from '@material-ui/core';
import { LoginContextType } from '../../../types/loginContext';
import { useContext, useState, useEffect, ChangeEvent } from 'react';
import { LoginContext } from '../../../contexts/LoginContext';
import { SnackBarContext } from '../../CustomAlertSnackBar/CustomAlertSnackBarContext';
import Loadingspinner from '../../LoadingSpinner/Loadingspinner';
export default function FlavourSettings() {

	const { selectedFlavor }: LoginContextType = useContext(LoginContext);

	const [ioSAppID, setioSAppID] = useState<string | null>('');
	const [ioSBundleID, setioSBundleID] = useState<string | null>('');
	const [ioSTeamIdentifier, setioSTeamIdentifier] = useState<string | null>('');
	const [androidAppID, setAndroidAppID] = useState<string | null>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const { displaySnackbar } = useContext(SnackBarContext);

	const setFlavourSettings = async ()=> {
		setIsSaving(true); 
		const iosSettings = {
			appID: ioSAppID,
			bundleID: ioSBundleID,
			teamIdentifier: ioSTeamIdentifier,
		};

		const androidSettings = {
			appID: androidAppID,
		};
		var resIOS = await SetIOS_AppStore_SettingsAsync(iosSettings, selectedFlavor.flavor);
        
		var resAndroid = await SetAndroid_PlayStore_SettingsAsync(
			androidSettings,
			selectedFlavor.flavor,
		);

        if(resIOS !==null || resAndroid !== null) displaySnackbar(`${resIOS ?? ''} \n${resAndroid ?? ''}`, 'error');
        else displaySnackbar(`Einstellungen erfolgreich gespeichert.`, 'success');
		setIsSaving(false);
	};

	useEffect(() => {
		setIsLoading(true);
		getSettings();
		setIsLoading(false);

		async function getSettings() {
            var responseIOS = await GetIOS_AppStore_SettingsAsync(selectedFlavor.flavor);

            var responseAndroid = await GetAndroid_PlayStore_SettingsAsync(selectedFlavor.flavor);
            if(responseIOS.errorMsg !==null || responseAndroid.errorMsg !== null )
                displaySnackbar(`${responseIOS.errorMsg ?? ''} \n${responseAndroid.errorMsg ?? ''}`, 'error');
            if(responseIOS.data ) {
               const respIOSData= responseIOS.data as FlavorSettings_IOSAppstore_Request;
                setioSAppID (respIOSData.appID);
                setioSBundleID (respIOSData.bundleID);
                setioSTeamIdentifier (respIOSData.teamIdentifier);
            }
            if(responseAndroid.data ) {
               const respAndroidData= responseAndroid.data as FlavorSettings_AndroidPlaystore_Request;
                setAndroidAppID (respAndroidData.appID); 
            }
        }
	}, [selectedFlavor.flavor]);
	if (isLoading || isSaving) {
		<div>
			<label>
				<Loadingspinner />
				Email Smtp Server Änderungen werden gespeichert.
			</label>
		</div>;
	}
	return (
		<div >
			<div className='flavourSettings__div'>
				<label className='flavourSettings__label' >iOS App Store Einstellungen</label>
				<div className='flavourSettings__div'>
					<TextField
						label="App ID"
						value={ioSAppID}
						onChange={(event: ChangeEvent<{ value: any }>) => setioSAppID(event.target.value)}
						margin={'dense'}
					/>
					<TextField
						label="Bundle ID"
						value={ioSBundleID}
						onChange={(event: ChangeEvent<{ value: any }>) => setioSBundleID(event.target.value)}
						margin={'dense'}
					/>
					<TextField
						label="Team Identifier"
						value={ioSTeamIdentifier}
						onChange={(event: ChangeEvent<{ value: any }>) =>
							setioSTeamIdentifier(event.target.value)
						}
						margin={'dense'}
					/>
				</div>
			</div>
			<div className='flavourSettings__div'>
				<label className='flavourSettings__label'>Android App Store Einstellungen</label>
				<div className='flavourSettings__div'>
					<TextField
						label="App ID"
						value={androidAppID}
						onChange={(event: ChangeEvent<{ value: any }>) => setAndroidAppID(event.target.value)}
						margin={'dense'}
					/>
				</div>
			</div>
             <div className='flavourSettings__div'>
				  <Button onClick={ setFlavourSettings}  className="flavourSettings__button" color="secondary" variant="text">
				 Änderungen speichern
			 </Button>
			 </div>
		</div>
	);
}
