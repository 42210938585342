import { DataGrid, GridColDef } from '@material-ui/data-grid';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getProductAvailability, getProducts } from '../../api/product';
import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import { LoginContext } from '../../contexts/LoginContext';
import { AvailabilityProduct } from '../../types/product';
import { getProductsWithAvailability } from '../../util/products';
import './ProductAvailability.css';
import DateFnsUtils from '@date-io/date-fns';

import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
	params: any;
}

const ProductAvailability = ({ params }: Props) => {
	const { selectedFlavor } = useContext(LoginContext);

	const { data: productData, refetch: refetchProducts } = useQuery(
		'getProducts',
		() => getProducts(selectedFlavor.flavor),
		{
			// staleTime: 1000 * 60 * 60,
		},
	);

	const { data: availabilityData, refetch: refetchAvailability } = useQuery(
		['branchAvailability', params.id],
		() =>
			getProductAvailability(selectedFlavor.flavor, params.id, selectedDate?.toISOString() || ''),
		{
			refetchInterval: 1000 * 5,
		},
	);

	const [products, setProducts] = useState<AvailabilityProduct[]>([]);

	const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>();

	const handleDateChange = (date: MaterialUiPickersDate) => {
		setSelectedDate(date);
	};

	useEffect(() => {
		refetchProducts();
		refetchAvailability();
	}, []);

	useEffect(() => {
		setProducts([]);
	}, [selectedFlavor]);

	useEffect(() => {
		refetchAvailability();
	}, [refetchAvailability, selectedDate]);

	useEffect(() => {
		if (!productData || productData.length < 1) return;
		if (!availabilityData || availabilityData.length < 1) return;
		setProducts(getProductsWithAvailability(productData, availabilityData));
	}, [productData, availabilityData]);

	useEffect(() => {
		//console.log('products: ', products);
	}, [products]);

	const columns: GridColDef[] = [
		{ field: 'productID', headerName: 'Produktnummer', width: 180 },
		{ field: 'name', headerName: 'Name', width: 190 },
		{ field: 'subtitle', headerName: 'Subtitel', width: 190 },
		{
			field: 'availability',
			headerName: 'Verfügbar',
			width: 140,
			renderCell: (params) => {
				return params.row.availability.find((element: any) => element[1]) ? 'Nein' : 'Ja';
			},
		},
		{
			field: '',
			headerName: 'Grund',
			flex: 1,
			renderCell: (params) => {
				const reasons: string[] = [];
				params.row.availability.forEach((element: any) => {
					if (element[1]) {
						reasons.push(element[0]);
					}
				});
				return reasons.map((reason, index) => {
					if (index != 0) return ', ' + reason;
					else return reason;
				});
			},
		},
	];

	if (!products || products.length < 1) {
		return <Loadingspinner />;
	}

	return (
		<div style={{ height: '100%' }}>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="dd.MM.yyyy"
					margin="normal"
					label="Datum"
					style={{ marginRight: '1rem', marginLeft: '0.2rem' }}
					value={selectedDate}
					onChange={handleDateChange}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
				/>

				<KeyboardTimePicker
					margin="normal"
					label="Uhrzeit"
					value={selectedDate}
					onChange={handleDateChange}
					KeyboardButtonProps={{
						'aria-label': 'change time',
					}}
				/>
			</MuiPickersUtilsProvider>
			<DataGrid getRowId={(row) => row.productID} rows={products} columns={columns} />
		</div>
	);
};

export default ProductAvailability;
