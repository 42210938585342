import { AxiosResponse } from 'axios';
import { axiosInstance } from './axiosConfig';


export interface FlavorSettings_IOSAppstore_Request{
	appID :string|null,
	bundleID : string | null,
    teamIdentifier : string | null
}

export interface FlavorSettings_AndroidPlaystore_Request{
	appID :string|null
}

 

export interface FlavorSettings_Response{
	data :FlavorSettings_IOSAppstore_Request|null|FlavorSettings_AndroidPlaystore_Request,
    errorMsg :string| null
}

 /**
 * set FlavourSettings IOS_APPStore
 *
 * @param FlavorSettings_IOSAppstore_Request which setting to set
 * @param flavor AppFlavor
 */
export const SetIOS_AppStore_SettingsAsync = async (
	flavorSettings: FlavorSettings_IOSAppstore_Request,
	flavor: string,
): Promise<string|null> => {
	try {
		const res: AxiosResponse<string | null> = await axiosInstance.post(
			`v1/SuperAdmin/FlavorSettings/iosstore?targetFlavor=${flavor}`,
			flavorSettings
		);
	return null;
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return   error.response.data.detail;
		}
		return 'no valid server response';
	}
};



 /**
 * set FlavorSettings AndroidPlaystore_Request
 *
 * @param FlavorSettings_AndroidPlaystore_Request which setting to set
 * @param flavor AppFlavor
 */
 export const SetAndroid_PlayStore_SettingsAsync = async (
	flavorSettings: FlavorSettings_AndroidPlaystore_Request,
	flavor: string,
): Promise<string|null> => {
	try {
		const res: AxiosResponse<string | null> = await axiosInstance.post(
			`v1/SuperAdmin/FlavorSettings/androidplaystore?targetFlavor=${flavor}`,
			flavorSettings
		);
	return null;
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return   error.response.data.detail;
		}
		return   'no valid server response';
	}
};



 /**
 * get FlavourSettings IOS_APPStore
 * 
 * @param flavor AppFlavor
 */
 export const GetIOS_AppStore_SettingsAsync = async ( 
	flavor: string,
): Promise<FlavorSettings_Response> => {
	try {
		const res: AxiosResponse<FlavorSettings_IOSAppstore_Request| null> = await axiosInstance.get(
			`v1/SuperAdmin/FlavorSettings/iosstore?targetFlavor=${flavor}`			
		); 
	return {data:  res.data, errorMsg: null};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return  {data:null, errorMsg :error.response.data.detail};
		}
        
			return  {data:null, errorMsg:'no valid server response'}; 
	}
};



 /**
 * get FlavorSettings AndroidPlaystore_Request
 * 
 * @param flavor AppFlavor
 */
 export const GetAndroid_PlayStore_SettingsAsync = async ( 
	flavor: string,
): Promise<FlavorSettings_Response> => {
	try {
		const res:  AxiosResponse<FlavorSettings_AndroidPlaystore_Request| null> = await axiosInstance(
			`v1/SuperAdmin/FlavorSettings/androidplaystore?targetFlavor=${flavor}`
		);
        return {data:  res.data, errorMsg: null};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return  {data:null, errorMsg :error.response.data.detail};
		}
        
			return  {data:null, errorMsg:'no valid server response'}; 
	}
};
