import { TextField, Button } from '@material-ui/core';
import { ChangeEvent } from 'react';
import {
	G2GSetting,
	G2GSettingGetResponse,
	PatchResponse,
	doApiRequestGET,
	doApiSetEmailServer,
	doApiRequestUpdateDataSet,
	SyncType,
} from '../../../api/g2gSettings';
import { LoginContextType } from '../../../types/loginContext';
import { LoginContext } from '../../../contexts/LoginContext';
import { useContext, useState, useEffect } from 'react';
import { SnackBarContext } from '../../CustomAlertSnackBar/CustomAlertSnackBarContext';
import Loadingspinner from '../../LoadingSpinner/Loadingspinner';
import './EmailServerSMTPSettings.css'

export default function EmailServerSMTPSetiings() {
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);

	const { displaySnackbar } = useContext(SnackBarContext);

	const [smtpServer, setSmtpServer] = useState<string>('');
	const [smtpPort, setSmtpPort] = useState<number>(587);
	const [smtpAccountAdress, setSmtpAccountAdress] = useState<string>('');
	const [smtpPassword, setSmtpPassword] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const setEmailServer = async (event: ChangeEvent<HTMLFormElement>) => {
 
		setIsSaving(true);
		event.preventDefault();

		var res = await doApiSetEmailServer(
			selectedFlavor.flavor,
			smtpServer,
			smtpPort,
			smtpAccountAdress,
			smtpPassword,
		);
		if (!res.success)
			displaySnackbar('Die E-Mail-Einstellungen könnten nicht festgelegt werden.', 'error');
		else {
			displaySnackbar(
				'Die E-Mail-Einstellungen wurden erfolgreich festgelegt.',
				'success',
			);
		}
	
		setIsSaving(false);
	};

	useEffect(() => {
		setIsLoading(true);
		getAndSetAllEmailServerSettings_FromApi();
		setIsLoading(false);

		function getAndSetAllEmailServerSettings_FromApi() {
	
			doApiRequestGET(G2GSetting.EmailSmtpServer, selectedFlavor.flavor)
				.then((value) => {
					if (value.value === null ){
						displaySnackbar(`Einstellung SMTP-Server: ${value.msg}`, 'error');
				
					}else{
					if( typeof value.value === 'string')
					setSmtpServer( value.value);
				}})
				.catch((error) => console.error('Fehler beim Festlegen des SMTP-Serverwerts:', error));

			doApiRequestGET(G2GSetting.EmailSmtpServerPort, selectedFlavor.flavor)
				.then((value) => {
					if (value.value === null|| value.value===0){ displaySnackbar(`Einstellung SMTP-Port: ${value.msg}`, 'error'); }else{
					if(typeof value.value === 'number')
					setSmtpPort(  value.value );}
				})
				.catch((error) => console.error('Fehler beim Einstellen des SMTP-Port-Wertes:', error));

			doApiRequestGET(G2GSetting.EmailAccountAdresse, selectedFlavor.flavor)
				.then((value) => {
					if (value.value === null){
						displaySnackbar(`Einstellung SMTP-Konto-Adresse: ${value.msg}`, 'error');
					
						} 
					else{
						if(typeof value.value === 'string')
							setSmtpAccountAdress( value.value);
						}
					
				})				
				.catch((error) =>
					console.error('Fehler beim Einstellen des SMTP-Konto-Adresse-Wertes:', error),
				);

			doApiRequestGET(G2GSetting.EmailAccountPasswort, selectedFlavor.flavor)
				.then((value) => {
					if (value.value === null){
						displaySnackbar(`Einstellung SMTP-Passwort: ${value.msg}`, 'error');
						}else{
						if(typeof value.value === 'string')
					setSmtpPassword( value.value);}
				})
				.catch((error) => console.error('Fehler beim Einstellen des SMTP-Passwort-Wertes:', error));
		}
	}, [selectedFlavor.flavor]);
if(isLoading || isSaving){
    <div>
	<label>
<Loadingspinner/>
		Email Smtp Server Änderungen werden gespeichert.
	</label>
</div>
}
    return (
		<form className="emailserversmtp__container" autoComplete="off"  onSubmit={setEmailServer}> 
 
			<TextField
				label="Smtp Server "
				required
				inputProps={{
					autoComplete: 'new-password',
					form: {
						autoComplete: 'off',
					},
				  }}
				value={smtpServer}
				onChange={(event: ChangeEvent<{ value: any }>) => setSmtpServer(event.target.value)}
				margin={'dense'}
			/>
 
			<TextField
				label="Smtp Port "
                required
				inputProps={{
					autoComplete: 'new-password',
					form: {
						autoComplete: 'off',
					},
				  }}
				value={smtpPort}
				onChange={(event: ChangeEvent<{ value: any }>) => setSmtpPort(event.target.value)}
				margin={'dense'}
			/>
 
			<TextField
				label="Konto-Adresse "
				value={smtpAccountAdress}
                required
				inputProps={{
					autoComplete: 'new-password',
					form: {
						autoComplete: 'off',
					},
				  }}
                onChange={(event: ChangeEvent<{ value: any }>) => setSmtpAccountAdress(event.target.value)}
				margin={'dense'}
			/>
 
         
			<TextField
				label="Smtp Password "
				value={smtpPassword}
                required  
				inputProps={{
					autoComplete: 'new-password',
					form: {
						autoComplete: 'off',
					},
				  }}
                type="password"
				onChange={(event: ChangeEvent<{ value: any }>) => setSmtpPassword(event.target.value)}
				margin={'dense'}
			/>

			{/* Submit Button */}
			{
				smtpServer && smtpServer.length !== 0 &&
					smtpAccountAdress && smtpAccountAdress.length !== 0 &&
					smtpPassword && smtpPassword.length !== 0   &&
				 
				  <Button type="submit"  className="emailserversmtp__button" color="secondary" variant="text">
				 Änderungen speichern
			 </Button>
				 }
				
		</form>
	);
}
