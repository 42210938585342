import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';
import React, { useContext } from 'react';
import * as logic from '../../service/user';
import { User } from '../../types/user';
import { SnackBarContext } from '../CustomAlertSnackBar/CustomAlertSnackBarContext';

interface Props {
	user: User;
	heading: string;
	message: string;
	acceptButtonText: string;
	onAccept: Function;
	openDialog: boolean;
	setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserSecurityDialog = ({
	user,
	heading,
	message,
	acceptButtonText,
	onAccept,
	openDialog,
	setOpenDialog,
}: Props) => {
	return (
		<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
			<DialogTitle>{heading}</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onAccept();
						setOpenDialog(false);
					}}
					color="primary"
				>
					{acceptButtonText}
				</Button>
				<Button onClick={() => setOpenDialog(false)} color="default" autoFocus>
					Abbrechen
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UserSecurityDialog;
