/**
 * convert a number to unavailability reasons
 *
 * @param bitmask
 * @returns reasons for unavailability set to true or false
 */
export const getUnavailabilityReasons = (bitmask: number) => {
	// prettier-ignore
	return {
		'Kritische Menge': bitmask & 128 ? true : false, 					// BlockedByCriticalAmount
		'Kein get2go Artikel': bitmask & 64 ? true : false, 				// BlockedByFixedG2gAvailability
		'Vorbestellzeit unterschriten': bitmask & 32 ? true : false,		// BlockedByPreorderTime
		'Keinen Preis gefunden': bitmask & 16 ? true : false, 				// BlockedByMissingPrice
		'Nicht im Artikelkalender verfügbar': bitmask & 8 ? true : false, 	// BlockedByCalendarAvailability
		'In Filiale ausverkauft': bitmask & 4 ? true : false, 				// BlockedByManualBranchBlock
		'Bestandsführung leer': bitmask & 2 ? true : false, 				// BlockedByCurrentBranchAvailability
		'Artikel in Filiale nicht verfügbar': bitmask & 1 ? true : false, 	// BlockedByFixedBranchAvailability
	};
};
