import { Card, CardContent, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import EditFlavor from '../../components/Kundenanlage/EditFalvor/EditFlavor';
import FullSync from '../../components/Kundenanlage/FullSync/FullSync';
import SanityCheck from '../../components/Kundenanlage/SanityChecks/SanityCheck';
import EmailServerSMTPSettings from '../../components/Kundenanlage/EmailServer/EmailServerSMTPSettings';
import EditablePaymentProviderSettingList from '../../components/Kundenanlage/PaymentProviderSettings/PaymentProviderSettingsList';
import FlavourSettings from '../../components/Kundenanlage/FlavourSettings/FlavourSettings';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContextType } from '../../types/loginContext';
import { hasAccess } from '../../util/authorization';
import './Kundenanlage.css';

function Kundenanlage() {
	const { role, selectedFlavor }: LoginContextType = useContext(LoginContext);

	if (selectedFlavor.flavor === 'all') {
		return (
			<div className="kundenanlage__noFlavorSelected">
				Es muss ein Flavor ausgew&auml;hlt werden um die Kundenanlage anzeigen zu k&ouml;nnen
			</div>
		);
	}

	return (
		<Grid container style={{ marginBottom: '1rem' }}>
			{hasAccess(role, 'editFlavor') && (
				<Grid item className="kundenanlage__gridItem" xs={12} md={6}>
					<p className="kundenanlage__header">Flavor bearbeiten</p>
					<Card className="kundenanlage__card" variant="elevation">
						<CardContent>
							<EditFlavor />
						</CardContent>
					</Card>
				</Grid>
			)}
				

				{ (hasAccess(role, 'emailSmtpServer') || hasAccess(role, 'fullSync')) && (

<Grid item className="kundenanlage__gridItem" xs={12} md={6}>
{hasAccess(role, 'fullSync') && (
					<div className='kundenanlage__subgridItem'>
					<p className="kundenanlage__header">FullSync </p>
					<Card className="kundenanlage__card" variant="elevation">
						<CardContent>
							<FullSync />
						</CardContent>
					</Card>
					</div>
				)}
{hasAccess(role, 'emailSmtpServer') && (
	<div className='kundenanlage__subgridItem'>
<p className="kundenanlage__header">Einstellen Smtp Server</p>
<Card className="kundenanlage__card" variant="elevation">
	<CardContent>
		<EmailServerSMTPSettings />
	</CardContent>
</Card>
</div>)}
				

					
				</Grid>
)}
		
			{hasAccess(role, 'paymentProviderSetting') && (
				<Grid item className="kundenanlage__gridItem" xs={12} md={6}>
					
					<p className="kundenanlage__header">Bezahlung und Aufladung Einstellungen</p>
					<Card className="kundenanlage__card" variant="elevation">
						<CardContent>
							<EditablePaymentProviderSettingList />
						</CardContent>
					</Card>
			 
				</Grid>
			)}
		{hasAccess(role, 'sanityCheck') && (
				<Grid item className="kundenanlage__gridItem" xs={12} md={6}>
					
					<p className="kundenanlage__header">Statusabfrage</p>
					<Card className="kundenanlage__card" variant="elevation">
						<CardContent>
							<SanityCheck />
						</CardContent>
					</Card>
			 
				</Grid>
			)}
					{hasAccess(role, 'flavourSettings') && (
				<Grid item className="kundenanlage__gridItem" xs={12} md={6}>
					<p className="kundenanlage__header">Einstellungen bearbeiten</p>
					<Card className="kundenanlage__card" variant="elevation">
						<CardContent>
							<FlavourSettings />
						</CardContent>
					</Card>
				</Grid>
			)}
			
		</Grid>
	);
}

export default Kundenanlage;
