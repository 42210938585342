import * as userAccountApi from '../api/userAccount';
import * as userManagementApi from '../api/userManagement';
import { User } from '../types/user';

export const chargeBalance = async (user: User, amount: number, displaySnackbar: Function) => {
	if (user.flavor !== 'Dev' && user.flavor !== 'Demo')
		return displaySnackbar('Nur auf Dev und Demo möglich!', 'error');
	try {
		const data: number = await userAccountApi.chargeBalance(user.customerCard, amount, user.flavor);
		if (data === 1) return displaySnackbar('Aufladung erfolgreich.', 'success');
		displaySnackbar('Aufladung konnte nicht durchgeführt werden!', 'error');
	} catch (error) {
		console.error(error);
		displaySnackbar('Aufladung konnte nicht durchgeführt werden!', 'error');
	}
};

export const handlePasswordReset = async (user: User, displaySnackbar: Function) => {
	if (user != null) {
		try {
			const data: number = await userAccountApi.resetPassword(user.email, user.flavor);
			if (data === 1) {
				displaySnackbar('Email wurde versendet', 'success');
			} else {
				displaySnackbar('Email konnte nicht versendet werden!', 'error');
			}
		} catch (error) {
			console.error(error);
			displaySnackbar('Email konnte nicht versendet werden!', 'error');
		}
	}
};

export const handleResendConfirmationEmail = async (user: User, displaySnackbar: Function) => {
	if (user != null) {
		try {
			const data: number = await userAccountApi.resendConfirmEmail(user.id);
			if (data === 1) {
				displaySnackbar('Email wurde versendet', 'success');
			} else {
				displaySnackbar('Email konnte nicht versendet werden!', 'error');
			}
		} catch (error) {
			console.error(error);
			displaySnackbar('Email konnte nicht versendet werden!', 'error');
		}
	}
};

export const handleManuelEmailConfirmation = async (user: User, displaySnackbar: Function) => {
	if (user != null) {
		try {
			const data: number = await userAccountApi.manuallyConfirmEmail(user.id, user.flavor);
			if (data === 1) {
				displaySnackbar('Email wurde bestätigt', 'success');
			} else {
				displaySnackbar('Email konnte nicht bestätigt werden!', 'error');
			}
		} catch (error) {
			console.error(error);
			displaySnackbar('Email konnte nicht bestätigt werden!', 'error');
		}
	}
};

export const handleCustomerCardBlock = async (
	user: User,
	displaySnackbar: Function,
	closeModal: Function | undefined,
) => {
	if (user == null) return displaySnackbar('Fehler!', 'error');
	try {
		const data: number = await userAccountApi.blockCustomerCard(user.id);
		if (data === 1) {
			user.lockoutEnd = Date.now().toLocaleString();
			return displaySnackbar('Karte gesperrt.', 'success');
		}
		displaySnackbar('Karte konnte nicht gesperrt werden!', 'error');
	} catch (error) {
		console.error(error);
		displaySnackbar('Karte konnte nicht gesperrt werden!', 'error');
	}
	closeModal != null && closeModal();
};

export const handleCustomerCardUnblock = async (
	user: User,
	displaySnackbar: Function,
	closeModal: Function | undefined,
) => {
	if (user == null) return displaySnackbar('Fehler!', 'error');
	try {
		const data: number = await userAccountApi.unblockCustomerCard(user.id);
		if (data === 1) {
			user.lockoutEnd = Date.parse('01.01.10000').toLocaleString();
			return displaySnackbar('Karte freigeschaltet', 'success');
		}
		displaySnackbar('Karte konnte nicht freigeschaltet werden.', 'error');
	} catch (error) {
		console.error(error);
		displaySnackbar('Karte konnte nicht freigeschaltet werden.', 'error');
	}
	closeModal != null && closeModal();
};

export const handleUserDeletion = async (user: User, displaySnackbar: Function) => {
	if (user != null) {
		try {
			const data: number = await userManagementApi.deleteUser(user.id);
			if (data === 1) {
				displaySnackbar('Nutzer wurde erfolgreich gelöscht', 'success');
			} else {
				displaySnackbar('Nutzer konnte nicht gelöscht werden!', 'error');
			}
		} catch (error) {
			console.error(error);
			displaySnackbar('Nutzer konnte nicht gelöscht werden!', 'error');
		}
	}
};
