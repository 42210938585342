import React from 'react';
import { ProductCategory } from '../../types/product';
import ImagePlaceholder from '../ImagePlaceholder/ImagePlaceholder';
import './Category.css';

interface Props {
	category: ProductCategory;
	onClick: Function;
}

const Category = ({ category, onClick }: Props) => {
	return (
		<div className="category" onClick={() => onClick()}>
			<div className="category__idcontainer">{category.id}</div>
			<div className="category__imgcontainer">
				<ImagePlaceholder>
					<img src={category.image} alt="Kategoriebild" />
				</ImagePlaceholder>
			</div>
			<div className="category__namecontainer">{category.name}</div>
		</div>
	);
};

export default Category;
