export enum G2GTableOrderSetting {
    PrimaryColor = 0,
    SecondaryColor =1,
    CompanyLogo =2,
    Vorlaufzeiten_Minuten=3,
    BestellzeitMaximalStundenVorSchliessung=4,
	OrderingAllowedOnlyPerApp =5,
	DeeplinkPopupIntervallMS =6
}

/**
 * Branch TableOrder Info
 */ 
export interface StoreTableOrderInfo {
	storeID: number; //
	storeNumber : number;
	storeName : string;
	tableOrderAllowed: boolean;
}

/**
 * Patch Request Branch TableOrder Info
 */
export interface PatchBranchTableOrderInfo {
	branchTablePreordability: Record<number, boolean>; 
}

/**
 * TableOrder Admin Info
 */

export interface TableOrderAdminInfo {
	storeID: number; //
	storeNumber : number;
	storeName : string;
	tableID: number;
	code: string | null;
	url:string;
}
