import { AxiosResponse } from "axios";
import { CustomerCard } from "../types/customerCard";
import { OrderHistory, ShoppingHistory } from "../types/order";
import { EditableUser } from "../types/user";
import { axiosInstance } from "./axiosConfig";

/**
 * reset users password
 *
 * @param email email address of the concerned user
 * @returns 1 on success, otherwise -1
 */
export const resetPassword = async (
  email: string,
  flavor: string
): Promise<number> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance.post(
      `v1/Auth/forgotpw?email=${email}`,
      {},
      { headers: { AppFlavour: flavor } }
    );
    if (res != null) return 1;
  } catch (error) {
    console.error(error);
  }
  return -1;
};

/**
 * resend confirmation email to user
 *
 * @param userId userId of the concerned user
 * @returns 1 on success, otherwise -1
 */
export const resendConfirmEmail = async (userId: string): Promise<number> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance.post(
      `v1/SuperAdmin/resend-email-confirmation?id=${userId}`
    );
    if (res != null) return 1;
  } catch (error) {
    console.error(error);
  }
  return -1;
};

/**
 * confirm the user manually
 *
 * @param userId userId of the concerned user
 * @returns
 */
export const manuallyConfirmEmail = async (userId: string, flavor: string ): Promise<number> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance.post(
      `v1/SuperAdmin/confirm-email?id=${userId}`,
      {},
      { headers: { AppFlavour: flavor } }
    );
    if (res != null) return 1;
  } catch (error) {
    console.error(error);
  }
  return -1;
};

/**
 * edit the user
 *
 * @param userId id of the user
 * @param user
 * @returns 1 on success, otherwise -1
 */
export const editUser = async (userId: string, user: EditableUser, flavor:string) => {
  try {
    const res: AxiosResponse<any> = await axiosInstance.post(
      `v1/SuperAdmin/user-changeSettings?id=${userId}`,
      user,
      { headers: { AppFlavour: flavor } }
    );
    if (res != null) return 1;
  } catch (error) {
    console.error(error);
  }
  return -1;
};

/**
 * get the customerCard from a user by userId from api
 *
 * @param id userId
 * @returns customerCard or null
 */
export const getUserCard = async (
  id: string,
  flavor: string
): Promise<CustomerCard | null> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance(
      `v1/SuperAdmin/customer-card?id=${id}`,
      { headers: { AppFlavour: flavor } }
    );
    if (res != null) return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

/**
 * get the shoppingHistory of a user by userId from api
 *
 * @param id userId
 * @returns ShoppingHistory or null
 */
export const getShoppingHistory = async (
  id: string,
  flavor: string
): Promise<null | ShoppingHistory[]> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance(
      `v1/SuperAdmin/user-shopping-history?id=${id}`,
      { headers: { AppFlavour: flavor } }
    );
    if (res != null) return res.data.changed;
  } catch (error) {
    console.error(error);
  }
  return null;
};

/**
 * get the orderHistory of a user by userId from api
 *
 * @param id userId
 * @returns orderHistory array on success, otherwise null
 */
export const getOrderHistory = async (
  id: string,
  flavor: string
): Promise<null | OrderHistory[]> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance(
      `v1/SuperAdmin/user-orders?id=${id}`,
      { headers: { AppFlavour: flavor } }
    );
    if (res != null) return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

/**
 * blocks the customer card of the user
 *
 * @param id userId
 * @returns 1 on success, otherwise -1
 */
export const blockCustomerCard = async (id: string): Promise<number> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance.patch(
      `v1/SuperAdmin/block-customer-card?id=${id}`
    );
    if (res != null) return 1;
  } catch (error) {
    console.error(error);
  }
  return -1;
};

/**
 * unblocks the customer card of the user
 *
 * @param id userId
 * @returns 1 on success, otherwise -1
 */
export const unblockCustomerCard = async (id: string): Promise<number> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance.patch(
      `v1/SuperAdmin/unblock-customer-card?id=${id}`
    );
    if (res != null) return 1;
  } catch (error) {
    console.error(error);
  }
  return -1;
};

/**
 * change the customer card balance of a user on Demo oder Dev flavor
 *
 * @param id customerCardId
 * @param amount amount to charge
 * @param flavor Dev or Demo flavor
 * @returns 1 on success, otherwise -1
 */
export const chargeBalance = async (
  id: string,
  amount: number,
  flavor: "Demo" | "Dev"
): Promise<number> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance.post(
      `v1/SuperAdmin/charge-balance?cardID=${id}&amount=${amount}`,
      {},
      {
        headers: {
          AppFlavour: flavor,
        },
      }
    );
    if (res != null) return 1;
  } catch (error) {
    console.error(error);
  }
  return -1;
};
