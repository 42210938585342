import { Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAllUsers } from '../../api/userManagement';
import AgeDoughnutChart from '../../components/AgeDoughnutChart/AgeDoughnutChart';
import GenderAgeBarChart from '../../components/GenderAgeBarChart/GenderAgeBarChart';
import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContextType } from '../../types/loginContext';
import { GenderAgeData } from '../../types/statistics';
import { buildGenderAgeArray, getUserNumberByGender } from '../../util/statistics';

interface GenderCounter {
	male: number;
	female: number;
	divers: number;
	family: number;
	company: number;
	other: number;
}

function Home() {
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);
	const {
		data: allUser,
		isLoading,
		refetch,
	} = useQuery('getUser', () => getAllUsers(selectedFlavor.flavor, "Home.tsx"), {
		refetchInterval: 1000 * 60,
		refetchOnWindowFocus: true,
	});

	const [isWaiting, setIsWaiting] = useState<boolean>(true);
	const [userAmountByGender, setUserAmountByGender] = useState<GenderCounter>({
		male: 0,
		female: 0,
		divers: 0,
		family: 0,
		company: 0,
		other: 0,
	});
	const [genderAgeArray, setGenderAgeArray] = useState<GenderAgeData | null>(null);

	useEffect(() => {
		setIsWaiting(true);
		refetch();
	}, [selectedFlavor]);

	useEffect(() => {
		setIsWaiting(false);
		if (!allUser) return;
		setUserAmountByGender(getUserNumberByGender(allUser));
		setGenderAgeArray(buildGenderAgeArray(allUser));
	}, [allUser]);

	if (isWaiting || isLoading || !allUser) {
		return <Loadingspinner />;
	}

	return (
		<div>
			{/* <h1>Statistiken</h1> */}
			<Grid container>
				<Grid item xs={12} md={6} style={{ height: 500 }}>
					<h2>Nutzerzahlen nach Geschlecht</h2>
					{userAmountByGender && <AgeDoughnutChart data={userAmountByGender} />}
				</Grid>
				<Grid item xs={12} md={6}>
					<h2>Nutzerzahlen nach Alter und Geschlecht</h2>
					{genderAgeArray && <GenderAgeBarChart data={genderAgeArray} />}
				</Grid>
			</Grid>
		</div>
	);
}

export default Home;
