import { Button, Card, CardContent } from '@material-ui/core';
import dateFormat from 'dateformat';
import React, { useState } from 'react';
import { CustomerCard as CustomerCardType } from '../../types/customerCard';
import { User } from '../../types/user';
import CustomerCard from '../CustomerCard/CustomerCard';
import UserEditForm from '../UserEditForm/UserEditForm';
import './UserDataCard.css';

interface Props {
	user: User;
	customerCard: CustomerCardType | undefined;
}

function UserDataCard({ user, customerCard }: Props) {
	const [editMode, setEditMode] = useState(false);

	if (editMode) {
		return <UserEditForm user={user} setEditMode={setEditMode} />;
	}

	//Wenn Datenfelder leer waren, verschob sich alles nach oben, daher dieses schnelle bandaid
	const NonNullableP = (data: any) => {
		//Extra case since true/false somehow got converted to empty string...
		if (typeof data == "boolean") {
			return (<p>{data ? "Ja" : "Nein"}</p>);
		}
		if (data != null && data !== "") {
			return (<p>{data}</p>);
		}
		return (<br />);
	}

	return (
		<Card className="userDataCard__root" variant="elevation">
			<CardContent>
				<CustomerCard card={customerCard ? customerCard.debitCard : undefined} />
				<div className="userDataCard__outerWrapper">
					<div className="userDataCard__innerWrapper">
						<div>
							<p>ID: </p>
							<p>Flavor: </p>
							<p>Anrede: </p>
							<p>Vorname: </p>
							<p>Nachname: </p>
							<p>Geburtsdatum: </p>
							<p>Telefon: </p>
							<p>Email: </p>
							<p>Best&auml;tigt: </p>
							<p>Registrierungsdatum: </p>
							<p>Fehlgeschlagene Logins: </p>
							{Date.parse(user.lockoutEnd) > Date.now() && <p>Karte entsperrt: </p>}
						</div>
						<div>
							{NonNullableP(user.id)}
							{NonNullableP(user.flavor)}
							{NonNullableP(user.salutation)}
							{NonNullableP(user.vorname)}
							{NonNullableP(user.name)}
							{NonNullableP(dateFormat(user.birthdate, 'dd.mm.yyyy'))}
							{NonNullableP(user.phonenumber)}
							{NonNullableP(user.email)}
							{NonNullableP(user.emailConfirmed)}
							{NonNullableP(dateFormat(user.registrationTimeUTC, 'dd.mm.yyyy'))}
							{NonNullableP(user.accessFailedCount)}
							{Date.parse(user.lockoutEnd) > Date.now() && (
								NonNullableP(dateFormat(user.lockoutEnd, 'dd.mm.yyyy'))
							)}
						</div>
					</div>
					<Button color="secondary" onClick={() => setEditMode(true)}>
						Bearbeiten
					</Button>
				</div>
			</CardContent>
		</Card>
	);
}

export default UserDataCard;
