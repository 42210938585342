import { Dialog, IconButton,Popper  } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowData } from '@material-ui/data-grid';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getBranches } from '../../api/branche';
import { getPOSSyncOrder } from '../../api/orders';
import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import OrderDocument from '../../components/OrderDocument/OrderDocument';
import { LoginContext } from '../../contexts/LoginContext';
import { Branch } from '../../types/branch';
import { LoginContextType } from '../../types/loginContext';
import './BranchOrders.css';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import dateFormat from 'dateformat';
import { useHistory, useLocation } from 'react-router';
import { parse } from 'query-string';
import { getPaymentMethod, getPaymentStatus } from '../../util/payment';
import { POSSyncOrder } from '../../types/order';

interface Props {
	params: any;
}

const BranchOrders = ({ params }: Props) => {
	const history = useHistory();
	const location = useLocation();
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);
	const { data: branches, isLoading: branchesLoading } = useQuery('getBranches', () =>
		getBranches(selectedFlavor.flavor),
	);
	const {
		data: apiOrders,
		isLoading: ordersLoading,
		refetch,
	} = useQuery('getOrders', () => getPOSSyncOrder(selectedFlavor.flavor, params.id));

	const [notAvailableOnFlavor, setNotAvailableOnFlavor] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState<number>(25);
	const [anchorElTooltip, setAnchorElTooltip] = useState<null | HTMLElement>(null);
	const [selectedOrder, setSelectedOrder] = useState<any>();
	const [showReceipt, setShowReceipt] = useState<boolean>(false);

	// reads page size from url
	useEffect(() => {
		const urlPageSize = parse(location.search).pagesize;
		if (urlPageSize) setPageSize(Number.parseInt(urlPageSize.toString()));
	}, []);

	// check if current branch id is available on this flavor
	useEffect(() => {
		if (!branches) return;
		const branchAvailable: boolean = branches.some(
			(branch: Branch) => branch.id.toString() === params.id.toString(),
		);
		if (branchAvailable) return setNotAvailableOnFlavor(true);
		setNotAvailableOnFlavor(true);
	}, [branches]);

	useEffect(() => {
		refetch();
	}, [selectedFlavor]);

	// hides the "kopiert" popup after a delay
	useEffect(() => {
		if (anchorElTooltip == null) return;
		setTimeout(() => {
			setAnchorElTooltip(null);
		}, 1200);
	}, [anchorElTooltip]);

	/** selects the order id of the clicked row */
	const selectRow = (params: any) => {
		setSelectedOrder(params.row);
	};

	/** copies the order id and triggers "kopiert" popup */
	const handleCopyId = (event: React.MouseEvent<HTMLDivElement>) => {
		if (apiOrders && selectedOrder) navigator.clipboard.writeText(selectedOrder.id);
		setAnchorElTooltip(event.currentTarget);
	};

	const handleButtonClick = () => {
		setShowReceipt(true);
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			flex: 2,
			renderCell: (param) => {
				return (
					<div
						style={{ width: '100%', overflow: 'hidden' }}
						onDoubleClick={handleCopyId}
						onMouseDown={(event) => event.preventDefault()}
					>
						{param.row.id}
					</div>
				);
			},
		},
		{ field: 'Abholcode', headerName: 'Abholcode', flex: 1 },
		{
			field: 'Bestellzeit',
			headerName: 'Bestellzeit',
			flex: 1,
			renderCell: (params) => dateFormat(params.row.Bestellzeit, 'dd.mm.yyyy - HH:MM'),
		},
		{
			field: 'Bezahlzeit',
			headerName: 'Bezahlzeit',
			flex: 1,
			renderCell: (params) => dateFormat(params.row.Bezahlzeit, 'dd.mm.yyyy - HH:MM'),
		},
		{
			field: 'BezahlArt',
			headerName: 'Bezahlart',
			flex: 1,
			renderCell: (params) => getPaymentMethod(params.row.BezahlArt),
		},
		{
			field: 'Status',
			headerName: 'Status',
			flex: 1,
			renderCell: (params) => getPaymentStatus(params.row.Status),
		},
		{
			field: 'GesamtBetrag',
			headerName: 'Gesamtbetrag',
			width: 170,
			align: 'right',
			renderCell: (params) => `${params.row.GesamtBetrag.toFixed(2)}€`,
		},
		{
			field: 'UserVorname',
			headerName: 'Vorname',
			flex: 1,
		},
		{
			field: 'UserNachname',
			headerName: 'Nachname',
			flex: 1,
		},
		{
			field: 'UserTelefon',
			headerName: 'Telefon',
			flex: 1,
		},
		{
			field: '',
			headerName: 'Bon',
			width: 80,
			sortable: false,
			align: 'center',
			renderCell: () => {
				return (
					<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleButtonClick}>
						<ReceiptOutlinedIcon />
					</IconButton>
				);
			},
		},
	];

	if (selectedFlavor.flavor === 'all') {
		return (
			<div className="branch__noFlavorSelected">
				Es muss ein Flavor ausgewählt werden um die Bestellungen der Filiale anzeigen zu können
			</div>
		);
	}

	if (notAvailableOnFlavor) {
		<div className="branch__noFlavorSelected">Die gewünschte Filiale existiert nicht</div>;
	}

	if (ordersLoading || branchesLoading) {
		return <Loadingspinner />;
	}

	return (
		<>
			<div className="branchOrders">
				{apiOrders != null ? (
					<DataGrid
						rows={apiOrders}
						columns={columns}
						pageSize={pageSize}
						onPageSizeChange={(newPagesize) => {
							setPageSize(newPagesize);
							history.push({
								pathname: location.pathname,
								search: `pagesize=${newPagesize}`,
							});
						}}
						onRowClick={selectRow}
					/>
				) : (
					<Loadingspinner />
				)}
			</div>

			<Dialog
				onClose={() => setShowReceipt(false)}
				aria-labelledby="possyncOrder-document"
				open={showReceipt}
			>
				<div style={{ width: 340, display: 'flex', justifyContent: 'center' }}>
					<OrderDocument order={selectedOrder} />
				</div>
			</Dialog>

			{/* popup after copying customerCard or userId */}
			{anchorElTooltip != null && (
				<Popper anchorEl={anchorElTooltip} open={anchorElTooltip != null} placement="right">
					<div className="branchOrders__popper">kopiert</div>
				</Popper>
			)}
		</>
	);
};

export default BranchOrders;
