import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@material-ui/core';
import React, { ChangeEvent, useContext, useState } from 'react';
import { chargeBalance } from '../../service/user';
import { User } from '../../types/user';
import { SnackBarContext } from '../CustomAlertSnackBar/CustomAlertSnackBarContext';

interface Props {
	user: User;
	openDialog: boolean;
	setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

function UserBalanceChargeDialog({ user, openDialog, setOpenDialog }: Props) {
	const { displaySnackbar } = useContext(SnackBarContext);

	const [amount, setAmount] = useState<number>(0);

	return (
		<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
			<DialogTitle>Aufladen</DialogTitle>
			<DialogContent>
				<DialogContentText>Um welchen Betrag soll das Konto aufgeladen werden?</DialogContentText>
				<TextField
					label="Betrag"
					type="number"
					value={amount}
					onChange={(event: ChangeEvent<{ value: any }>) => setAmount(event.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						chargeBalance(user, amount, displaySnackbar);
						setOpenDialog(false);
					}}
					color="primary"
				>
					Aufladen
				</Button>
				<Button onClick={() => setOpenDialog(false)} color="default" autoFocus>
					Abbrechen
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default UserBalanceChargeDialog;
