import { IconButton, Popper } from '@material-ui/core';

import { DataGrid, GridColDef, GridRowData } from '@material-ui/data-grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { parse } from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import * as userManagementApi from '../../api/userManagement';
import { changeRole } from '../../api/userManagement';
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import UsersPopups from '../../components/UserManagementPopups/UsersPopups';
import { LoginContext } from '../../contexts/LoginContext';
import { UserManagementActions } from '../../types/actions';
import { Flavor } from '../../types/flavor';
import { LoginContextType } from '../../types/loginContext';
import { User } from '../../types/user';
import { hasAccess } from '../../util/authorization';
import './UserManagement.css';

const UserManagement = () => {
	const history = useHistory();
	const location = useLocation();
	const { role, selectedFlavor, allowedFlavors }: LoginContextType = useContext(LoginContext);
	const { displaySnackbar } = useContext(SnackBarContext);

	const {
		data: allUser,
		error,
		isLoading,
		refetch,
	} = useQuery('getAdminUser', () => userManagementApi.getAllAdminUsers(selectedFlavor.flavor), {
		refetchInterval: 10000,
	});

	const changeRoleMutation = useMutation(
		async ({
			userFlavor,
			userId,
			newRole,
		}: {
			userFlavor: string;
			userId: string;
			newRole: 'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | 'Customer';
		}) => {
			const res: number = await changeRole(userFlavor, userId, newRole);
			showSnackbar('Rolle wurde geändert.', 'Fehler beim ändern der Rolle!', res);
		},
	);

	const actions: UserManagementActions = {
		changeRole: changeRoleMutation,
	};

	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorElTooltip, setAnchorElTooltip] = useState<null | HTMLElement>(null);
	const [filteredUsers, setFilteredUsers] = useState<User[]>();
	const [selectedUser, setSelectedUser] = useState<User | null>();
	const [pageSize, setPageSize] = useState<number>(25);

	useEffect(() => {
		const urlPageSize = parse(location.search).pagesize;
		if (urlPageSize) setPageSize(Number.parseInt(urlPageSize.toString()));
	}, []);

	useEffect(() => {
		if (allUser != null) {
			if (selectedFlavor.flavor !== 'all') {
				setFilteredUsers(allUser.filter((user) => user.flavor === selectedFlavor.flavor));
			} else {
				setFilteredUsers(allUser);
			}
		}
	}, [selectedFlavor, allUser]);

	// hides the "kopiert" popup after a delay
	useEffect(() => {
		if (anchorElTooltip == null) return;
		setTimeout(() => {
			setAnchorElTooltip(null);
		}, 1200);
	}, [anchorElTooltip]);

	const showSnackbar = (msgSuccess: string, msgError: string, type: number) => {
		displaySnackbar(type === 1 ? msgSuccess : msgError, type === 1 ? 'success' : 'error');
		refetch();
	};

	/** records the "more" button click */
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	/** copies the user id and triggers "kopiert" popup */
	const handleCopyId = (event: React.MouseEvent<HTMLDivElement>) => {
		if (selectedUser) navigator.clipboard.writeText(selectedUser.id);
		setAnchorElTooltip(event.currentTarget);
	};

	const columns: GridColDef[] = [
		{ field: 'email', headerName: 'E-Mail', flex: 1 },
		{ field: 'vorname', headerName: 'Vorname', width: 190 },
		{ field: 'name', headerName: 'Nachname', width: 190 },
		{ field: 'role', headerName: 'Rolle', width: 190 },
		{
			field: 'emailConfirmed',
			headerName: 'Bestätigt',
			width: 130,
			renderCell: (param) => {
				return param.row.emailConfirmed ? 'bestätigt' : 'offen';
			},
		},
		{
			field: '',
			headerName: 'Mehr',
			width: 85,
			sortable: false,
			align: 'center',
			renderCell: () => {
				return (
					<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
				);
			},
		},
	];

	if (selectedFlavor.flavor === 'all')
		columns.splice(0, 0, {
			field: 'flavor',
			headerName: 'Flavor',
			flex: 0.5,
			renderCell: (param) => {
				return allowedFlavors.find((item: Flavor) => param.row.flavor === item.flavor)?.name;
			},
		});

	if (hasAccess(role, 'userManagerIdColumn'))
		columns.splice(0, 0, {
			field: 'id',
			headerName: 'ID',
			flex: 1,
			renderCell: (param) => {
				return (
					<div
						style={{ width: '100%', overflow: 'hidden' }}
						onDoubleClick={handleCopyId}
						onMouseDown={(event) => event.preventDefault()}
					>
						{param.row.id}
					</div>
				);
			},
		});

	if (error) {
		console.error(error);
	}

	if (isLoading || filteredUsers == null) {
		return <Loadingspinner />;
	}

	if (filteredUsers.length===0){
		<div>Keine anzeigbaren Nutzer vorhanden.</div>
	}

	return (
		<>
			<div className="userTable">
				<DataGrid
					rows={filteredUsers}
					columns={columns}
					pageSize={pageSize}
					onPageSizeChange={(newPagesize) => {
						setPageSize(newPagesize);
						history.push({
							pathname: location.pathname,
							search: `pagesize=${newPagesize}`,
						});
					}}
					onRowClick={(element: GridRowData) => setSelectedUser(element.row as User)}
				/>
			</div>

			{/* Popupmenue on user options */}
			{selectedUser != null && (
				<UsersPopups
					user={selectedUser as User}
					anchorElement={anchorEl}
					setAnchorElement={setAnchorEl}
					openSecurityDialog={openDeleteDialog}
					setOpenSecurityDialog={setOpenDeleteDialog}
					actions={actions}
				/>
			)}

			{/* popup after copying customerCard or userId */}
			{anchorElTooltip != null && (
				<Popper anchorEl={anchorElTooltip} open={anchorElTooltip != null} placement="right">
					<div className="userTable__popper">kopiert</div>
				</Popper>
			)}
		</>
	);
};

export default UserManagement;
