import { refreshAccessToken } from "../api/auth";
import type { TokenType } from "../types/TokenType";

/* eslint no-var: 0 */
var accessToken: TokenType | undefined;

/**
 * sets the given accesstoken as current accesstoken 
 * 
 * @param token accestoken
 */
export const saveAccessToken = (token: TokenType | undefined) => {
    accessToken = token;
    if (token === undefined) {
        PurgeAccessToken();
    } else {
        StoreLocalToken();
    }
};

/**
 * get the current accesstoken
 * 
 * @returns the current accesstoken
 */
export const getAccessToken = (): TokenType | undefined => {
    return accessToken;
};

/**
 * purges the accesstoken to prevent autologin after logout
 * 
 */
export const PurgeAccessToken = () => {
    accessToken = undefined;
};

/**
 * checks localstorage for accesstoken - if present, tries refreshing it.
 * 
 * @returns 1 if success, -1 if error
 */
export const CheckLocalToken = async (): Promise<1 | -1> => {
    try {
        let temptok = localStorage.getItem('localTokAdmindashboard')
        if (temptok === null || temptok === undefined) {
            console.log("No local accesstoken found");
            return -1;
        } else if (temptok === 'purged') {
            console.log("Local accesstoken was purged");
            return -1;
        } else {
            try {
                let accTok: TokenType = JSON.parse(temptok);
                saveAccessToken(accTok);
                await refreshAccessToken();
                if (getAccessToken() !== undefined) {
                    return 1;
                } else {
                    return -1;
                }
            } catch (error) {
                console.log(error);
                return -1;
            }
        }
    } catch (error) {
        console.log(error);
        return -1;
    }
};

/**
 * stores accesstoken in localstorage
 */
export const StoreLocalToken = () => {
    localStorage.setItem('localTokAdmindashboard', JSON.stringify(accessToken));
};

/**
 * purge local accesstoken to prevent autologin after logout
 */
export const PurgeLocalToken = () => {
    localStorage.setItem('localTokAdmindashboard', 'purged');
};