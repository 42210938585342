import { Button, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import RefreshIcon from '@material-ui/icons/Refresh';
import SyncIcon from '@material-ui/icons/Sync';
import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../../../contexts/LoginContext';
import { eStatus } from '../../../types/kundenanlage';
import { LoginContextType } from '../../../types/loginContext';
import './InfoRow.css';

interface Props {
	name: string;
	onClick: Function;
}

function InfoRow({ name, onClick }: Props) {
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);

	const [status, setStatus] = useState<eStatus>(eStatus.initial);
	const [message, setMessage] = useState<string>('');

	useEffect(() => {
		setStatus(eStatus.initial);
	}, [selectedFlavor]);

	const getIcon = () => {
		switch (status) {
			case eStatus.initial:
				return <HelpIcon className="infoRow__initial" />;
			case eStatus.loading:
				return <SyncIcon className="infoRow__loading" />;
			case eStatus.success:
				return <CheckCircleIcon className="infoRow__success" />;
			case eStatus.error:
				return <ErrorIcon className="infoRow__error" />;
			default:
				break;
		}
	};

	return (
		<div className="infoRow">
			{message !== '' ? (
				<Tooltip title={message}>
					<div>{getIcon()}</div>
				</Tooltip>
			) : (
				<div>{getIcon()}</div>
			)}
			<div className="infoRow__name">{name}</div>
			<div>
				<Button
					onClick={async () => {
						setStatus(eStatus.loading);
						const res = await onClick();
						setStatus(res.status);
						setMessage(res.msg);
					}}
				>
					<RefreshIcon />
				</Button>
			</div>
		</div>
	);
}

export default InfoRow;
