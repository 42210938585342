import { createContext, FunctionComponent, useEffect, useState } from 'react';
import { getAllowedFlavours } from '../api/flavor';
import { PurgeLocalToken, saveAccessToken } from "../service/Token";
import { Flavor, LoginFlavorDTO, LoginOnlyFlavor } from '../types/flavor';
import { LoginContextType } from '../types/loginContext';

export const LoginContext = createContext({} as LoginContextType);

const LoginProvider: FunctionComponent = (props) => {
	const [role, setRole] = useState<string>('');
	const [allowedFlavors, setAllowedFlavors] = useState<Flavor[]>([getDefaultFlavor()]);
	const [selectedFlavor, setSelectedFlavor] = useState<Flavor>(allowedFlavors[0]);
	const [loginFlavors, setLoginFlavors] = useState<LoginFlavorDTO | null>(null);
	const [chosenFlavor, setChosenFlavor] = useState<LoginOnlyFlavor | null>(null);
	const [tempEmail, setTempEmail] = useState('');
	const [tempPassword, setTempPassword] = useState('');

	useEffect(() => {
		const getAllowedFavors = async () => {
			const flavors: Flavor[] | null = await getAllowedFlavours(chosenFlavor != null ? chosenFlavor.flavor : 'Dev');
			if (flavors != null) {
				if (flavors.length === 1) {
					setAllowedFlavors([...flavors]);
					setSelectedFlavor(flavors[0]);
				} else {
					setAllowedFlavors([...allowedFlavors, ...flavors]);
				}
			}
		};
		if (role !== '') getAllowedFavors();
	}, [role]);

	const logout = () => {
		setRole('');
		setAllowedFlavors([getDefaultFlavor()]);
		setSelectedFlavor(allowedFlavors[0]);
		saveAccessToken(undefined);
		PurgeLocalToken();
		localStorage.setItem('userLoggedOut', 'true');
		sessionStorage.removeItem('alternativeAPIRoute');
	};

	return (
		<LoginContext.Provider
			value={{
				logout,
				role,
				setRole,
				allowedFlavors,
				setAllowedFlavors,
				selectedFlavor,
				setSelectedFlavor,
				loginFlavors,
				setLoginFlavors,
				tempEmail,
				setTempEmail,
				tempPassword,
				setTempPassword,
				chosenFlavor,
				setChosenFlavor
			}}
		>
			{props.children}
		</LoginContext.Provider>
	);
};

const getDefaultFlavor = (): Flavor => {
	return {
		flavor: 'all',
		name: 'Alle',
		primaryColor: '',
		secondaryColor: '',
		kundennummer: '',
		emailDomain: '',
	};
};

export default LoginProvider;
