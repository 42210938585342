import React from 'react';
import './ImagePlaceholder.css';

interface Props {
	children: any;
}

const ImagePlaceholder = ({ children }: Props) => {
	return <div className="imagePlaceholder">{children}</div>;
};

export default ImagePlaceholder;
