import { AxiosResponse } from 'axios';
import { InvitedUser, RegistrationUser, User } from '../types/user';
import { axiosInstance } from './axiosConfig';

/**
 * create a new user in the database
 *
 * @param user user that should be created
 * @returns 1 on success, otherwise -1
 */
export const createUser = async (user: RegistrationUser): Promise<number> => {
	try {
		await axiosInstance.post(`v1/Auth/register`, user, {
			withCredentials: true,
			headers: {
				AppFlavour: user.flavor,
			},
		});
		return 1;
	} catch (error) {
		console.error(error);
	}
	return -1;
};

/**
 * delete a user from database
 *
 * @param userId userId of the concerned user
 * @returns 1 on success, otherwise -1
 */
export const deleteUser = async (userId: string): Promise<number> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.post(
			`v1/SuperAdmin/delete-user?id=${userId}`,
		);
		if (res != null) return 1;
	} catch (error) {
		console.error(error);
	}
	return -1;
};

/**
 * get all users of a specific flavor
 *
 * @param flavor
 * @returns
 */
export const getAllUsers = async (flavor: string | null, calledFrom:string): Promise<User[] | null> => {
	if (flavor === 'all') flavor = null;
	try {
		const res: AxiosResponse<any> = await axiosInstance.get('v1/SuperAdmin/users', {
			params: {
				AppFlavour: flavor,
			},
		});
		return res.data;
	} catch (error) {
		console.error(error);
		//console.log("Error'ing user call called from: " + calledFrom);
	}
	return null;
};

/**
 * get all users of a specific flavor
 *
 * @param flavor
 * @returns
 */
 export const getAllAdminUsers = async (flavor: string | null): Promise<User[] | null> => {
	if (flavor === 'all') flavor = null;
	try {
		const res: AxiosResponse<any> = await axiosInstance.get('v1/SuperAdmin/admin-users', {
			params: {
				AppFlavour: flavor,
			},
		});
		return res.data;
	} catch (error) {
		console.error(error);
		//console.log("ERRORING CALL IS USING FLAVOR " + flavor);
	}
	return null;
};

// TODO rückgabetyp anpassen
/**
 * get all users the currently logged in user can manage
 *
 * @param flavor flavor the get the manageable users for
 * @returns
 */
export const getManageableUsers = async (flavor: string): Promise<any[]> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.get('v1/SuperAdmin/manageableUsers', {
			params: {
				AppFlavour: flavor,
			},
		});
		return res.data;
	} catch (error) {
		console.error(error);
		return [];
	}
};

/**
 * change the role of a user
 *
 * @param flavor flavor of the selected user
 * @param userId user id of the selected user
 * @param newRole new role for the selected user
 * @returns
 */
export const changeRole = async (
	flavor: string,
	userId: string,
	newRole: 'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | 'Customer',
): Promise<1 | -1> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.post(
			'v1/SuperAdmin/changeUserRole',
			{ userId: userId, newRole: newRole },
			{ headers: { AppFlavour: flavor } },
		);
		if (res) return 1;
	} catch (error) {
		console.error(error);
	}
	return -1;
};

export const inviteUser = async (
	email: string,
	role: 'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | 'Customer',
	flavor: string,
): Promise<1 | -1> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.post(
			'v1/auth/invite-user',
			{
				userFlavor: flavor,
				email: email,
				userRoles: [role],
			},
			{ headers: { AppFlavour: flavor } },
		);
		if (res) return 1;
	} catch (error) {
		console.error(error);
	}
	return -1;
};

export const createInvitedUser = async (
	newUser: InvitedUser,
	token: string,
	encflavor: string,
): Promise<1 | -1> => {
	if (token == '') return -1;
	try {
		const res: AxiosResponse<any> = await axiosInstance.post(
			'v1/auth/register-invited',
			{
				acceptedPrivacyStatement: true,
				birthdate: newUser.birthdate,
				companyName: newUser.companyName,
				firstName:newUser.firstName,
				inviteToken:token,
				name:newUser.name,
				password:newUser.password,
				phoneNumber:newUser.phoneNumber,
				salutation:newUser.salutation
			},
			{ headers: { EncFlavour: encodeURI(encflavor) } },
		);
		if (res) return 1;
	} catch (error) {
		console.error(error);
	}
	return -1;
};
