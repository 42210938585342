import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowData } from '@material-ui/data-grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { getBranches } from '../../api/branche';
import { getPOSSyncOrder } from '../../api/orders';
import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import { LoginContext } from '../../contexts/LoginContext';
import { Branch as BranchType } from '../../types/branch';
import { LoginContextType } from '../../types/loginContext';
import { hasAccess } from '../../util/authorization';
import './Branches.css';
import { parse } from 'query-string';

const Branches = () => {
	const history = useHistory();
	const location = useLocation();
	const { role, selectedFlavor }: LoginContextType = useContext(LoginContext);
	const { data, isLoading, refetch } = useQuery('getBranches', () =>
		getBranches(selectedFlavor.flavor),
	);

	const [selectedBranch, setSelectedBranch] = useState<BranchType>();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isWaiting, setIsWaiting] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState<number>(25);

	useEffect(() => {
		const urlPageSize = parse(location.search).pagesize;
		if (urlPageSize) setPageSize(Number.parseInt(urlPageSize.toString()));
	}, []);

	useEffect(() => {
		setIsWaiting(true);
		refetch();
	}, [selectedFlavor]);

	useEffect(() => {
		setIsWaiting(false);
	}, [data]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	if (selectedFlavor.flavor === 'all') {
		return (
			<div className="branch__noFlavorSelected">
				Es muss ein Flavor ausgewählt werden um die Filialen anzeigen zu können
			</div>
		);
	}

	if (isWaiting) {
		return <Loadingspinner />;
	}

	if (isLoading) {
		return <div>Laden...</div>;
	}

	if (data == null || data.length < 1) {
		return <div className="branch__noFlavorSelected">Keine Filialen verfügbar</div>;
	}

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'name', headerName: 'Name', width: 200 },
		{ field: 'filialNummer', headerName: 'FillialNr', width: 130 },
		{
			field: 'street',
			headerName: 'Anschrift',
			flex: 1,
			minWidth: 300,
			renderCell: (param) => {
				return `${param.row.street} ${param.row.streetNumber}, ${param.row.postCode} ${param.row.city}`;
			},
		},
		{ field: 'country', headerName: 'Land', width: 150 },
		{ field: 'telephone', headerName: 'Telefon', width: 160 },
		{ field: 'website', headerName: 'Webseite', flex: 1, minWidth: 200 },
		{
			field: 'times',
			headerName: 'Öffnungzeigen',
			width: 150,
			renderCell: (param) => {
				return (
					<div style={{ padding: 0, margin: 0 }}>
						{param.row.times.map((time: string, index: number) => (
							<div key={index} className="branch__time">{`${time} \n`}</div>
						))}
					</div>
				);
			},
		},
		{
			field: '',
			headerName: 'Mehr',
			sortable: false,
			align: 'center',
			renderCell: () => {
				return (
					<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
				);
			},
		},
	];

	return (
		<>
			<div className="branch__table">
				<DataGrid
					rows={data}
					columns={columns}
					pageSize={pageSize}
					onPageSizeChange={(newPagesize) => {
						setPageSize(newPagesize);
						history.push({
							pathname: location.pathname,
							search: `pagesize=${newPagesize}`,
						});
					}}
					rowHeight={80}
					onRowClick={(element: GridRowData) => setSelectedBranch(element.row as BranchType)}
					onCellDoubleClick={(element: GridRowData) => {
						if (element.value != null) {
							navigator.clipboard.writeText(element.value.toString());
						}
					}}
				/>
			</div>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				{hasAccess(role, 'orderOverview') && (
					<MenuItem
						onClick={() =>
							history.push(`/${selectedFlavor.flavor}/branches/${selectedBranch!.id}/orders`)
						}
					>
						Bestellungen
					</MenuItem>
				)}
				{hasAccess(role, 'productAvailability') && (
					<MenuItem
						onClick={() =>
							history.push(
								`/${selectedFlavor.flavor}/branches/${selectedBranch!.id}/productavailability`,
							)
						}
					>
						Produktverfügbarkeit
					</MenuItem>
				)}
			</Menu>
		</>
	);
};

export default Branches;
