import { GenderAgeData, GenderCounter } from '../types/statistics';
import { User } from '../types/user';

/**
 * calculates the total user number
 *
 * @param users users
 * @returns total user number
 */
export const getTotalUserNumber = (users: User[]): number => {
	let result = 0;
	users.forEach((user: User) => {
		if (user.role === 'admin') return;
		result++;
	});
	return result;
};

/**
 * calculates the user number for each gender
 *
 * @param users users
 * @returns user number for each gender
 */
export const getUserNumberByGender = (users: User[]): GenderCounter => {
	const result: GenderCounter = { male: 0, female: 0, divers: 0, family: 0, company: 0, other: 0 };
	users.forEach((user: User) => {
		if (user.role === 'admin') return;
		if (user.salutation.toLowerCase() === 'herr') {
			result.male++;
		} else if (user.salutation.toLowerCase() === 'frau') {
			result.female++;
		} else if (user.salutation.toLowerCase() === 'divers') {
			result.divers++;
		} else if (user.salutation.toLowerCase() === 'familie') {
			result.family++;
		} else if (user.salutation.toLowerCase() === 'firma') {
			result.company++;
		} else {
			result.other++;
		}
	});
	return result;
};

/**
 * calculates the age of a user. Not 100% exact, because of leap years
 *
 * @param user user
 * @returns age
 */
export const getAge = (user: User): number => {
	const birthday = new Date(user.birthdate);
	const birthdayInMilliseconds = birthday.getTime();
	const ageInMilliseconds = Date.now() - birthdayInMilliseconds;
	const ageDate = new Date(ageInMilliseconds);
	const age = Math.abs(ageDate.getUTCFullYear() - 1970);
	return age;
};

/**
 *  calculates the user number for each gender categorized by age groups
 *
 * @param users users
 * @returns user number for each gender categorized by age groups
 */
export const buildGenderAgeArray = (users: User[]) => {
	const result: GenderAgeData = {
		male: Array(8).fill(0),
		female: Array(8).fill(0),
		divers: Array(8).fill(0),
		family: Array(8).fill(0),
		company: Array(8).fill(0),
		other: Array(8).fill(0),
	};

	const add = (user: User, ageRange: number) => {
		if (user.salutation.toLowerCase() === 'herr') {
			result.male[ageRange] += 1;
		} else if (user.salutation.toLowerCase() === 'frau') {
			result.female[ageRange] += 1;
		} else if (user.salutation.toLowerCase() === 'divers') {
			result.divers[ageRange] += 1;
		} else if (user.salutation.toLowerCase() === 'familie') {
			result.family[ageRange] += 1;
		} else if (user.salutation.toLowerCase() === 'firma') {
			result.company[ageRange] += 1;
		} else {
			result.other[ageRange] += 1;
		}
	};

	users.forEach((user: User) => {
		if (user.role === 'admin') return;
		const age: number = getAge(user);
		switch (true) {
			case age < 16:
				add(user, 0);
				break;
			case age < 21:
				add(user, 1);
				break;
			case age < 31:
				add(user, 2);
				break;
			case age < 41:
				add(user, 3);
				break;
			case age < 51:
				add(user, 4);
				break;
			case age < 61:
				add(user, 5);
				break;
			case age < 71:
				add(user, 6);
				break;
			default:
				add(user, 7);
				break;
		}
	});
	return result;
};
