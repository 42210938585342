import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@material-ui/core';
import React, { ChangeEvent, useContext } from 'react';
import { LoginContext } from '../../contexts/LoginContext';
import * as logic from '../../service/user';
import { User } from '../../types/user';
import { hasAccess } from '../../util/authorization';
import { SnackBarContext } from '../CustomAlertSnackBar/CustomAlertSnackBarContext';

interface Props {
	user: User;
	heading: string;
	message: string;
	acceptButtonText: string;
	onAccept: Function;
	openDialog: boolean;
	setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
	roleSelection: boolean;
	setRoleSelection: React.Dispatch<React.SetStateAction<boolean>>;
	newUserRole: 'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | 'Customer';
	setNewUserRole: React.Dispatch<
		React.SetStateAction<'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | 'Customer'>
	>;
}

const UserSecurityDialog = ({
	heading,
	message,
	acceptButtonText,
	onAccept,
	openDialog,
	setOpenDialog,
	roleSelection,
	setRoleSelection,
	newUserRole,
	setNewUserRole,
}: Props) => {
	const { role } = useContext(LoginContext);

	const onClose = () => {
		setRoleSelection(false);
		setOpenDialog(false);
	};

	return (
		<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
			<DialogTitle>{heading}</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
				{roleSelection ? (
					hasAccess(role, 'createUserWithRole') ? (
						<FormControl className="userRegistration__textBox">
							<InputLabel id="createUser__inputlabel__role">Rolle</InputLabel>
							<Select
								labelId="createUser__inputlabel__role"
								required
								value={newUserRole}
								onChange={(event: ChangeEvent<{ value: any }>) =>
									setNewUserRole(event.target.value)
								}
							>
								<MenuItem value={'Customer'}>Customer</MenuItem>
								<MenuItem value={'FlavorUser'}>FlavorUser</MenuItem>
								{hasAccess(role, 'creatFlavorAdminUser') ? (
									<MenuItem value={'FlavorAdmin'}>FlavorAdmin</MenuItem>
								) : null}
								{hasAccess(role, 'creatSupportUser') ? (
									<MenuItem value={'Support'}>Support</MenuItem>
								) : null}
								{hasAccess(role, 'creatSuperAdminUser') ? (
									<MenuItem value={'SuperAdmin'}>SuperAdmin</MenuItem>
								) : null}
							</Select>
						</FormControl>
					) : null
				) : (
					<>keinInputFeld</>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onAccept();
						onClose();
					}}
					color="primary"
				>
					{acceptButtonText}
				</Button>
				<Button onClick={onClose} color="default" autoFocus>
					Abbrechen
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UserSecurityDialog;
