/**
 * result of KundenanlageCheck
 */
export interface KundenanlageInfo {
	status: eStatus;
	msg: string;
}
/**
 * possible status of KundenanlageCheck
 */
export enum eStatus {
	initial,
	loading,
	success,
	error,
}
