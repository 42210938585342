import { AxiosResponse } from 'axios';
import { axiosInstance } from './axiosConfig';

export interface G2GSettingGetResponse {
	value: string| number |null;
	msg: string;
}
export interface PatchResponse {
	success: boolean;
	msg: string;
}

export interface EmailServerSetResponse{
	success : boolean,
	msg : string | null
}

export enum G2GSetting {
	EmailAccountAdresse = 96,
	EmailSmtpServer = 97, 
	EmailSmtpServerPort = 98,
	EmailAccountPasswort =99,
	TischbestellungFrontEndURL=151

}

export enum SyncType
{
	product=0,
	category=1,
	combination = 2,
	construction=3,
	branches=4,
	premiums=5,
	startPage =6,
	productFilter=7,
	ordersFixed=8,
	onboardingPages=9,
	productCalendar=10,
	branchopenTimes=11,
	upperCategory = 12,
	einstellungen=13,
	cardDiscounts=14,
	feedbackForm =15

}

/**
 * gets a payment provider setting value
 *
 * @param paymentSetting which setting to get
 * @param flavor AppFlavor
 */
export const doApiRequestGET = async (
	g2gSetting: G2GSetting,
	flavor: string,
): Promise<G2GSettingGetResponse> => {
	try {
		const res: AxiosResponse<string | null> = await axiosInstance(
			`v1/Config/g2gSettingsJSON/${G2GSetting[g2gSetting]}`,
			{
				headers: { AppFlavour: flavor },
			},
		);
		if (res.data == null) {
			return {
				value: null,
				msg: 'Keine Werte für Einstellung.',
			};
		}
		return {
			value: res.data,
			msg: '',
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				value: null,
				msg: error.response.data.title,
			};
		}
		return {
			value: null,
			msg: 'no valid server response',
		};
	}
};

/**
 * set a payment provider setting value
 *
 * @param paymentSetting which setting to get
 * @param flavor AppFlavor
 */
export const doApiRequestPATCHJSON = async (
	g2gSetting: G2GSetting,
	flavor: string,
	 settingValue:any 
): Promise<G2GSettingGetResponse> => {
	try {
		const res: AxiosResponse<string | null> = await axiosInstance.patch(
			`v1/Config/g2gSettingsJSON/${G2GSetting[g2gSetting]}?value=${settingValue}`,
			{},
			{
				headers: { AppFlavour: flavor },
			},
		);
		if (res.data == null) {
			return {
				value: null,
				msg: 'Keine Werte für Einstellung.',
			};
		}
		return {
			value: res.data,
			msg: '',
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				value: null,
				msg: error.response.data.title,
			};
		}
		return {
			value: null,
			msg: 'no valid server response',
		};
	}
};

/**
 * patches a payment provider setting
 *
 * @param g2gSetting which setting to patch
 * @param flavor AppFlavor
 * @param checked value of setting
 */
export const doApiRequestPatch = async (
	g2gSetting: G2GSetting,
	flavor: string,
	checked: boolean,
): Promise<PatchResponse> => {
	try {
		const res: AxiosResponse<any>= await axiosInstance.patch(
			`v1/Config/paypal-setting/${g2gSetting}/${checked}`,
			{},{
				headers: { AppFlavour: flavor }
			},
		);
		if (res.status !== 200) {
			return {
				success: false,
				msg: 'Einstellung könnte nicht gespeichert werden.',
			};
		}
		return {
			success: true,
			msg: '',
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				success: false,
				msg: error.response.data.title,
			};
		}
		return { success: false, msg: 'no valid server response' };
	}
};


/**
 * patches a payment provider setting
 *
 * @param g2gSetting which setting to patch
 * @param flavor AppFlavor
 * @param checked value of setting
 */
export const doApiRequestPatchJSON= async (
	g2gSetting: G2GSetting,
	flavor: string,
	json: any,
): Promise<PatchResponse> => {
	try {
		const res: AxiosResponse<any>= await axiosInstance.patch(
			`v1/Config/paypal-setting/${g2gSetting}/${json}`,
			{},{
				headers: { AppFlavour: flavor }
			},
		);
		if (res.status !== 200) {
			return {
				success: false,
				msg: 'Einstellung könnte nicht gespeichert werden.',
			};
		}
		return {
			success: true,
			msg: '',
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				success: false,
				msg: error.response.data.title,
			};
		}
		return { success: false, msg: 'no valid server response' };
	}
};

/**
 * update a dataset in the api (sync)
 *
 * @param SyncType which Sync to run
 * @param flavor AppFlavor
 */
export const doApiRequestUpdateDataSet = async (
	syncType: SyncType,
	flavor: string,
): Promise<PatchResponse> => {
	try {
		const res: AxiosResponse<any>= await axiosInstance.post(
			`v1/admin/Update/update-datasets/${syncType}`,
			{},{
				headers: { AppFlavour: flavor }
			},
		);
		if (res.status !== 200) {
			return {
				success: false,
				msg: 'Sync was not successful.',
			};
		}
		return {
			success: true,
			msg: '',
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				success: false,
				msg: error.response.data.title,
			};
		}
		return { success: false, msg: 'no valid server response' };
	}
};

/**
 * set email server settings 
 *
 * @param flavor AppFlavor
 * @param smtpServer Email Smtp Server
 * @param smtpPort Email Smtp Port
 * @param smtpAccountAdr Email Smtp Account Address
 * @param smtpPassword Email Smtp Server Password
 */
export const doApiSetEmailServer = async ( 
	flavor: string,
	smtpServer :string|null,
	smtpPort :number|null,
	smtpAccountAdr : string|null,
	smtpPassword :string|null
): Promise<EmailServerSetResponse> => {
	try {
		const res: AxiosResponse<string | null> = await axiosInstance.post(
			`v1/SuperAdmin/set-mailserver`,
			{
				"emailSmtpServer": smtpServer,
				"emailSmtpServerPort": smtpPort,
				"emailAccountAdresse": smtpAccountAdr,
				"emailAccountPasswort": smtpPassword,
				"flavor": flavor
			  },
			{
				headers: { AppFlavour: flavor },
			},
		);
		console.log(res.status)
		if (res.status !== 200) {
			return {
				success: false,
				msg: res.data,
			};
		}
		await doApiRequestUpdateDataSet(SyncType.einstellungen, flavor)
		return {
			success: true,
			msg: '',
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				success: false,
				msg: error.response.data.title,
			};
		}
		return {
			success: false,
			msg: 'no valid server response',
		};
	}
};
