import type { AxiosResponse } from 'axios';
import { head } from 'lodash';
import type { ProductCategory } from '../types/product';
import type { Availability } from '../types/productAvailability';
import { axiosInstance } from './axiosConfig';

/**
 * get available productCategories for a specific flavor
 *
 * @param flavor flavor to get the available productCategories for
 * @returns array with available productCategories
 */
export const getCategories = async (flavor: string): Promise<ProductCategory[]> => {
	if (flavor === 'all') return [] as ProductCategory[];
	try {
		const res: AxiosResponse = await axiosInstance.get('v1/Sync/categories', {
			headers: { AppFlavour: flavor },
		});

		return res.data.changed;
	} catch (error) {
		console.error(error);
		return [] as ProductCategory[];
	}
};

/**
 * get all products for a specific flavor
 *
 * @param flavor flavor to get the products for
 * @returns array of products
 */
export const getProducts = async (flavor: string) => {
	if (flavor === 'all') return [];
	try {
		const res: AxiosResponse = await axiosInstance.get('v1/Sync/products', {
			headers: { AppFlavour: flavor },
		});
		return res.data.changed;
	} catch (error) {
		console.error(error);
	}
	return [];
};

/**
 *
 * @param flavor
 * @param filialID
 * @returns
 */
export const getProductAvailability = async (
	flavor: string,
	filialID: number,
	date: string
): Promise<Availability[]> => {
	if (flavor === 'all') return [];
	try {
		const res: AxiosResponse = await axiosInstance.get('v1/SuperAdmin/products', {
			params: { filialID: filialID, date: date },
			headers: { Appflavour: flavor},
		});
		return Object.entries(res.data);
	} catch (error) {
		console.error(error);
	}
	return [];
};
