import React, { useEffect, useState, useCallback } from 'react';
import { StoreTableOrderInfo } from '../../types/tableordersmodels';
import {
	DataGrid,
	GridColDef,
	GridRowData,
	GridCellParams,
	GridCell,
} from '@material-ui/data-grid';
 
import { Checkbox } from '@material-ui/core';
import './BranchTableOrderInfoTable.css';

interface Props {
	data: StoreTableOrderInfo[];
	setChanged: Function;
	changeTableOrderAllowed: Function;
}
const BranchTableOrderInfoTable = ({ data, setChanged, changeTableOrderAllowed }: Props) => {
	const [pageSize, setPageSize] = useState<number>(10);  
 
	function RenderCheckBox(props: GridCellParams) {
		const [checked, setChecked] = React.useState<boolean>(props.row.tableOrderAllowed); // Initiated react binded value with param from `rows`

		// Handler for user clicks to set checkbox mark or unset it
		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			props.row.tableOrderAllowed = event.target.checked;
			changeTableOrderAllowed(props.row.storeID, event.target.checked);
			setChecked(event.target.checked);

			setChanged();
		}; 
		//The bind for dynamic mark/unmark: checked={checked}
		//The handler for user clicks: onChange={handleChange}
		return <Checkbox checked={checked} onChange={handleChange} />;
	}
	const columns: GridColDef[] = [
		{ field: 'storeNumber', headerName: 'Filialnummer', flex: 1 },
		{ field: 'storeName', headerName: 'Filialname', flex: 2 },
		{
			field: 'tableOrderAllowed',
			headerName: 'Bestellung erlaubt',
			flex: 1,
			type: 'boolean',
			renderCell: RenderCheckBox,
		},
	];
 
	return (
		<>
			<div className="btoigrid">
				<DataGrid
					rows={data}
					columns={columns}
					getRowId={(row: any) => row.storeNumber}
					pageSize={pageSize}
					rowsPerPageOptions={[5, 10]}
					onPageSizeChange={(newPagesize) => {
						setPageSize(newPagesize);
					}}
					disableSelectionOnClick
				/>
			</div>
		</>
	);
};

export default BranchTableOrderInfoTable;
