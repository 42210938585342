import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import logo from '../../assets/logo/logo-dark.svg';
import { LoginContext } from '../../contexts/LoginContext';
import { Flavor } from '../../types/flavor';
import { LoginContextType } from '../../types/loginContext';
import { hasAccess } from '../../util/authorization';
import './Navbar.css';

function Navbar() {
	const location = useLocation();
	const history = useHistory();
	const { role, allowedFlavors, selectedFlavor, setSelectedFlavor }: LoginContextType =
		useContext(LoginContext);

	const Props = {
		options: allowedFlavors,
		getOptionLabel: (option: Flavor) => option.name,
	};

	useEffect(() => {
		redirect(selectedFlavor.flavor);
	}, [selectedFlavor]);

	/**
	 * redirects to the new selectted flavor.
	 * Selected flavor can not be set directly, because of an inf loop caused by async sate setters
	 * between navbar and ParamsManager.
	 */
	const changeSelectedFlavor = (e: ChangeEvent<{}>, value: Flavor | null) => {
		if (value == null) return;
		redirect(value.flavor);
	};

	/** redirects to the new route  */
	const redirect = (flavor: string) => {
		if (!role) return;
		const newPath = location.pathname.split('/');
		newPath[1] = flavor;
		history.push(`${newPath.join('/') + location.search}`);
	};

	const handleLogoNavigation = (event: any) => {
		if (event.button === 1) {
			// on middle mousebutton clicked
			const baseURL = window.location.origin;
			const win = window.open(`${baseURL}${location.pathname}${location.search}`, '_blank');
			win?.focus();
		} else if (event.button === 0) {
			// on left mousebutton clicked
			history.push(`/${selectedFlavor.flavor}/`);
		}
	};

	return (
		<div className="navbar">
			<div className="navbar__container navbar__link" onMouseUp={handleLogoNavigation}>
				<img src={logo} alt="Logo" style={{ height: '100%' }} />
			</div>
			<div className="navbar__container">
				{hasAccess(role, 'selectFlavor') && (
					<Autocomplete
						className="navbar__combobox"
						{...Props}
						renderInput={(params) => <TextField {...params} margin="normal" />}
						value={selectedFlavor}
						onChange={changeSelectedFlavor}
					/>
				)}
			</div>
		</div>
	);
}

export default Navbar;
