import React from 'react';
import { Bar } from 'react-chartjs-2';
import { GenderAgeData } from '../../types/statistics';

interface Props {
	data: GenderAgeData;
}

const GenderAgeBarChart = ({ data }: Props) => {
	const localData = {
		labels: ['0 - 15', '16 - 20', '21 - 30', '31 - 40', '41 - 50', '51 - 60', '61 - 70', '70+'],
		datasets: [
			{
				label: 'männlich',
				data: data.male,
				backgroundColor: 'rgb(54, 162, 235, 1)',
			},
			{
				label: 'weiblich',
				data: data.female,
				backgroundColor: 'rgb(255, 99, 132, 1)',
			},
			{
				label: 'divers',
				data: data.divers,
				backgroundColor: 'rgb(75, 192, 192, 1)',
			},
			{
				label: 'familie',
				data: data.family,
				backgroundColor: 'rgba(70, 100, 220, 1)',
			},
			{
				label: 'firma',
				data: data.company,
				backgroundColor: 'rgba(130, 252, 94, 1)',
			},
			{
				label: 'sonstige',
				data: data.other,
				backgroundColor: 'rgba(0, 185, 62, 1)',
			},
		],
	};

	return <Bar data={localData} />;
};

export default GenderAgeBarChart;
