import { createTheme } from '@material-ui/core/styles';
import { deDE } from '@material-ui/data-grid';
import { ThemeProvider } from '@material-ui/styles'; 
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import { SnackBarProvider } from './components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import LoginContextProvider from './contexts/LoginContext';
import myTheme from './resources/theme.json';
import Routes from './Routes';
import { defaults } from 'react-chartjs-2';

const theme = createTheme(myTheme, deDE);

const queryClient = new QueryClient();

defaults.animation = false;

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<LoginContextProvider>
				<QueryClientProvider client={queryClient}>
					<SnackBarProvider>
						<Routes />
						{/* <ReactQueryDevtools initialIsOpen={false} /> */}
					</SnackBarProvider>
				</QueryClientProvider>
			</LoginContextProvider>
		</ThemeProvider>
	);
};

export default App;
