import type { AxiosResponse } from 'axios';
import type { OrderDetails, POSSyncOrder } from '../types/order';
import type { ProductById } from '../types/product';
import { axiosInstance } from './axiosConfig';

/**
 * get the orderDetails of a specific order
 *
 * @param id orderId
 * @param flavor flavor of the order
 * @returns orderDetails on success, otherwise null
 */
export const getOrderDetails = async (id: string, flavor: string): Promise<OrderDetails | null> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.get(`v1/Order/details?orderId=${id}`, {
			headers: {
				AppFlavour: flavor,
			},
		});
		if (res != null) return res.data;
	} catch (error) {
		console.error(error);
	}
	return null;
};

/**
 * get the receipt of a specific shopping element
 *
 * @param id shoppingId
 * @param flavor flavor of the shopping element
 * @returns html receipt on success, otherwise null
 */
export const getShoppingReceipt = async (id: string, flavor: string): Promise<string | null> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.get(`v1/Order/receipt?uid=${id}`, {
			headers: {
				AppFlavour: flavor,
			},
		});
		if (res != null) return res.data;
	} catch (error) {
		console.error(error);
	}
	return null;
};

/**
 * get the product name by productId
 *
 * @param id productId
 * @param flavor flavor to search in
 * @returns product name on success, otherwise null
 */
export const getProductById = async (id: string, flavor: string): Promise<ProductById | null> => {
	try {
		const res = await axiosInstance.get(`v1/ProductDetails/product/${id}`, {
			headers: {
				AppFlavour: flavor,
			},
		});
		if (res != null) return res.data;
	} catch (error) {
		console.error(error);
	}
	return null;
};

export const getPOSSyncOrder = async (
	flavor: string,
	filialID: number,
): Promise<POSSyncOrder[]> => {
	try {
		const res: AxiosResponse = await axiosInstance.get(`v1/SuperAdmin/orders`, {
			params: { filialID: filialID },
			headers: {
				AppFlavour: flavor
			},
		});
		res.data.forEach((order: any) => {
			order['id'] = order['ID'];
			delete order['ID'];
		});
		return res.data;
	} catch (error) {
		console.error(error);
	}
	return [];
};
