import { AxiosResponse } from "axios";
import { Flavor } from "../types/flavor";
import { axiosInstance } from "./axiosConfig";

/**
 * gets all flavors the user is allowed to use from the api
 *
 * @returns array of flavors on success, otherwise null
 */
export const getAllowedFlavours = async (
  flavor: string
): Promise<null | Flavor[]> => {
  try {
    const res: AxiosResponse<any> = await axiosInstance.get(
      "v1/SuperAdmin/flavors",
      {
        headers: { AppFlavour: flavor },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Flavor Error: ", error);
  }
  return null;
};

/**
 * creates a new Flavor
 *
 * @param flavor flavor to create
 * @returns 1 on success, otherwise -1
 */
export const createFlavor = async (flavor: Flavor): Promise<number> => {
  return await editOrCreateFlavor(flavor, true);
};

/**
 * edits an existing flavor
 *
 * @param flavor flavor to edit
 * @returns 1 on success, otherwise -1
 */
export const editFlavor = async (flavor: Flavor) => {
  return await editOrCreateFlavor(flavor, false);
};

/**
 * creates a new Flavor or edit if already exists
 *
 * @param flavor flavor to create or edit
 * @returns 1 on success, otherwise -1
 */

const editOrCreateFlavor = async (flavor: Flavor, create :boolean): Promise<number> => {
  if (flavor.flavor === "all") return -1;
  delete flavor.inactiveTintColor;
   
  try {
    const res: AxiosResponse<any> = await axiosInstance.post(
      "v1/SuperAdmin/flavor",
      flavor,
      {
        //if its create vorgang flavour does not exist yet so it is not known for the api
        headers: create ? {} : {AppFlavour : flavor.flavor} 
      }
    );
    if (res != null) return 1;
  } catch (error) {
    console.error(error);
  }
  return -1;
};
