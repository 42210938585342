import { AxiosResponse } from 'axios';
import { axiosInstance } from './axiosConfig';
import { KundenanlageInfo, eStatus } from '../types/kundenanlage';

export const checkSqlStringExists = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-sql-string-exists', flavor);

export const checkSqlKarten = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-sql-karten', flavor);

export const checkSqlKartenprepaidumsatz = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-sql-kartenprepaidumsatz', flavor);

export const checkSqlTreuekartebuchung = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-sql-Treuekartebuchung', flavor);

export const checkSqlKartengruppe = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-sql-Kartengruppe', flavor);

export const checkSkriptstand = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-skriptstand', flavor);

export const setDemoUser = async (flavor: string, force: boolean) =>
	await apiRequestForce('v1/SuperAdmin/Setup/set-demo-user', flavor, force);

export const setkOfficeAdmin = async (flavor: string, force: boolean) =>
	await apiRequestForce('v1/SuperAdmin/Setup/set-office-admin', flavor, force);

export const setOfficeApiSettings = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/set-office-api-settings', flavor);
export const setDefaultApiSettings = async (flavor: string) =>
	await apiRequest(`v1/SuperAdmin/Setup/set-defaults?targetFlavor=${flavor}`,  flavor, true);
export const checkHasFirebase = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-has-firebase-sdk', flavor);
export const checkHasPPLive = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-has-paypal?live=true', flavor);
export const checkHasPPSandbox = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-has-paypal?live=false', flavor);
export const checkHasNELive = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-has-nets-easy?live=true', flavor);
export const checkHasNESandbox = async (flavor: string) =>
	await apiRequest('v1/SuperAdmin/Setup/check-has-nets-easy?live=false', flavor);

export const doNothing = async () =>
	{
		return {
			status: eStatus.initial,
			msg: '',
		};	
	}




/**
 * sends a request to the path
 *
 * @param path api path to send request to
 * @param flavor AppFlavor
 * @returns 1 if everything worked, otherwise error message
 */
const apiRequest = async (path: string, flavor: string, isPost :boolean = false): Promise<KundenanlageInfo> => {
	try {
		if(isPost){
			const res: AxiosResponse<any> = await axiosInstance.post(path, 
				{},
				{
					headers: { AppFlavour: flavor }
				}	
		);
			if (res.data == null) {
				return {
					status: eStatus.success,
					msg: '',
				};
			}
			return {
				status: eStatus.success,
				msg: res.data,
			};
		}
		const res: AxiosResponse<any> = await axiosInstance(path,  {
			headers: { AppFlavour: flavor },
		});
		if (res.data == null) {
			return {
				status: eStatus.success,
				msg: '',
			};
		}
		return {
			status: eStatus.success,
			msg: res.data,
		};
	} catch (error: any) {
		console.error('error: ', error);
		if (error?.response?.data?.detail != null) {
			console.error(error.response.data.detail);
			return {
				status: eStatus.error,
				msg: error.response.data.title,
			};
		}
		return {
			status: eStatus.error,
			msg: 'no valid server response',
		};
	}
};

/**
 * sends a request with forced parameter to the path
 *
 * @param path api path to send request to
 * @param flavor AppFlavor
 * @param force force reload
 * @returns 1 if everything worked, otherwise error message
 */
const apiRequestForce = async (
	path: string,
	flavor: string,
	force: boolean,
): Promise<KundenanlageInfo> => {
	try {
		const res: AxiosResponse<any> = await axiosInstance.get(path, {
			params: { force: force },
			headers: { AppFlavour: flavor },
		});
		if (res.data.msg === '') {
			return {
				status: eStatus.success,
				msg: '',
			};
		}
		return {
			status: eStatus.success,
			msg: res.data.msg,
		};
	} catch (error: any) {
		console.error(error.response.data.detail);
		return {
			status: eStatus.error,
			msg: error.response.data.title,
		};
	}
};

export const forceFullSync = async (flavor: string): Promise<-1 | 1> => {
	try {
		const resUpdateAllDataSets: AxiosResponse<any> = await axiosInstance.post(
			'v1/admin/Update/update-all-datasets',{},
			{ headers: { AppFlavour: flavor } },
		);
		if(resUpdateAllDataSets.status!==200) return -1;
		const resForceFullSync: AxiosResponse<any> = await axiosInstance.post(
			'v1/admin/Update/force-fullsync',{},
			{ headers: { AppFlavour: flavor } },
		);
		return resForceFullSync.status===200 ? 1 : -1;
	} catch (error) {
		console.error(error);
		return -1;
	}
};
