import {
	Button,
	Card,
	CardContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import dateformat from 'dateformat';
import { parse } from 'query-string';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { createInvitedUser } from '../../api/userManagement';
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import { LoginContext } from '../../contexts/LoginContext';
import { AxiosResponse } from 'axios';
import { axiosInstance, axiosRefreshInstance } from '../../api/axiosConfig';
import './InvitationRegistration.css';

interface Props {
	params: any;
}

const InvitationRegistration = ({ params }: Props) => {
	const history = useHistory();
	const location = useLocation();
	const { displaySnackbar } = useContext(SnackBarContext);
	const { selectedFlavor } = useContext(LoginContext);

	const [newUserRole, setNewUserRole] = useState<
		'SuperAdmin' | 'Support' | 'FlavorAdmin' | 'FlavorUser' | '-'
	>('-');
	const [salutation, setSalutation] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [birthday, setBirthday] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [passwordConf, setPasswordConf] = useState<string>('');
	const [companyName, setCompanyName] = useState<string>('');

	const resetForm = () => {
		setNewUserRole('-');
		setSalutation('');
		setFirstName('');
		setName('');
		setBirthday('');
		setPhoneNumber('');
		setPassword('');
		setPasswordConf('');
		setCompanyName('');
	};

	const registration = async (event: ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (password == passwordConf) {

			const newUser = {
				firstName: firstName,
				name: name,
				phoneNumber: phoneNumber,
				password: password,
				salutation: salutation,
				birthdate: new Date(Date.parse(birthday)).toISOString(),
				acceptedPrivacyStatement: true,
				companyName: companyName
			};

			try {
				const res: number = await createInvitedUser(newUser, params.invtoken, decodeURI(params.encflavour));
				if (res === -1) return displaySnackbar('Registrierung fehlgeschlagen!', 'error');
				resetForm();
				displaySnackbar('Registrierung erfolgreich!', 'success');
				history.push('/login');
			} catch (error) {
				displaySnackbar('Registrierung fehlgeschlagen!', 'error');
			}
		} else {
			displaySnackbar('Passwörter müssen übereinstimmen!', 'error');
		}
	};

	return (
		<div className="userRegistration">
			<Card className="userRegistration__card" variant="elevation">
				<CardContent>
					<form className="userRegistration__form" autoComplete="off" onSubmit={registration}>

						{/* Salutation */}
						<FormControl className="userRegistration__textBox">
							<InputLabel id="createUser__inputlabel__salutation">Anrede</InputLabel>
							<Select
								labelId="createUser__inputlabel__salutation"
								required
								value={salutation}
								onChange={(event: ChangeEvent<{ value: any }>) => setSalutation(event.target.value)}
							>
								<MenuItem value={'Herr'}>Herr</MenuItem>
								<MenuItem value={'Frau'}>Frau</MenuItem>
								<MenuItem value={'Divers'}>Divers</MenuItem>
								<MenuItem value={'Familie'}>Familie</MenuItem>
								<MenuItem value={'Firma'}>Firma</MenuItem>
							</Select>
						</FormControl>

						{/* FirstName */}
						<TextField
							label="Vorname"
							required
							value={firstName}
							onChange={(event: ChangeEvent<{ value: any }>) => setFirstName(event.target.value)}
							margin={'dense'}
						/>

						{/* Name */}
						<TextField
							label="Name"
							required
							value={name}
							onChange={(event: ChangeEvent<{ value: any }>) => setName(event.target.value)}
							margin={'dense'}
						/>

						{/* CompanyName */}
						<TextField
							label="Firmenname"
							value={companyName}
							onChange={(event: ChangeEvent<{ value: any }>) => setCompanyName(event.target.value)}
							margin={'dense'}
						/>

						{/* Birthday */}
						<TextField
							label="Geburtstag"
							type="date"
							required
							value={dateformat(Date.parse(birthday), 'yyyy-mm-dd')}
							onChange={(event: ChangeEvent<{ value: any }>) => setBirthday(event.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
							margin={'dense'}
						/>

						{/* PhoneNumber */}
						<TextField
							label="Telefon"
							required
							value={phoneNumber}
							onChange={(event: ChangeEvent<{ value: any }>) => setPhoneNumber(event.target.value)}
							margin={'dense'}
						/>


						{/* Password */}
						<TextField
							label="Passwort"
							type="password"
							required
							value={password}
							onChange={(event: ChangeEvent<{ value: any }>) => setPassword(event.target.value)}
							margin={'dense'}
						/>

						{/* Password Confirm */}
						<TextField
							label="Passwort bestätigen"
							type="password"
							required
							value={passwordConf}
							onChange={(event: ChangeEvent<{ value: any }>) => setPasswordConf(event.target.value)}
							margin={'dense'}
						/>

						{/* Submit Button */}
						<Button
							type="submit"
							className="userRegistration__button"
							color="secondary"
							variant="text"
						>
							Erstellen
						</Button>
					</form>
				</CardContent>
			</Card>
		</div>
	);
};

export default InvitationRegistration;
