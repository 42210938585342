import { AxiosResponse } from 'axios';
import { Branch } from '../types/branch';
import { axiosInstance } from './axiosConfig';

/**
 * get all branches of a flavor
 *
 * @param flavor AppFlavor to search branches of
 * @returns
 */
export const getBranches = async (flavor: string): Promise<Branch[] | null> => {
	if (flavor === 'all') return null;
	try {
		const res: AxiosResponse = await axiosInstance.get('v1/Sync/branches', {
			headers: { AppFlavour: flavor },
		});
		if (res.data != null) return res.data.changed;
	} catch (error) {
		console.error(error);
	}
	return null;
};
