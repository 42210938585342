import { Snackbar } from '@material-ui/core';
import MuiAlert, { Color } from '@material-ui/lab/Alert';

type CustomAlertSnackBarType = {
	duration?: number;
	message?: string;
	open: boolean;
	severity: Color;
	setOpen: (value: boolean) => void;
};

export const CustomAlertSnackBar = ({
	duration,
	message,
	open,
	severity,
	setOpen,
}: CustomAlertSnackBarType) => {
	return (
		<Snackbar open={open} autoHideDuration={duration} onClose={() => setOpen(false)}>
			<MuiAlert elevation={6} variant="filled" onClose={() => setOpen(false)} severity={severity}>
				{message}
			</MuiAlert>
		</Snackbar>
	);
};
